.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@charset "UTF-8";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  --bs-blue: #00426c;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #e30513;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #009640;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #009640;
  --bs-secondary: #6c757d;
  --bs-success: #009640;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #e30513;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 0, 150, 64;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 0, 150, 64;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 227, 5, 19;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 34, 34, 34;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Montserrat";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff
;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(34, 34, 34, 0);
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  margin: 0;
  text-align: var(--bs-body-text-align);
}

hr {
  background-color: currentColor;
  border: 0;
  color: inherit;
  margin: 1rem 0;
  opacity: .25;
}

hr:not([size]) {
  height: 1px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: .5rem;
  margin-top: 0;
}

.h1,
h1 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2rem;
  }
}

.h2,
h2 {
  font-size: calc(1.3125rem + .75vw);
}

@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 1.875rem;
  }
}

.h3,
h3 {
  font-size: 1.125rem;
}

.h4,
h4 {
  font-size: 1rem;
}

.h5,
h5 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  .h5,
  h5 {
    font-size: 1.5rem;
  }
}

.h6,
h6 {
  font-size: 1rem;
}

p {
  margin-bottom: 1rem;
  margin-top: 0;
}

abbr[data-bs-original-title],
abbr[title] {
  cursor: help;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol,
ul {
  padding-left: 2rem;
}

dl,
ol,
ul {
  margin-bottom: 1rem;
  margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

.small,
small {
  font-size: .875em;
}

.mark,
mark {
  background-color: #fcf8e3;
  padding: .2em;
}

sub,
sup {
  font-size: .75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #009640;
  text-decoration: underline;
}

a:hover {
  color: #007833;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code,
kbd,
pre,
samp {
  direction: ltr;
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  font-size: .875em;
  margin-bottom: 1rem;
  margin-top: 0;
  overflow: auto;
}

pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}

code {
  word-wrap: break-word;
  color: #d63384;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  background-color: #212529;
  border-radius: .2rem;
  color: #fff;
  font-size: .875em;
  padding: .2rem .4rem;
}

kbd kbd {
  font-size: 1em;
  font-weight: 700;
  padding: 0;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  caption-side: bottom;
}

caption {
  color: #6c757d;
  padding-bottom: .5rem;
  padding-top: .5rem;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
  width: 100%;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-inline,
.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  font-size: .875em;
  text-transform: uppercase;
}

.blockquote {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  font-size: .875em;
  margin-bottom: 1rem;
  margin-top: -1rem;
}

.blockquote-footer:before {
  content: "— ";
}

.ecosystem ul li a img,
.hero__content .content__img img,
.img-fluid,
.img-thumbnail,
.modal .modal-dialog .modal-content .modal-body .body__gallery__empty img {
  height: auto;
  max-width: 100%;
}

.img-thumbnail {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  line-height: 1;
  margin-bottom: .5rem;
}

.figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl,
.ecosystem,
.hero .wrapper,
.landing .navbar-wrapper,
.landing main .info__wrapper .info,
.navbar-wrapper,
.requests__wrapper,
.stickyBar__wrapper .stickyBar__container,
footer,
main .acceptance__wrapper,
main .agencies__wrapper,
main .faq__wrapper,
main .info__wrapper .info,
main .marketplace_wrapper,
main .profile__data,
main .profile__requests,
main .profile__services,
main .profile__wrapper,
main .questions__wrapper,
main .registration__wrapper,
main hr {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--bs-gutter-x, 1.5rem);
  padding-right: var(--bs-gutter-x, 1.5rem);
  width: 100%;
}

@media (min-width: 576px) {
  .container,
  .container-sm,
  .hero .wrapper,
  main .acceptance__wrapper {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm,
  .hero .wrapper,
  main .acceptance__wrapper {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .hero .wrapper,
  main .acceptance__wrapper {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .hero .wrapper,
  .landing .navbar-wrapper,
  .landing main .info__wrapper .info,
  .stickyBar__wrapper .stickyBar__container,
  main .acceptance__wrapper,
  main .agencies__wrapper,
  main .questions__wrapper,
  main .registration__wrapper,
  main hr {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl,
  .hero .wrapper,
  .landing .navbar-wrapper,
  .landing main .info__wrapper .info,
  .stickyBar__wrapper .stickyBar__container,
  main .acceptance__wrapper,
  main .agencies__wrapper,
  main .questions__wrapper,
  main .registration__wrapper,
  main hr {
    max-width: 1320px;
  }
}

.ecosystem ul,
.hero__content,
.requests__wrapper .requests,
.row,
.serviceOffCanvas .offcanvas-body .fieldset,
.services-cards .content__cards .cards,
.stickyBar__wrapper .stickyBar__container .stickyBar,
footer > ul,
main .agencies__wrapper .agencies,
main .info__wrapper .info .content,
main .info__wrapper .info .content__list .list__wrapper,
main .marketplace_wrapper .marketplace .content__filters > ul,
main .marketplace_wrapper .marketplace .content__tabs > ul,
main .profile__data .chart,
main .profile__wrapper .profile,
main .registration__wrapper .registration,
main .registration__wrapper .registration__list .list__wrapper {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x)*-.5);
  margin-right: calc(var(--bs-gutter-x)*-.5);
  margin-top: calc(var(--bs-gutter-y)*-1);
}

.ecosystem ul > *,
.hero__content > *,
.requests__wrapper .requests > *,
.row > *,
.serviceOffCanvas .offcanvas-body .fieldset > *,
.services-cards .content__cards .cards > *,
.stickyBar__wrapper .stickyBar__container .stickyBar > *,
footer > ul > *,
main .agencies__wrapper .agencies > *,
main .info__wrapper .info .content > *,
main .info__wrapper .info .content__list .list__wrapper > *,
main .marketplace_wrapper .marketplace .content__filters > ul > *,
main .marketplace_wrapper .marketplace .content__tabs > ul > *,
main .profile__data .chart > *,
main .profile__wrapper .profile > *,
main .registration__wrapper .registration > *,
main .registration__wrapper .registration__list .list__wrapper > * {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x)*.5);
  padding-right: calc(var(--bs-gutter-x)*.5);
  width: 100%;
}

.col,
.hero__content .content__img,
.hero__content .content__text,
main .marketplace_wrapper .marketplace .content__tabs > ul li {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
}

.row-cols-auto > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6,
.ecosystem ul li,
.serviceOffCanvas .offcanvas-body .submit,
main .agencies__wrapper .agencies__agency {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12,
.serviceOffCanvas .offcanvas-body .fieldset .abstract,
.serviceOffCanvas .offcanvas-body .fieldset .chi-siamo,
.serviceOffCanvas .offcanvas-body .fieldset .confirm-delete,
.serviceOffCanvas .offcanvas-body .fieldset .cosa-offriamo,
.serviceOffCanvas .offcanvas-body .fieldset .titolo,
.serviceOffCanvas .offcanvas-body .fieldset .video,
main .info__wrapper .info .content__iframe,
main .info__wrapper .info .content__list,
main .info__wrapper .info .content__title,
main .profile__data .chart__main,
main .registration__wrapper .registration__list,
main .registration__wrapper .registration__register,
main .registration__wrapper .registration__title {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0
;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0
;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem
;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem
;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem
;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem
;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem
;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem
;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem
;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem
;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem
;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem
;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6,
  .ecosystem ul li {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0
    ;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0
    ;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem
    ;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem
    ;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem
    ;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem
    ;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem
    ;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem
    ;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem
    ;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem
    ;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem
    ;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem
    ;
  }
}

@media (min-width: 768px) {
  .col-md {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4,
  .ecosystem ul li,
  .serviceOffCanvas .offcanvas-body .fieldset .categoria,
  .serviceOffCanvas .offcanvas-body .fieldset .sconto,
  .serviceOffCanvas .offcanvas-body .fieldset .web,
  main .agencies__wrapper .agencies__agency,
  main .marketplace_wrapper .marketplace .content__filters > ul > li {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6,
  .serviceOffCanvas .offcanvas-body .fieldset .gallery,
  .serviceOffCanvas .offcanvas-body .fieldset .logo,
  .services-cards .content__cards .cards .service,
  .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons,
  .stickyBar__wrapper .stickyBar__container .stickyBar__logo,
  footer > ul > li.social,
  main .profile__data .chart__donuts,
  main .profile__wrapper .profile > div,
  main .registration__wrapper .registration__list .list__wrapper li {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12,
  .requests__wrapper .requests .request,
  footer > ul > li.info,
  footer > ul > li.menu {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0
    ;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0
    ;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem
    ;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem
    ;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem
    ;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem
    ;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem
    ;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem
    ;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem
    ;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem
    ;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem
    ;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem
    ;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2,
  .ecosystem ul li,
  main .agencies__wrapper .agencies__agency {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4,
  .services-cards .content__cards .cards .service,
  footer > ul > li.info,
  footer > ul > li.menu,
  footer > ul > li.social,
  main .info__wrapper .info .content__list .list__wrapper article {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5,
  .requests__wrapper .requests .request__info,
  .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6,
  .requests__wrapper .requests .request {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7,
  .requests__wrapper .requests .request__content,
  .stickyBar__wrapper .stickyBar__container .stickyBar__logo {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0
    ;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0
    ;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem
    ;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem
    ;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem
    ;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem
    ;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem
    ;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem
    ;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem
    ;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem
    ;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem
    ;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem
    ;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3,
  .services-cards .content__cards .cards .service {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4,
  .requests__wrapper .requests .request__info {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8,
  .requests__wrapper .requests .request__content {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0
    ;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0
    ;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem
    ;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem
    ;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem
    ;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem
    ;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem
    ;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem
    ;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem
    ;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem
    ;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem
    ;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem
    ;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0
    ;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0
    ;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem
    ;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem
    ;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem
    ;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem
    ;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem
    ;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem
    ;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem
    ;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem
    ;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem
    ;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem
    ;
  }
}

.fieldset label,
.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  line-height: 1.5;
  margin-bottom: 0;
  padding-bottom: .375rem;
  padding-top: .375rem;
}

.col-form-label-lg {
  font-size: 1.25rem;
  padding-bottom: .5rem;
  padding-top: .5rem;
}

.col-form-label-sm {
  font-size: .875rem;
  padding-bottom: .25rem;
  padding-top: .25rem;
}

.form-text {
  color: #6c757d;
  font-size: .875em;
  margin-top: .25rem;
}

.fieldset .form__group input:not(.form-check-input),
.fieldset .form__group textarea,
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 0 solid #ced4da;
  border-radius: .25rem;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: .375rem .75rem;
  -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  width: 100%;
}

@media (prefers-reduced-motion: reduce) {
  .fieldset .form__group input:not(.form-check-input),
  .fieldset .form__group textarea,
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.fieldset .form__group input[type=file]:not(.form-check-input),
.fieldset .form__group textarea[type=file],
.form-control[type=file] {
  overflow: hidden;
}

.fieldset .form__group input[type=file]:not(:disabled):not([readonly]):not(.form-check-input),
.fieldset .form__group textarea[type=file]:not(:disabled):not([readonly]),
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.fieldset .form__group input:focus:not(.form-check-input),
.fieldset .form__group textarea:focus,
.form-control:focus {
  background-color: #fff;
  border-color: #80cba0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
  color: #212529;
  outline: 0;
}

.fieldset .form__group input:not(.form-check-input)::-webkit-date-and-time-value,
.fieldset .form__group textarea::-webkit-date-and-time-value,
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.fieldset .form__group input:not(.form-check-input):-ms-input-placeholder,
.fieldset .form__group textarea:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.fieldset .form__group input:not(.form-check-input)::-webkit-input-placeholder,
.fieldset .form__group textarea::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.fieldset .form__group input:not(.form-check-input)::-ms-input-placeholder,
.fieldset .form__group textarea::-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.fieldset .form__group input:not(.form-check-input)::placeholder,
.fieldset .form__group textarea::placeholder,
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.fieldset .form__group input:disabled:not(.form-check-input),
.fieldset .form__group input[readonly]:not(.form-check-input),
.fieldset .form__group textarea:disabled,
.fieldset .form__group textarea[readonly],
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.fieldset .form__group input:not(.form-check-input)::file-selector-button,
.fieldset .form__group textarea::file-selector-button,
.form-control::file-selector-button {
  -webkit-margin-end: .75rem;
  background-color: #e9ecef;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 0;
  border-radius: 0;
  color: #212529;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  pointer-events: none;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .fieldset .form__group input:not(.form-check-input)::-webkit-file-upload-button,
  .fieldset .form__group textarea::-webkit-file-upload-button,
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .fieldset .form__group input:not(.form-check-input)::file-selector-button,
  .fieldset .form__group textarea::file-selector-button,
  .form-control::file-selector-button {
    -webkit-transition: none;
    transition: none;
  }
}

.fieldset .form__group input:hover:not(:disabled):not([readonly]):not(.form-check-input)::file-selector-button,
.fieldset .form__group textarea:hover:not(:disabled):not([readonly])::file-selector-button,
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.fieldset .form__group input:not(.form-check-input)::-webkit-file-upload-button,
.fieldset .form__group textarea::-webkit-file-upload-button,
.form-control::-webkit-file-upload-button {
  -webkit-margin-end: .75rem;
  background-color: #e9ecef;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 0;
  border-radius: 0;
  color: #212529;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  pointer-events: none;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .fieldset .form__group input:not(.form-check-input)::-webkit-file-upload-button,
  .fieldset .form__group textarea::-webkit-file-upload-button,
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}

.fieldset .form__group input:hover:not(:disabled):not([readonly]):not(.form-check-input)::-webkit-file-upload-button,
.fieldset .form__group textarea:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  background-color: transparent;
  border: 0 solid transparent;
  color: #212529;
  display: block;
  line-height: 1.5;
  margin-bottom: 0;
  padding: .375rem 0;
  width: 100%;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  border-radius: .2rem;
  font-size: .875rem;
  min-height: calc(1.5em + .5rem);
  padding: .25rem .5rem;
}

.form-control-sm::file-selector-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  border-radius: .3rem;
  font-size: 1.25rem;
  min-height: calc(1.5em + 1rem);
  padding: .5rem 1rem;
}

.form-control-lg::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

.fieldset .form__group textarea,
textarea.form-control {
  min-height: calc(1.5em + .75rem);
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem);
}

.form-control-color {
  height: auto;
  padding: .375rem;
  width: 3rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .25rem;
  height: 1.5em;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .25rem;
  height: 1.5em;
}

.fieldset .form__group select,
.form-select {
  -moz-padding-start: calc(.75rem - 3px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 0 solid #ced4da;
  border-radius: .25rem;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: .375rem 2.25rem .375rem .75rem;
  -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  width: 100%;
}

@media (prefers-reduced-motion: reduce) {
  .fieldset .form__group select,
  .form-select {
    -webkit-transition: none;
    transition: none;
  }
}

.fieldset .form__group select:focus,
.form-select:focus {
  border-color: #80cba0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
  outline: 0;
}

.fieldset .form__group select[multiple],
.fieldset .form__group select[size]:not([size="1"]),
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.fieldset .form__group select:disabled,
.form-select:disabled {
  background-color: #e9ecef;
}

.fieldset .form__group select:-moz-focusring,
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  border-radius: .2rem;
  font-size: .875rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  padding-top: .25rem;
}

.form-select-lg {
  border-radius: .3rem;
  font-size: 1.25rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  padding-top: .5rem;
}

.form-check {
  display: block;
  margin-bottom: .125rem;
  min-height: 1.5rem;
  padding-left: 1.5em;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  -webkit-print-color-adjust: exact;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgba(34, 34, 34, 0.25);
  color-adjust: exact;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  width: 1em;
}

.form-check-input[type=checkbox] {
  border-radius: .25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #80cba0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
  outline: 0;
}

.form-check-input:checked {
  background-color: #009640;
  border-color: #009640;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #009640;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
  border-color: #009640;
}

.form-check-input:disabled {
  -webkit-filter: none;
          filter: none;
  opacity: .5;
  pointer-events: none;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(34, 34, 34, 0.25)'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  -webkit-transition: background-position .15s ease-in-out;
  transition: background-position .15s ease-in-out;
  width: 2em;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2380cba0'/%3E%3C/svg%3E");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 100%;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check:disabled + .btn,
.btn-check[disabled] + .btn,
.serviceOffCanvas .offcanvas-body .btn-check:disabled + .bozza,
.serviceOffCanvas .offcanvas-body .btn-check:disabled + .cancella,
.serviceOffCanvas .offcanvas-body .btn-check:disabled + .pubblica,
.serviceOffCanvas .offcanvas-body .btn-check[disabled] + .bozza,
.serviceOffCanvas .offcanvas-body .btn-check[disabled] + .cancella,
.serviceOffCanvas .offcanvas-body .btn-check[disabled] + .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:disabled + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:disabled + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check[disabled] + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check[disabled] + .ctaButtons__login,
body .buorg .btn-check:disabled + #buorgul,
body .buorg .btn-check[disabled] + #buorgul,
main .acceptance__wrapper .btn-check:disabled + button,
main .acceptance__wrapper .btn-check[disabled] + button,
main .agencies__wrapper .agencies__more .btn-check:disabled + a,
main .agencies__wrapper .agencies__more .btn-check[disabled] + a,
main .profile__wrapper .profile__addService .btn-check:disabled + button,
main .profile__wrapper .profile__addService .btn-check[disabled] + button,
main .questions__wrapper .btn-check:disabled + .questions__ctaButton,
main .questions__wrapper .btn-check[disabled] + .questions__ctaButton,
main .registration__wrapper .registration__register .btn-check:disabled + a,
main .registration__wrapper .registration__register .btn-check[disabled] + a {
  -webkit-filter: none;
          filter: none;
  opacity: .65;
  pointer-events: none;
}

.form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  height: 1.5rem;
  padding: 0;
  width: 100%;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #009640;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  width: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b3e0c6;
}

.form-range::-webkit-slider-runnable-track {
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: .5rem;
  width: 100%;
}

.form-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: #009640;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  width: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b3e0c6;
}

.form-range::-moz-range-track {
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: .5rem;
  width: 100%;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.fieldset .form__group .form-floating > input:not(.form-check-input),
.fieldset .form__group .form-floating > select,
.fieldset .form__group .form-floating > textarea,
.form-floating > .form-control,
.form-floating > .form-select {
  height: 3.5rem;
  line-height: 1.25;
}

.form-floating > label {
  border: 0 solid transparent;
  height: 100%;
  left: 0;
  padding: 1rem .75rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out;
  transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out, -webkit-transform .1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    -webkit-transition: none;
    transition: none;
  }
}

.fieldset .form__group .form-floating > input:not(.form-check-input),
.fieldset .form__group .form-floating > textarea,
.form-floating > .form-control {
  padding: 1rem .75rem;
}

.fieldset .form__group .form-floating > input:not(.form-check-input):-ms-input-placeholder,
.fieldset .form__group .form-floating > textarea:-ms-input-placeholder,
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}

.fieldset .form__group .form-floating > input:not(.form-check-input)::-webkit-input-placeholder,
.fieldset .form__group .form-floating > textarea::-webkit-input-placeholder,
.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}

.fieldset .form__group .form-floating > input:not(.form-check-input)::-ms-input-placeholder,
.fieldset .form__group .form-floating > textarea::-ms-input-placeholder,
.form-floating > .form-control::-ms-input-placeholder {
  color: transparent;
}

.fieldset .form__group .form-floating > input:not(.form-check-input)::placeholder,
.fieldset .form__group .form-floating > textarea::placeholder,
.form-floating > .form-control::placeholder {
  color: transparent;
}

.fieldset .form__group .form-floating > input:not(:-moz-placeholder-shown):not(.form-check-input),
.fieldset .form__group .form-floating > textarea:not(:-moz-placeholder-shown),
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-bottom: .625rem;
  padding-top: 1.625rem;
}

.fieldset .form__group .form-floating > input:not(:-ms-input-placeholder):not(.form-check-input),
.fieldset .form__group .form-floating > textarea:not(:-ms-input-placeholder),
.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-bottom: .625rem;
  padding-top: 1.625rem;
}

.fieldset .form__group .form-floating > input:focus:not(.form-check-input),
.fieldset .form__group .form-floating > input:not(:placeholder-shown):not(.form-check-input),
.fieldset .form__group .form-floating > textarea:focus,
.fieldset .form__group .form-floating > textarea:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-bottom: .625rem;
  padding-top: 1.625rem;
}

.fieldset .form__group .form-floating > input:-webkit-autofill:not(.form-check-input),
.fieldset .form__group .form-floating > textarea:-webkit-autofill,
.form-floating > .form-control:-webkit-autofill {
  padding-bottom: .625rem;
  padding-top: 1.625rem;
}

.fieldset .form__group .form-floating > select,
.form-floating > .form-select {
  padding-bottom: .625rem;
  padding-top: 1.625rem;
}

.fieldset .form__group .form-floating > input:not(:-moz-placeholder-shown):not(.form-check-input) ~ label,
.fieldset .form__group .form-floating > textarea:not(:-moz-placeholder-shown) ~ label,
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: .65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.fieldset .form__group .form-floating > input:not(:-ms-input-placeholder):not(.form-check-input) ~ label,
.fieldset .form__group .form-floating > textarea:not(:-ms-input-placeholder) ~ label,
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: .65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.fieldset .form__group .form-floating > input:focus:not(.form-check-input) ~ label,
.fieldset .form__group .form-floating > input:not(:placeholder-shown):not(.form-check-input) ~ label,
.fieldset .form__group .form-floating > select ~ label,
.fieldset .form__group .form-floating > textarea:focus ~ label,
.fieldset .form__group .form-floating > textarea:not(:placeholder-shown) ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: .65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.fieldset .form__group .form-floating > input:-webkit-autofill:not(.form-check-input) ~ label,
.fieldset .form__group .form-floating > textarea:-webkit-autofill ~ label,
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.fieldset .form__group .input-group > input:not(.form-check-input),
.fieldset .form__group .input-group > select,
.fieldset .form__group .input-group > textarea,
.input-group > .form-control,
.input-group > .form-select {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 1%;
}

.fieldset .form__group .input-group > input:focus:not(.form-check-input),
.fieldset .form__group .input-group > select:focus,
.fieldset .form__group .input-group > textarea:focus,
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn,
.input-group .serviceOffCanvas .offcanvas-body .bozza,
.input-group .serviceOffCanvas .offcanvas-body .cancella,
.input-group .serviceOffCanvas .offcanvas-body .pubblica,
.input-group .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi,
.input-group .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login,
.input-group body .buorg #buorgul,
.input-group main .acceptance__wrapper button,
.input-group main .agencies__wrapper .agencies__more a,
.input-group main .profile__wrapper .profile__addService button,
.input-group main .questions__wrapper .questions__ctaButton,
.input-group main .registration__wrapper .registration__register a,
.serviceOffCanvas .offcanvas-body .input-group .bozza,
.serviceOffCanvas .offcanvas-body .input-group .cancella,
.serviceOffCanvas .offcanvas-body .input-group .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .input-group .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .input-group .ctaButtons__login,
body .buorg .input-group #buorgul,
main .acceptance__wrapper .input-group button,
main .agencies__wrapper .agencies__more .input-group a,
main .profile__wrapper .profile__addService .input-group button,
main .questions__wrapper .input-group .questions__ctaButton,
main .registration__wrapper .registration__register .input-group a {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus,
.input-group .serviceOffCanvas .offcanvas-body .bozza:focus,
.input-group .serviceOffCanvas .offcanvas-body .cancella:focus,
.input-group .serviceOffCanvas .offcanvas-body .pubblica:focus,
.input-group .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi:focus,
.input-group .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:focus,
.input-group body .buorg #buorgul:focus,
.input-group main .acceptance__wrapper button:focus,
.input-group main .agencies__wrapper .agencies__more a:focus,
.input-group main .profile__wrapper .profile__addService button:focus,
.input-group main .questions__wrapper .questions__ctaButton:focus,
.input-group main .registration__wrapper .registration__register a:focus,
.serviceOffCanvas .offcanvas-body .input-group .bozza:focus,
.serviceOffCanvas .offcanvas-body .input-group .cancella:focus,
.serviceOffCanvas .offcanvas-body .input-group .pubblica:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .input-group .ctaButtons__comeRegistrarsi:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .input-group .ctaButtons__login:focus,
body .buorg .input-group #buorgul:focus,
main .acceptance__wrapper .input-group button:focus,
main .agencies__wrapper .agencies__more .input-group a:focus,
main .profile__wrapper .profile__addService .input-group button:focus,
main .questions__wrapper .input-group .questions__ctaButton:focus,
main .registration__wrapper .registration__register .input-group a:focus {
  z-index: 3;
}

.input-group-text {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #e9ecef;
  border: 0 solid #ced4da;
  border-radius: .25rem;
  color: #212529;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: .375rem .75rem;
  text-align: center;
  white-space: nowrap;
}

.fieldset .form__group .input-group-lg > input:not(.form-check-input),
.fieldset .form__group .input-group-lg > select,
.fieldset .form__group .input-group-lg > textarea,
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.serviceOffCanvas .offcanvas-body .input-group-lg > .bozza,
.serviceOffCanvas .offcanvas-body .input-group-lg > .cancella,
.serviceOffCanvas .offcanvas-body .input-group-lg > .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .input-group-lg > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .input-group-lg > .ctaButtons__login,
body .buorg .input-group-lg > #buorgul,
main .acceptance__wrapper .input-group-lg > button,
main .agencies__wrapper .agencies__more .input-group-lg > a,
main .profile__wrapper .profile__addService .input-group-lg > button,
main .questions__wrapper .input-group-lg > .questions__ctaButton,
main .registration__wrapper .registration__register .input-group-lg > a {
  border-radius: .3rem;
  font-size: 1.25rem;
  padding: .5rem 1rem;
}

.fieldset .form__group .input-group-sm > input:not(.form-check-input),
.fieldset .form__group .input-group-sm > select,
.fieldset .form__group .input-group-sm > textarea,
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.serviceOffCanvas .offcanvas-body .input-group-sm > .bozza,
.serviceOffCanvas .offcanvas-body .input-group-sm > .cancella,
.serviceOffCanvas .offcanvas-body .input-group-sm > .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .input-group-sm > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .input-group-sm > .ctaButtons__login,
body .buorg .input-group-sm > #buorgul,
main .acceptance__wrapper .input-group-sm > button,
main .agencies__wrapper .agencies__more .input-group-sm > a,
main .profile__wrapper .profile__addService .input-group-sm > button,
main .questions__wrapper .input-group-sm > .questions__ctaButton,
main .registration__wrapper .registration__register .input-group-sm > a {
  border-radius: .2rem;
  font-size: .875rem;
  padding: .25rem .5rem;
}

.fieldset .form__group .input-group-lg > select,
.fieldset .form__group .input-group-sm > select,
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0;
}

.valid-feedback {
  color: #009640;
  display: none;
  font-size: .875em;
  margin-top: .25rem;
  width: 100%;
}

.valid-tooltip {
  background-color: rgba(0, 150, 64, 0.9);
  border-radius: .25rem;
  color: #222;
  display: none;
  font-size: .875rem;
  margin-top: .1rem;
  max-width: 100%;
  padding: .25rem .5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}

.fieldset .form__group .was-validated input:valid:not(.form-check-input),
.fieldset .form__group .was-validated textarea:valid,
.fieldset .form__group input.is-valid:not(.form-check-input),
.fieldset .form__group textarea.is-valid,
.form-control.is-valid,
.was-validated .fieldset .form__group input:valid:not(.form-check-input),
.was-validated .fieldset .form__group textarea:valid,
.was-validated .form-control:valid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23009640' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #009640;
  padding-right: calc(1.5em + .75rem);
}

.fieldset .form__group .was-validated input:valid:focus:not(.form-check-input),
.fieldset .form__group .was-validated textarea:valid:focus,
.fieldset .form__group input.is-valid:focus:not(.form-check-input),
.fieldset .form__group textarea.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .fieldset .form__group input:valid:focus:not(.form-check-input),
.was-validated .fieldset .form__group textarea:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #009640;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
}

.fieldset .form__group .was-validated textarea:valid,
.fieldset .form__group textarea.is-valid,
.was-validated .fieldset .form__group textarea:valid,
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.fieldset .form__group .was-validated select:valid,
.fieldset .form__group select.is-valid,
.form-select.is-valid,
.was-validated .fieldset .form__group select:valid,
.was-validated .form-select:valid {
  border-color: #009640;
}

.fieldset .form__group .was-validated select:valid:not([multiple]):not([size]),
.fieldset .form__group .was-validated select:valid:not([multiple])[size="1"],
.fieldset .form__group select.is-valid:not([multiple]):not([size]),
.fieldset .form__group select.is-valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .fieldset .form__group select:valid:not([multiple]):not([size]),
.was-validated .fieldset .form__group select:valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E"), url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23009640' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right .75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.fieldset .form__group .was-validated select:valid:focus,
.fieldset .form__group select.is-valid:focus,
.form-select.is-valid:focus,
.was-validated .fieldset .form__group select:valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #009640;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #009640;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #009640;
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #009640;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.fieldset .form__group .input-group input.is-valid:not(.form-check-input),
.fieldset .form__group .input-group select.is-valid,
.fieldset .form__group .input-group textarea.is-valid,
.fieldset .form__group .was-validated .input-group input:valid:not(.form-check-input),
.fieldset .form__group .was-validated .input-group select:valid,
.fieldset .form__group .was-validated .input-group textarea:valid,
.input-group .fieldset .form__group input.is-valid:not(.form-check-input),
.input-group .fieldset .form__group select.is-valid,
.input-group .fieldset .form__group textarea.is-valid,
.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .fieldset .form__group input:valid:not(.form-check-input),
.was-validated .input-group .fieldset .form__group select:valid,
.was-validated .input-group .fieldset .form__group textarea:valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
  z-index: 1;
}

.fieldset .form__group .input-group input.is-valid:focus:not(.form-check-input),
.fieldset .form__group .input-group select.is-valid:focus,
.fieldset .form__group .input-group textarea.is-valid:focus,
.fieldset .form__group .was-validated .input-group input:valid:focus:not(.form-check-input),
.fieldset .form__group .was-validated .input-group select:valid:focus,
.fieldset .form__group .was-validated .input-group textarea:valid:focus,
.input-group .fieldset .form__group input.is-valid:focus:not(.form-check-input),
.input-group .fieldset .form__group select.is-valid:focus,
.input-group .fieldset .form__group textarea.is-valid:focus,
.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .fieldset .form__group input:valid:focus:not(.form-check-input),
.was-validated .input-group .fieldset .form__group select:valid:focus,
.was-validated .input-group .fieldset .form__group textarea:valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}

.invalid-feedback {
  color: #e30513;
  display: none;
  font-size: .875em;
  margin-top: .25rem;
  width: 100%;
}

.invalid-tooltip {
  background-color: rgba(227, 5, 19, 0.9);
  border-radius: .25rem;
  color: #fff;
  display: none;
  font-size: .875rem;
  margin-top: .1rem;
  max-width: 100%;
  padding: .25rem .5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.fieldset .form__group .was-validated input:invalid:not(.form-check-input),
.fieldset .form__group .was-validated textarea:invalid,
.fieldset .form__group input.is-invalid:not(.form-check-input),
.fieldset .form__group textarea.is-invalid,
.form-control.is-invalid,
.was-validated .fieldset .form__group input:invalid:not(.form-check-input),
.was-validated .fieldset .form__group textarea:invalid,
.was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e30513'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23e30513' stroke='none'/%3E%3C/svg%3E");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #e30513;
  padding-right: calc(1.5em + .75rem);
}

.fieldset .form__group .was-validated input:invalid:focus:not(.form-check-input),
.fieldset .form__group .was-validated textarea:invalid:focus,
.fieldset .form__group input.is-invalid:focus:not(.form-check-input),
.fieldset .form__group textarea.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .fieldset .form__group input:invalid:focus:not(.form-check-input),
.was-validated .fieldset .form__group textarea:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #e30513;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.25);
}

.fieldset .form__group .was-validated textarea:invalid,
.fieldset .form__group textarea.is-invalid,
.was-validated .fieldset .form__group textarea:invalid,
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.fieldset .form__group .was-validated select:invalid,
.fieldset .form__group select.is-invalid,
.form-select.is-invalid,
.was-validated .fieldset .form__group select:invalid,
.was-validated .form-select:invalid {
  border-color: #e30513;
}

.fieldset .form__group .was-validated select:invalid:not([multiple]):not([size]),
.fieldset .form__group .was-validated select:invalid:not([multiple])[size="1"],
.fieldset .form__group select.is-invalid:not([multiple]):not([size]),
.fieldset .form__group select.is-invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .fieldset .form__group select:invalid:not([multiple]):not([size]),
.was-validated .fieldset .form__group select:invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E"), url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e30513'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23e30513' stroke='none'/%3E%3C/svg%3E");
  background-position: right .75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.fieldset .form__group .was-validated select:invalid:focus,
.fieldset .form__group select.is-invalid:focus,
.form-select.is-invalid:focus,
.was-validated .fieldset .form__group select:invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #e30513;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.25);
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #e30513;
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #e30513;
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.25);
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #e30513;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.fieldset .form__group .input-group input.is-invalid:not(.form-check-input),
.fieldset .form__group .input-group select.is-invalid,
.fieldset .form__group .input-group textarea.is-invalid,
.fieldset .form__group .was-validated .input-group input:invalid:not(.form-check-input),
.fieldset .form__group .was-validated .input-group select:invalid,
.fieldset .form__group .was-validated .input-group textarea:invalid,
.input-group .fieldset .form__group input.is-invalid:not(.form-check-input),
.input-group .fieldset .form__group select.is-invalid,
.input-group .fieldset .form__group textarea.is-invalid,
.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .fieldset .form__group input:invalid:not(.form-check-input),
.was-validated .input-group .fieldset .form__group select:invalid,
.was-validated .input-group .fieldset .form__group textarea:invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
  z-index: 2;
}

.fieldset .form__group .input-group input.is-invalid:focus:not(.form-check-input),
.fieldset .form__group .input-group select.is-invalid:focus,
.fieldset .form__group .input-group textarea.is-invalid:focus,
.fieldset .form__group .was-validated .input-group input:invalid:focus:not(.form-check-input),
.fieldset .form__group .was-validated .input-group select:invalid:focus,
.fieldset .form__group .was-validated .input-group textarea:invalid:focus,
.input-group .fieldset .form__group input.is-invalid:focus:not(.form-check-input),
.input-group .fieldset .form__group select.is-invalid:focus,
.input-group .fieldset .form__group textarea.is-invalid:focus,
.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .fieldset .form__group input:invalid:focus:not(.form-check-input),
.was-validated .input-group .fieldset .form__group select:invalid:focus,
.was-validated .input-group .fieldset .form__group textarea:invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}

.btn,
.serviceOffCanvas .offcanvas-body .bozza,
.serviceOffCanvas .offcanvas-body .cancella,
.serviceOffCanvas .offcanvas-body .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login,
body .buorg #buorgul,
main .acceptance__wrapper button,
main .agencies__wrapper .agencies__more a,
main .profile__wrapper .profile__addService button,
main .questions__wrapper .questions__ctaButton,
main .registration__wrapper .registration__register a {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  color: #212529;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: .375rem .75rem;
  text-align: center;
  text-decoration: none;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}

@media (prefers-reduced-motion: reduce) {
  .btn,
  .serviceOffCanvas .offcanvas-body .bozza,
  .serviceOffCanvas .offcanvas-body .cancella,
  .serviceOffCanvas .offcanvas-body .pubblica,
  .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi,
  .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login,
  body .buorg #buorgul,
  main .acceptance__wrapper button,
  main .agencies__wrapper .agencies__more a,
  main .profile__wrapper .profile__addService button,
  main .questions__wrapper .questions__ctaButton,
  main .registration__wrapper .registration__register a {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover,
.serviceOffCanvas .offcanvas-body .bozza:hover,
.serviceOffCanvas .offcanvas-body .cancella:hover,
.serviceOffCanvas .offcanvas-body .pubblica:hover,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi:hover,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:hover,
body .buorg #buorgul:hover,
main .acceptance__wrapper button:hover,
main .agencies__wrapper .agencies__more a:hover,
main .profile__wrapper .profile__addService button:hover,
main .questions__wrapper .questions__ctaButton:hover,
main .registration__wrapper .registration__register a:hover {
  color: #212529;
}

.btn-check:focus + .btn,
.btn:focus,
.serviceOffCanvas .offcanvas-body .bozza:focus,
.serviceOffCanvas .offcanvas-body .btn-check:focus + .bozza,
.serviceOffCanvas .offcanvas-body .btn-check:focus + .cancella,
.serviceOffCanvas .offcanvas-body .btn-check:focus + .pubblica,
.serviceOffCanvas .offcanvas-body .cancella:focus,
.serviceOffCanvas .offcanvas-body .pubblica:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:focus + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:focus + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:focus,
body .buorg #buorgul:focus,
body .buorg .btn-check:focus + #buorgul,
main .acceptance__wrapper .btn-check:focus + button,
main .acceptance__wrapper button:focus,
main .agencies__wrapper .agencies__more .btn-check:focus + a,
main .agencies__wrapper .agencies__more a:focus,
main .profile__wrapper .profile__addService .btn-check:focus + button,
main .profile__wrapper .profile__addService button:focus,
main .questions__wrapper .btn-check:focus + .questions__ctaButton,
main .questions__wrapper .questions__ctaButton:focus,
main .registration__wrapper .registration__register .btn-check:focus + a,
main .registration__wrapper .registration__register a:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
  outline: 0;
}

.btn.disabled,
.btn:disabled,
.serviceOffCanvas .offcanvas-body .bozza:disabled,
.serviceOffCanvas .offcanvas-body .cancella:disabled,
.serviceOffCanvas .offcanvas-body .disabled.bozza,
.serviceOffCanvas .offcanvas-body .disabled.cancella,
.serviceOffCanvas .offcanvas-body .disabled.pubblica,
.serviceOffCanvas .offcanvas-body .pubblica:disabled,
.serviceOffCanvas .offcanvas-body fieldset:disabled .bozza,
.serviceOffCanvas .offcanvas-body fieldset:disabled .cancella,
.serviceOffCanvas .offcanvas-body fieldset:disabled .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi:disabled,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:disabled,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .disabled.ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .disabled.ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons fieldset:disabled .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons fieldset:disabled .ctaButtons__login,
body .buorg #buorgul:disabled,
body .buorg .disabled#buorgul,
body .buorg fieldset:disabled #buorgul,
fieldset:disabled .btn,
fieldset:disabled .serviceOffCanvas .offcanvas-body .bozza,
fieldset:disabled .serviceOffCanvas .offcanvas-body .cancella,
fieldset:disabled .serviceOffCanvas .offcanvas-body .pubblica,
fieldset:disabled .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi,
fieldset:disabled .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login,
fieldset:disabled body .buorg #buorgul,
fieldset:disabled main .acceptance__wrapper button,
fieldset:disabled main .agencies__wrapper .agencies__more a,
fieldset:disabled main .profile__wrapper .profile__addService button,
fieldset:disabled main .questions__wrapper .questions__ctaButton,
fieldset:disabled main .registration__wrapper .registration__register a,
main .acceptance__wrapper button.disabled,
main .acceptance__wrapper button:disabled,
main .acceptance__wrapper fieldset:disabled button,
main .agencies__wrapper .agencies__more a.disabled,
main .agencies__wrapper .agencies__more a:disabled,
main .agencies__wrapper .agencies__more fieldset:disabled a,
main .profile__wrapper .profile__addService button.disabled,
main .profile__wrapper .profile__addService button:disabled,
main .profile__wrapper .profile__addService fieldset:disabled button,
main .questions__wrapper .disabled.questions__ctaButton,
main .questions__wrapper .questions__ctaButton:disabled,
main .questions__wrapper fieldset:disabled .questions__ctaButton,
main .registration__wrapper .registration__register a.disabled,
main .registration__wrapper .registration__register a:disabled,
main .registration__wrapper .registration__register fieldset:disabled a {
  opacity: .65;
  pointer-events: none;
}

.btn-primary,
.serviceOffCanvas .offcanvas-body .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login,
body .buorg #buorgul,
main .acceptance__wrapper button,
main .agencies__wrapper .agencies__more a,
main .profile__wrapper .profile__addService button,
main .registration__wrapper .registration__register a {
  color: #222;
}

.btn-primary:hover,
.serviceOffCanvas .offcanvas-body .pubblica:hover,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:hover,
body .buorg #buorgul:hover,
main .acceptance__wrapper button:hover,
main .agencies__wrapper .agencies__more a:hover,
main .profile__wrapper .profile__addService button:hover,
main .registration__wrapper .registration__register a:hover {
  background-color: #26a65d;
  border-color: #1aa153;
  color: #222;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus,
.serviceOffCanvas .offcanvas-body .btn-check:focus + .pubblica,
.serviceOffCanvas .offcanvas-body .pubblica:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:focus + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:focus,
body .buorg #buorgul:focus,
body .buorg .btn-check:focus + #buorgul,
main .acceptance__wrapper .btn-check:focus + button,
main .acceptance__wrapper button:focus,
main .agencies__wrapper .agencies__more .btn-check:focus + a,
main .agencies__wrapper .agencies__more a:focus,
main .profile__wrapper .profile__addService .btn-check:focus + button,
main .profile__wrapper .profile__addService button:focus,
main .registration__wrapper .registration__register .btn-check:focus + a,
main .registration__wrapper .registration__register a:focus {
  background-color: #26a65d;
  border-color: #1aa153;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(5, 133, 60, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(5, 133, 60, 0.5);
  color: #222;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.serviceOffCanvas .offcanvas-body .active.pubblica,
.serviceOffCanvas .offcanvas-body .btn-check:active + .pubblica,
.serviceOffCanvas .offcanvas-body .btn-check:checked + .pubblica,
.serviceOffCanvas .offcanvas-body .pubblica:active,
.serviceOffCanvas .offcanvas-body .show > .dropdown-toggle.pubblica,
.show > .btn-primary.dropdown-toggle,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .active.ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:active + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:checked + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:active,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .show > .dropdown-toggle.ctaButtons__login,
body .buorg #buorgul:active,
body .buorg .active#buorgul,
body .buorg .btn-check:active + #buorgul,
body .buorg .btn-check:checked + #buorgul,
body .buorg .show > .dropdown-toggle#buorgul,
main .acceptance__wrapper .btn-check:active + button,
main .acceptance__wrapper .btn-check:checked + button,
main .acceptance__wrapper .show > button.dropdown-toggle,
main .acceptance__wrapper button.active,
main .acceptance__wrapper button:active,
main .agencies__wrapper .agencies__more .btn-check:active + a,
main .agencies__wrapper .agencies__more .btn-check:checked + a,
main .agencies__wrapper .agencies__more .show > a.dropdown-toggle,
main .agencies__wrapper .agencies__more a.active,
main .agencies__wrapper .agencies__more a:active,
main .profile__wrapper .profile__addService .btn-check:active + button,
main .profile__wrapper .profile__addService .btn-check:checked + button,
main .profile__wrapper .profile__addService .show > button.dropdown-toggle,
main .profile__wrapper .profile__addService button.active,
main .profile__wrapper .profile__addService button:active,
main .registration__wrapper .registration__register .btn-check:active + a,
main .registration__wrapper .registration__register .btn-check:checked + a,
main .registration__wrapper .registration__register .show > a.dropdown-toggle,
main .registration__wrapper .registration__register a.active,
main .registration__wrapper .registration__register a:active {
  background-color: #33ab66;
  border-color: #1aa153;
  color: #222;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.serviceOffCanvas .offcanvas-body .active.pubblica:focus,
.serviceOffCanvas .offcanvas-body .btn-check:active + .pubblica:focus,
.serviceOffCanvas .offcanvas-body .btn-check:checked + .pubblica:focus,
.serviceOffCanvas .offcanvas-body .pubblica:active:focus,
.serviceOffCanvas .offcanvas-body .show > .dropdown-toggle.pubblica:focus,
.show > .btn-primary.dropdown-toggle:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .active.ctaButtons__login:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:active + .ctaButtons__login:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:checked + .ctaButtons__login:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:active:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .show > .dropdown-toggle.ctaButtons__login:focus,
body .buorg #buorgul:active:focus,
body .buorg .active#buorgul:focus,
body .buorg .btn-check:active + #buorgul:focus,
body .buorg .btn-check:checked + #buorgul:focus,
body .buorg .show > .dropdown-toggle#buorgul:focus,
main .acceptance__wrapper .btn-check:active + button:focus,
main .acceptance__wrapper .btn-check:checked + button:focus,
main .acceptance__wrapper .show > button.dropdown-toggle:focus,
main .acceptance__wrapper button.active:focus,
main .acceptance__wrapper button:active:focus,
main .agencies__wrapper .agencies__more .btn-check:active + a:focus,
main .agencies__wrapper .agencies__more .btn-check:checked + a:focus,
main .agencies__wrapper .agencies__more .show > a.dropdown-toggle:focus,
main .agencies__wrapper .agencies__more a.active:focus,
main .agencies__wrapper .agencies__more a:active:focus,
main .profile__wrapper .profile__addService .btn-check:active + button:focus,
main .profile__wrapper .profile__addService .btn-check:checked + button:focus,
main .profile__wrapper .profile__addService .show > button.dropdown-toggle:focus,
main .profile__wrapper .profile__addService button.active:focus,
main .profile__wrapper .profile__addService button:active:focus,
main .registration__wrapper .registration__register .btn-check:active + a:focus,
main .registration__wrapper .registration__register .btn-check:checked + a:focus,
main .registration__wrapper .registration__register .show > a.dropdown-toggle:focus,
main .registration__wrapper .registration__register a.active:focus,
main .registration__wrapper .registration__register a:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(5, 133, 60, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(5, 133, 60, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled,
.serviceOffCanvas .offcanvas-body .disabled.pubblica,
.serviceOffCanvas .offcanvas-body .pubblica:disabled,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:disabled,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .disabled.ctaButtons__login,
body .buorg #buorgul:disabled,
body .buorg .disabled#buorgul,
main .acceptance__wrapper button.disabled,
main .acceptance__wrapper button:disabled,
main .agencies__wrapper .agencies__more a.disabled,
main .agencies__wrapper .agencies__more a:disabled,
main .profile__wrapper .profile__addService button.disabled,
main .profile__wrapper .profile__addService button:disabled,
main .registration__wrapper .registration__register a.disabled,
main .registration__wrapper .registration__register a:disabled {
  color: #222;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #5c636a;
  border-color: #565e64;
  color: #fff;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(131, 138, 145, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(131, 138, 145, 0.5);
}

.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #565e64;
  border-color: #51585e;
  color: #fff;
}

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(131, 138, 145, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(131, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.btn-success {
  background-color: #009640;
  border-color: #009640;
  color: #222;
}

.btn-check:focus + .btn-success,
.btn-success:focus,
.btn-success:hover {
  background-color: #26a65d;
  border-color: #1aa153;
  color: #222;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(5, 133, 60, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(5, 133, 60, 0.5);
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  background-color: #33ab66;
  border-color: #1aa153;
  color: #222;
}

.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(5, 133, 60, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(5, 133, 60, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #009640;
  border-color: #009640;
  color: #222;
}

.btn-info {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  color: #222;
}

.btn-check:focus + .btn-info,
.btn-info:focus,
.btn-info:hover {
  background-color: #31d2f2;
  border-color: #25cff2;
  color: #222;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(16, 177, 209, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(16, 177, 209, 0.5);
}

.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
  background-color: #3dd5f3;
  border-color: #25cff2;
  color: #222;
}

.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(16, 177, 209, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(16, 177, 209, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  color: #222;
}

.btn-warning {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #222;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus,
.btn-warning:hover {
  background-color: #ffca2c;
  border-color: #ffc720;
  color: #222;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(222, 169, 11, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(222, 169, 11, 0.5);
}

.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
  background-color: #ffcd39;
  border-color: #ffc720;
  color: #222;
}

.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(222, 169, 11, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(222, 169, 11, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #222;
}

.btn-danger,
.serviceOffCanvas .offcanvas-body .cancella {
  background-color: #e30513;
  border-color: #e30513;
  color: #fff;
}

.btn-danger:hover,
.serviceOffCanvas .offcanvas-body .cancella:hover {
  background-color: #c10410;
  border-color: #b6040f;
  color: #fff;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus,
.serviceOffCanvas .offcanvas-body .btn-check:focus + .cancella,
.serviceOffCanvas .offcanvas-body .cancella:focus {
  background-color: #c10410;
  border-color: #b6040f;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(231, 43, 54, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(231, 43, 54, 0.5);
  color: #fff;
}

.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.serviceOffCanvas .offcanvas-body .active.cancella,
.serviceOffCanvas .offcanvas-body .btn-check:active + .cancella,
.serviceOffCanvas .offcanvas-body .btn-check:checked + .cancella,
.serviceOffCanvas .offcanvas-body .cancella:active,
.serviceOffCanvas .offcanvas-body .show > .dropdown-toggle.cancella,
.show > .btn-danger.dropdown-toggle {
  background-color: #b6040f;
  border-color: #aa040e;
  color: #fff;
}

.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.serviceOffCanvas .offcanvas-body .active.cancella:focus,
.serviceOffCanvas .offcanvas-body .btn-check:active + .cancella:focus,
.serviceOffCanvas .offcanvas-body .btn-check:checked + .cancella:focus,
.serviceOffCanvas .offcanvas-body .cancella:active:focus,
.serviceOffCanvas .offcanvas-body .show > .dropdown-toggle.cancella:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(231, 43, 54, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(231, 43, 54, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled,
.serviceOffCanvas .offcanvas-body .cancella:disabled,
.serviceOffCanvas .offcanvas-body .disabled.cancella {
  background-color: #e30513;
  border-color: #e30513;
  color: #fff;
}

.btn-light {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #222;
}

.btn-check:focus + .btn-light,
.btn-light:focus,
.btn-light:hover {
  background-color: #f9fafb;
  border-color: #f9fafb;
  color: #222;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(216, 217, 218, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(216, 217, 218, 0.5);
}

.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  background-color: #f9fafb;
  border-color: #f9fafb;
  color: #222;
}

.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(216, 217, 218, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(216, 217, 218, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #222;
}

.btn-dark {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus,
.btn-dark:hover {
  background-color: #1c1f23;
  border-color: #1a1e21;
  color: #fff;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
  background-color: #1a1e21;
  border-color: #191c1f;
  color: #fff;
}

.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

.btn-outline-primary:hover,
.serviceOffCanvas .offcanvas-body .bozza:hover,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi:hover,
main .questions__wrapper .questions__ctaButton:hover {
  color: #222;
}

.btn-outline-secondary {
  border-color: #6c757d;
  color: #6c757d;
}

.btn-outline-secondary:hover {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(109, 118, 126, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(109, 118, 126, 0.5);
}

.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(109, 118, 126, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(109, 118, 126, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  background-color: transparent;
  color: #6c757d;
}

.btn-outline-success {
  border-color: #009640;
  color: #009640;
}

.btn-outline-success:hover {
  background-color: #009640;
  border-color: #009640;
  color: #222;
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.5);
}

.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  background-color: #009640;
  border-color: #009640;
  color: #222;
}

.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  background-color: transparent;
  color: #009640;
}

.btn-outline-info {
  border-color: #0dcaf0;
  color: #0dcaf0;
}

.btn-outline-info:hover {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  color: #222;
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  color: #222;
}

.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #0dcaf0;
}

.btn-outline-warning {
  border-color: #ffc107;
  color: #ffc107;
}

.btn-outline-warning:hover {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #222;
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #222;
}

.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffc107;
}

.btn-outline-danger {
  border-color: #e30513;
  color: #e30513;
}

.btn-outline-danger:hover {
  background-color: #e30513;
  border-color: #e30513;
  color: #fff;
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.5);
}

.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  background-color: #e30513;
  border-color: #e30513;
  color: #fff;
}

.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #e30513;
}

.btn-outline-light {
  border-color: #f8f9fa;
  color: #f8f9fa;
}

.btn-outline-light:hover {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #222;
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #222;
}

.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  background-color: transparent;
  color: #f8f9fa;
}

.btn-outline-dark {
  border-color: #212529;
  color: #212529;
}

.btn-outline-dark:hover {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  background-color: transparent;
  color: #212529;
}

.btn-link {
  color: #009640;
  font-weight: 400;
  text-decoration: underline;
}

.btn-link:hover {
  color: #007833;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
}

.btn-group-lg > .btn,
.btn-lg,
.serviceOffCanvas .offcanvas-body .btn-group-lg > .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-lg > .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-lg > .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-lg > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-lg > .ctaButtons__login,
body .buorg .btn-group-lg > #buorgul,
main .acceptance__wrapper .btn-group-lg > button,
main .agencies__wrapper .agencies__more .btn-group-lg > a,
main .profile__wrapper .profile__addService .btn-group-lg > button,
main .questions__wrapper .btn-group-lg > .questions__ctaButton,
main .registration__wrapper .registration__register .btn-group-lg > a {
  border-radius: .3rem;
  font-size: 1.25rem;
  padding: .5rem 1rem;
}

.btn-group-sm > .btn,
.btn-sm,
.serviceOffCanvas .offcanvas-body .btn-group-sm > .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-sm > .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-sm > .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-sm > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-sm > .ctaButtons__login,
body .buorg .btn-group-sm > #buorgul,
main .acceptance__wrapper .btn-group-sm > button,
main .agencies__wrapper .agencies__more .btn-group-sm > a,
main .profile__wrapper .profile__addService .btn-group-sm > button,
main .questions__wrapper .btn-group-sm > .questions__ctaButton,
main .registration__wrapper .registration__register .btn-group-sm > a {
  border-radius: .2rem;
  font-size: .875rem;
  padding: .25rem .5rem;
}

.fade {
  -webkit-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height .35s ease;
  transition: height .35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  height: auto;
  -webkit-transition: width .35s ease;
  transition: width .35s ease;
  width: 0;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    -webkit-transition: none;
    transition: none;
  }
}

.btn-group,
.btn-group-vertical {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group > .btn,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group > .bozza,
.serviceOffCanvas .offcanvas-body .btn-group > .cancella,
.serviceOffCanvas .offcanvas-body .btn-group > .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__login,
body .buorg .btn-group-vertical > #buorgul,
body .buorg .btn-group > #buorgul,
main .acceptance__wrapper .btn-group-vertical > button,
main .acceptance__wrapper .btn-group > button,
main .agencies__wrapper .agencies__more .btn-group-vertical > a,
main .agencies__wrapper .agencies__more .btn-group > a,
main .profile__wrapper .profile__addService .btn-group-vertical > button,
main .profile__wrapper .profile__addService .btn-group > button,
main .questions__wrapper .btn-group-vertical > .questions__ctaButton,
main .questions__wrapper .btn-group > .questions__ctaButton,
main .registration__wrapper .registration__register .btn-group-vertical > a,
main .registration__wrapper .registration__register .btn-group > a {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  position: relative;
}

.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .active.bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .active.cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .active.pubblica,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza:active,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza:focus,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza:hover,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-check:checked + .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-check:checked + .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-check:checked + .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-check:focus + .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-check:focus + .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-check:focus + .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella:active,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella:focus,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella:hover,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica:active,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica:focus,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica:hover,
.serviceOffCanvas .offcanvas-body .btn-group > .active.bozza,
.serviceOffCanvas .offcanvas-body .btn-group > .active.cancella,
.serviceOffCanvas .offcanvas-body .btn-group > .active.pubblica,
.serviceOffCanvas .offcanvas-body .btn-group > .bozza:active,
.serviceOffCanvas .offcanvas-body .btn-group > .bozza:focus,
.serviceOffCanvas .offcanvas-body .btn-group > .bozza:hover,
.serviceOffCanvas .offcanvas-body .btn-group > .btn-check:checked + .bozza,
.serviceOffCanvas .offcanvas-body .btn-group > .btn-check:checked + .cancella,
.serviceOffCanvas .offcanvas-body .btn-group > .btn-check:checked + .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group > .btn-check:focus + .bozza,
.serviceOffCanvas .offcanvas-body .btn-group > .btn-check:focus + .cancella,
.serviceOffCanvas .offcanvas-body .btn-group > .btn-check:focus + .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group > .cancella:active,
.serviceOffCanvas .offcanvas-body .btn-group > .cancella:focus,
.serviceOffCanvas .offcanvas-body .btn-group > .cancella:hover,
.serviceOffCanvas .offcanvas-body .btn-group > .pubblica:active,
.serviceOffCanvas .offcanvas-body .btn-group > .pubblica:focus,
.serviceOffCanvas .offcanvas-body .btn-group > .pubblica:hover,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .active.ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .active.ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .btn-check:checked + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .btn-check:checked + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .btn-check:focus + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .btn-check:focus + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi:active,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi:hover,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login:active,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login:hover,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .active.ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .active.ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .btn-check:checked + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .btn-check:checked + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .btn-check:focus + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .btn-check:focus + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__comeRegistrarsi:active,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__comeRegistrarsi:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__comeRegistrarsi:hover,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__login:active,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__login:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__login:hover,
body .buorg .btn-group-vertical > #buorgul:active,
body .buorg .btn-group-vertical > #buorgul:focus,
body .buorg .btn-group-vertical > #buorgul:hover,
body .buorg .btn-group-vertical > .active#buorgul,
body .buorg .btn-group-vertical > .btn-check:checked + #buorgul,
body .buorg .btn-group-vertical > .btn-check:focus + #buorgul,
body .buorg .btn-group > #buorgul:active,
body .buorg .btn-group > #buorgul:focus,
body .buorg .btn-group > #buorgul:hover,
body .buorg .btn-group > .active#buorgul,
body .buorg .btn-group > .btn-check:checked + #buorgul,
body .buorg .btn-group > .btn-check:focus + #buorgul,
main .acceptance__wrapper .btn-group-vertical > .btn-check:checked + button,
main .acceptance__wrapper .btn-group-vertical > .btn-check:focus + button,
main .acceptance__wrapper .btn-group-vertical > button.active,
main .acceptance__wrapper .btn-group-vertical > button:active,
main .acceptance__wrapper .btn-group-vertical > button:focus,
main .acceptance__wrapper .btn-group-vertical > button:hover,
main .acceptance__wrapper .btn-group > .btn-check:checked + button,
main .acceptance__wrapper .btn-group > .btn-check:focus + button,
main .acceptance__wrapper .btn-group > button.active,
main .acceptance__wrapper .btn-group > button:active,
main .acceptance__wrapper .btn-group > button:focus,
main .acceptance__wrapper .btn-group > button:hover,
main .agencies__wrapper .agencies__more .btn-group-vertical > .btn-check:checked + a,
main .agencies__wrapper .agencies__more .btn-group-vertical > .btn-check:focus + a,
main .agencies__wrapper .agencies__more .btn-group-vertical > a.active,
main .agencies__wrapper .agencies__more .btn-group-vertical > a:active,
main .agencies__wrapper .agencies__more .btn-group-vertical > a:focus,
main .agencies__wrapper .agencies__more .btn-group-vertical > a:hover,
main .agencies__wrapper .agencies__more .btn-group > .btn-check:checked + a,
main .agencies__wrapper .agencies__more .btn-group > .btn-check:focus + a,
main .agencies__wrapper .agencies__more .btn-group > a.active,
main .agencies__wrapper .agencies__more .btn-group > a:active,
main .agencies__wrapper .agencies__more .btn-group > a:focus,
main .agencies__wrapper .agencies__more .btn-group > a:hover,
main .profile__wrapper .profile__addService .btn-group-vertical > .btn-check:checked + button,
main .profile__wrapper .profile__addService .btn-group-vertical > .btn-check:focus + button,
main .profile__wrapper .profile__addService .btn-group-vertical > button.active,
main .profile__wrapper .profile__addService .btn-group-vertical > button:active,
main .profile__wrapper .profile__addService .btn-group-vertical > button:focus,
main .profile__wrapper .profile__addService .btn-group-vertical > button:hover,
main .profile__wrapper .profile__addService .btn-group > .btn-check:checked + button,
main .profile__wrapper .profile__addService .btn-group > .btn-check:focus + button,
main .profile__wrapper .profile__addService .btn-group > button.active,
main .profile__wrapper .profile__addService .btn-group > button:active,
main .profile__wrapper .profile__addService .btn-group > button:focus,
main .profile__wrapper .profile__addService .btn-group > button:hover,
main .questions__wrapper .btn-group-vertical > .active.questions__ctaButton,
main .questions__wrapper .btn-group-vertical > .btn-check:checked + .questions__ctaButton,
main .questions__wrapper .btn-group-vertical > .btn-check:focus + .questions__ctaButton,
main .questions__wrapper .btn-group-vertical > .questions__ctaButton:active,
main .questions__wrapper .btn-group-vertical > .questions__ctaButton:focus,
main .questions__wrapper .btn-group-vertical > .questions__ctaButton:hover,
main .questions__wrapper .btn-group > .active.questions__ctaButton,
main .questions__wrapper .btn-group > .btn-check:checked + .questions__ctaButton,
main .questions__wrapper .btn-group > .btn-check:focus + .questions__ctaButton,
main .questions__wrapper .btn-group > .questions__ctaButton:active,
main .questions__wrapper .btn-group > .questions__ctaButton:focus,
main .questions__wrapper .btn-group > .questions__ctaButton:hover,
main .registration__wrapper .registration__register .btn-group-vertical > .btn-check:checked + a,
main .registration__wrapper .registration__register .btn-group-vertical > .btn-check:focus + a,
main .registration__wrapper .registration__register .btn-group-vertical > a.active,
main .registration__wrapper .registration__register .btn-group-vertical > a:active,
main .registration__wrapper .registration__register .btn-group-vertical > a:focus,
main .registration__wrapper .registration__register .btn-group-vertical > a:hover,
main .registration__wrapper .registration__register .btn-group > .btn-check:checked + a,
main .registration__wrapper .registration__register .btn-group > .btn-check:focus + a,
main .registration__wrapper .registration__register .btn-group > a.active,
main .registration__wrapper .registration__register .btn-group > a:active,
main .registration__wrapper .registration__register .btn-group > a:focus,
main .registration__wrapper .registration__register .btn-group > a:hover {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child),
.serviceOffCanvas .offcanvas-body .btn-group > .bozza:not(:first-child),
.serviceOffCanvas .offcanvas-body .btn-group > .cancella:not(:first-child),
.serviceOffCanvas .offcanvas-body .btn-group > .pubblica:not(:first-child),
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__comeRegistrarsi:not(:first-child),
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__login:not(:first-child),
body .buorg .btn-group > #buorgul:not(:first-child),
main .acceptance__wrapper .btn-group > button:not(:first-child),
main .agencies__wrapper .agencies__more .btn-group > a:not(:first-child),
main .profile__wrapper .profile__addService .btn-group > button:not(:first-child),
main .questions__wrapper .btn-group > .questions__ctaButton:not(:first-child),
main .registration__wrapper .registration__register .btn-group > a:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.serviceOffCanvas .offcanvas-body .btn-group > .bozza:not(:last-child):not(.dropdown-toggle),
.serviceOffCanvas .offcanvas-body .btn-group > .btn-group:not(:last-child) > .bozza,
.serviceOffCanvas .offcanvas-body .btn-group > .btn-group:not(:last-child) > .cancella,
.serviceOffCanvas .offcanvas-body .btn-group > .btn-group:not(:last-child) > .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group > .cancella:not(:last-child):not(.dropdown-toggle),
.serviceOffCanvas .offcanvas-body .btn-group > .pubblica:not(:last-child):not(.dropdown-toggle),
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .btn-group:not(:last-child) > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .btn-group:not(:last-child) > .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__comeRegistrarsi:not(:last-child):not(.dropdown-toggle),
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__login:not(:last-child):not(.dropdown-toggle),
body .buorg .btn-group > #buorgul:not(:last-child):not(.dropdown-toggle),
body .buorg .btn-group > .btn-group:not(:last-child) > #buorgul,
main .acceptance__wrapper .btn-group > .btn-group:not(:last-child) > button,
main .acceptance__wrapper .btn-group > button:not(:last-child):not(.dropdown-toggle),
main .agencies__wrapper .agencies__more .btn-group > .btn-group:not(:last-child) > a,
main .agencies__wrapper .agencies__more .btn-group > a:not(:last-child):not(.dropdown-toggle),
main .profile__wrapper .profile__addService .btn-group > .btn-group:not(:last-child) > button,
main .profile__wrapper .profile__addService .btn-group > button:not(:last-child):not(.dropdown-toggle),
main .questions__wrapper .btn-group > .btn-group:not(:last-child) > .questions__ctaButton,
main .questions__wrapper .btn-group > .questions__ctaButton:not(:last-child):not(.dropdown-toggle),
main .registration__wrapper .registration__register .btn-group > .btn-group:not(:last-child) > a,
main .registration__wrapper .registration__register .btn-group > a:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.serviceOffCanvas .offcanvas-body .btn-group > .bozza:nth-child(n+3),
.serviceOffCanvas .offcanvas-body .btn-group > .btn-group:not(:first-child) > .bozza,
.serviceOffCanvas .offcanvas-body .btn-group > .btn-group:not(:first-child) > .cancella,
.serviceOffCanvas .offcanvas-body .btn-group > .btn-group:not(:first-child) > .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group > .cancella:nth-child(n+3),
.serviceOffCanvas .offcanvas-body .btn-group > .pubblica:nth-child(n+3),
.serviceOffCanvas .offcanvas-body .btn-group > :not(.btn-check) + .bozza,
.serviceOffCanvas .offcanvas-body .btn-group > :not(.btn-check) + .cancella,
.serviceOffCanvas .offcanvas-body .btn-group > :not(.btn-check) + .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .btn-group:not(:first-child) > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .btn-group:not(:first-child) > .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__comeRegistrarsi:nth-child(n+3),
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > .ctaButtons__login:nth-child(n+3),
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > :not(.btn-check) + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group > :not(.btn-check) + .ctaButtons__login,
body .buorg .btn-group > #buorgul:nth-child(n+3),
body .buorg .btn-group > .btn-group:not(:first-child) > #buorgul,
body .buorg .btn-group > :not(.btn-check) + #buorgul,
main .acceptance__wrapper .btn-group > .btn-group:not(:first-child) > button,
main .acceptance__wrapper .btn-group > :not(.btn-check) + button,
main .acceptance__wrapper .btn-group > button:nth-child(n+3),
main .agencies__wrapper .agencies__more .btn-group > .btn-group:not(:first-child) > a,
main .agencies__wrapper .agencies__more .btn-group > :not(.btn-check) + a,
main .agencies__wrapper .agencies__more .btn-group > a:nth-child(n+3),
main .profile__wrapper .profile__addService .btn-group > .btn-group:not(:first-child) > button,
main .profile__wrapper .profile__addService .btn-group > :not(.btn-check) + button,
main .profile__wrapper .profile__addService .btn-group > button:nth-child(n+3),
main .questions__wrapper .btn-group > .btn-group:not(:first-child) > .questions__ctaButton,
main .questions__wrapper .btn-group > .questions__ctaButton:nth-child(n+3),
main .questions__wrapper .btn-group > :not(.btn-check) + .questions__ctaButton,
main .registration__wrapper .registration__register .btn-group > .btn-group:not(:first-child) > a,
main .registration__wrapper .registration__register .btn-group > :not(.btn-check) + a,
main .registration__wrapper .registration__register .btn-group > a:nth-child(n+3) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split,
.serviceOffCanvas .offcanvas-body .btn-group-sm > .bozza + .dropdown-toggle-split,
.serviceOffCanvas .offcanvas-body .btn-group-sm > .cancella + .dropdown-toggle-split,
.serviceOffCanvas .offcanvas-body .btn-group-sm > .pubblica + .dropdown-toggle-split,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-sm > .ctaButtons__comeRegistrarsi + .dropdown-toggle-split,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-sm > .ctaButtons__login + .dropdown-toggle-split,
body .buorg .btn-group-sm > #buorgul + .dropdown-toggle-split,
main .acceptance__wrapper .btn-group-sm > button + .dropdown-toggle-split,
main .agencies__wrapper .agencies__more .btn-group-sm > a + .dropdown-toggle-split,
main .profile__wrapper .profile__addService .btn-group-sm > button + .dropdown-toggle-split,
main .questions__wrapper .btn-group-sm > .questions__ctaButton + .dropdown-toggle-split,
main .registration__wrapper .registration__register .btn-group-sm > a + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split,
.serviceOffCanvas .offcanvas-body .btn-group-lg > .bozza + .dropdown-toggle-split,
.serviceOffCanvas .offcanvas-body .btn-group-lg > .cancella + .dropdown-toggle-split,
.serviceOffCanvas .offcanvas-body .btn-group-lg > .pubblica + .dropdown-toggle-split,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-lg > .ctaButtons__comeRegistrarsi + .dropdown-toggle-split,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-lg > .ctaButtons__login + .dropdown-toggle-split,
body .buorg .btn-group-lg > #buorgul + .dropdown-toggle-split,
main .acceptance__wrapper .btn-group-lg > button + .dropdown-toggle-split,
main .agencies__wrapper .agencies__more .btn-group-lg > a + .dropdown-toggle-split,
main .profile__wrapper .profile__addService .btn-group-lg > button + .dropdown-toggle-split,
main .questions__wrapper .btn-group-lg > .questions__ctaButton + .dropdown-toggle-split,
main .registration__wrapper .registration__register .btn-group-lg > a + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login,
body .buorg .btn-group-vertical > #buorgul,
main .acceptance__wrapper .btn-group-vertical > button,
main .agencies__wrapper .agencies__more .btn-group-vertical > a,
main .profile__wrapper .profile__addService .btn-group-vertical > button,
main .questions__wrapper .btn-group-vertical > .questions__ctaButton,
main .registration__wrapper .registration__register .btn-group-vertical > a {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child),
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza:not(:first-child),
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella:not(:first-child),
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica:not(:first-child),
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi:not(:first-child),
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login:not(:first-child),
body .buorg .btn-group-vertical > #buorgul:not(:first-child),
main .acceptance__wrapper .btn-group-vertical > button:not(:first-child),
main .agencies__wrapper .agencies__more .btn-group-vertical > a:not(:first-child),
main .profile__wrapper .profile__addService .btn-group-vertical > button:not(:first-child),
main .questions__wrapper .btn-group-vertical > .questions__ctaButton:not(:first-child),
main .registration__wrapper .registration__register .btn-group-vertical > a:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza:not(:last-child):not(.dropdown-toggle),
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-group:not(:last-child) > .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-group:not(:last-child) > .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-group:not(:last-child) > .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella:not(:last-child):not(.dropdown-toggle),
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica:not(:last-child):not(.dropdown-toggle),
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .btn-group:not(:last-child) > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .btn-group:not(:last-child) > .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi:not(:last-child):not(.dropdown-toggle),
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login:not(:last-child):not(.dropdown-toggle),
body .buorg .btn-group-vertical > #buorgul:not(:last-child):not(.dropdown-toggle),
body .buorg .btn-group-vertical > .btn-group:not(:last-child) > #buorgul,
main .acceptance__wrapper .btn-group-vertical > .btn-group:not(:last-child) > button,
main .acceptance__wrapper .btn-group-vertical > button:not(:last-child):not(.dropdown-toggle),
main .agencies__wrapper .agencies__more .btn-group-vertical > .btn-group:not(:last-child) > a,
main .agencies__wrapper .agencies__more .btn-group-vertical > a:not(:last-child):not(.dropdown-toggle),
main .profile__wrapper .profile__addService .btn-group-vertical > .btn-group:not(:last-child) > button,
main .profile__wrapper .profile__addService .btn-group-vertical > button:not(:last-child):not(.dropdown-toggle),
main .questions__wrapper .btn-group-vertical > .btn-group:not(:last-child) > .questions__ctaButton,
main .questions__wrapper .btn-group-vertical > .questions__ctaButton:not(:last-child):not(.dropdown-toggle),
main .registration__wrapper .registration__register .btn-group-vertical > .btn-group:not(:last-child) > a,
main .registration__wrapper .registration__register .btn-group-vertical > a:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ .btn,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-group:not(:first-child) > .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-group:not(:first-child) > .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn-group:not(:first-child) > .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn ~ .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn ~ .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .btn ~ .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ .btn,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ .pubblica,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ .bozza,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ .btn,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ .cancella,
.serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ .pubblica,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .bozza ~ .ctaButtons__comeRegistrarsi,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .bozza ~ .ctaButtons__login,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .cancella ~ .ctaButtons__comeRegistrarsi,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .cancella ~ .ctaButtons__login,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .bozza,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .cancella,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .pubblica,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ .bozza,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ .cancella,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ .pubblica,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .pubblica ~ .ctaButtons__comeRegistrarsi,
.serviceOffCanvas .offcanvas-body .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .pubblica ~ .ctaButtons__login,
.serviceOffCanvas .offcanvas-body body .buorg .btn-group-vertical > #buorgul ~ .bozza,
.serviceOffCanvas .offcanvas-body body .buorg .btn-group-vertical > #buorgul ~ .cancella,
.serviceOffCanvas .offcanvas-body body .buorg .btn-group-vertical > #buorgul ~ .pubblica,
.serviceOffCanvas .offcanvas-body body .buorg .btn-group-vertical > .bozza ~ #buorgul,
.serviceOffCanvas .offcanvas-body body .buorg .btn-group-vertical > .cancella ~ #buorgul,
.serviceOffCanvas .offcanvas-body body .buorg .btn-group-vertical > .pubblica ~ #buorgul,
.serviceOffCanvas .offcanvas-body main .acceptance__wrapper .btn-group-vertical > .bozza ~ button,
.serviceOffCanvas .offcanvas-body main .acceptance__wrapper .btn-group-vertical > .cancella ~ button,
.serviceOffCanvas .offcanvas-body main .acceptance__wrapper .btn-group-vertical > .pubblica ~ button,
.serviceOffCanvas .offcanvas-body main .acceptance__wrapper .btn-group-vertical > button ~ .bozza,
.serviceOffCanvas .offcanvas-body main .acceptance__wrapper .btn-group-vertical > button ~ .cancella,
.serviceOffCanvas .offcanvas-body main .acceptance__wrapper .btn-group-vertical > button ~ .pubblica,
.serviceOffCanvas .offcanvas-body main .agencies__wrapper .agencies__more .btn-group-vertical > .bozza ~ a,
.serviceOffCanvas .offcanvas-body main .agencies__wrapper .agencies__more .btn-group-vertical > .cancella ~ a,
.serviceOffCanvas .offcanvas-body main .agencies__wrapper .agencies__more .btn-group-vertical > .pubblica ~ a,
.serviceOffCanvas .offcanvas-body main .agencies__wrapper .agencies__more .btn-group-vertical > a ~ .bozza,
.serviceOffCanvas .offcanvas-body main .agencies__wrapper .agencies__more .btn-group-vertical > a ~ .cancella,
.serviceOffCanvas .offcanvas-body main .agencies__wrapper .agencies__more .btn-group-vertical > a ~ .pubblica,
.serviceOffCanvas .offcanvas-body main .profile__wrapper .profile__addService .btn-group-vertical > .bozza ~ button,
.serviceOffCanvas .offcanvas-body main .profile__wrapper .profile__addService .btn-group-vertical > .cancella ~ button,
.serviceOffCanvas .offcanvas-body main .profile__wrapper .profile__addService .btn-group-vertical > .pubblica ~ button,
.serviceOffCanvas .offcanvas-body main .profile__wrapper .profile__addService .btn-group-vertical > button ~ .bozza,
.serviceOffCanvas .offcanvas-body main .profile__wrapper .profile__addService .btn-group-vertical > button ~ .cancella,
.serviceOffCanvas .offcanvas-body main .profile__wrapper .profile__addService .btn-group-vertical > button ~ .pubblica,
.serviceOffCanvas .offcanvas-body main .questions__wrapper .btn-group-vertical > .bozza ~ .questions__ctaButton,
.serviceOffCanvas .offcanvas-body main .questions__wrapper .btn-group-vertical > .cancella ~ .questions__ctaButton,
.serviceOffCanvas .offcanvas-body main .questions__wrapper .btn-group-vertical > .pubblica ~ .questions__ctaButton,
.serviceOffCanvas .offcanvas-body main .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ .bozza,
.serviceOffCanvas .offcanvas-body main .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ .cancella,
.serviceOffCanvas .offcanvas-body main .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ .pubblica,
.serviceOffCanvas .offcanvas-body main .registration__wrapper .registration__register .btn-group-vertical > .bozza ~ a,
.serviceOffCanvas .offcanvas-body main .registration__wrapper .registration__register .btn-group-vertical > .cancella ~ a,
.serviceOffCanvas .offcanvas-body main .registration__wrapper .registration__register .btn-group-vertical > .pubblica ~ a,
.serviceOffCanvas .offcanvas-body main .registration__wrapper .registration__register .btn-group-vertical > a ~ .bozza,
.serviceOffCanvas .offcanvas-body main .registration__wrapper .registration__register .btn-group-vertical > a ~ .cancella,
.serviceOffCanvas .offcanvas-body main .registration__wrapper .registration__register .btn-group-vertical > a ~ .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .btn-group:not(:first-child) > .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .btn-group:not(:first-child) > .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .btn ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .btn ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .btn,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ .btn,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .bozza,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .cancella,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .ctaButtons__login ~ .bozza,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .ctaButtons__login ~ .cancella,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .ctaButtons__login ~ .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons body .buorg .btn-group-vertical > #buorgul ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons body .buorg .btn-group-vertical > #buorgul ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons body .buorg .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ #buorgul,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons body .buorg .btn-group-vertical > .ctaButtons__login ~ #buorgul,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .acceptance__wrapper .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ button,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .acceptance__wrapper .btn-group-vertical > .ctaButtons__login ~ button,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .acceptance__wrapper .btn-group-vertical > button ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .acceptance__wrapper .btn-group-vertical > button ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .agencies__wrapper .agencies__more .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ a,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .agencies__wrapper .agencies__more .btn-group-vertical > .ctaButtons__login ~ a,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .agencies__wrapper .agencies__more .btn-group-vertical > a ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .agencies__wrapper .agencies__more .btn-group-vertical > a ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .profile__wrapper .profile__addService .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ button,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .profile__wrapper .profile__addService .btn-group-vertical > .ctaButtons__login ~ button,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .profile__wrapper .profile__addService .btn-group-vertical > button ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .profile__wrapper .profile__addService .btn-group-vertical > button ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .questions__wrapper .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .questions__ctaButton,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .questions__wrapper .btn-group-vertical > .ctaButtons__login ~ .questions__ctaButton,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .registration__wrapper .registration__register .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ a,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .registration__wrapper .registration__register .btn-group-vertical > .ctaButtons__login ~ a,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .registration__wrapper .registration__register .btn-group-vertical > a ~ .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons main .registration__wrapper .registration__register .btn-group-vertical > a ~ .ctaButtons__login,
body .buorg .btn-group-vertical > #buorgul ~ #buorgul,
body .buorg .btn-group-vertical > #buorgul ~ .btn,
body .buorg .btn-group-vertical > .btn-group:not(:first-child) > #buorgul,
body .buorg .btn-group-vertical > .btn ~ #buorgul,
body .buorg .serviceOffCanvas .offcanvas-body .btn-group-vertical > #buorgul ~ .bozza,
body .buorg .serviceOffCanvas .offcanvas-body .btn-group-vertical > #buorgul ~ .cancella,
body .buorg .serviceOffCanvas .offcanvas-body .btn-group-vertical > #buorgul ~ .pubblica,
body .buorg .serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ #buorgul,
body .buorg .serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ #buorgul,
body .buorg .serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ #buorgul,
body .buorg .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > #buorgul ~ .ctaButtons__comeRegistrarsi,
body .buorg .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > #buorgul ~ .ctaButtons__login,
body .buorg .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ #buorgul,
body .buorg .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ #buorgul,
body .buorg main .acceptance__wrapper .btn-group-vertical > #buorgul ~ button,
body .buorg main .acceptance__wrapper .btn-group-vertical > button ~ #buorgul,
body .buorg main .agencies__wrapper .agencies__more .btn-group-vertical > #buorgul ~ a,
body .buorg main .agencies__wrapper .agencies__more .btn-group-vertical > a ~ #buorgul,
body .buorg main .profile__wrapper .profile__addService .btn-group-vertical > #buorgul ~ button,
body .buorg main .profile__wrapper .profile__addService .btn-group-vertical > button ~ #buorgul,
body .buorg main .questions__wrapper .btn-group-vertical > #buorgul ~ .questions__ctaButton,
body .buorg main .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ #buorgul,
body .buorg main .registration__wrapper .registration__register .btn-group-vertical > #buorgul ~ a,
body .buorg main .registration__wrapper .registration__register .btn-group-vertical > a ~ #buorgul,
main .acceptance__wrapper .agencies__wrapper .agencies__more .btn-group-vertical > a ~ button,
main .acceptance__wrapper .agencies__wrapper .agencies__more .btn-group-vertical > button ~ a,
main .acceptance__wrapper .btn-group-vertical > .btn-group:not(:first-child) > button,
main .acceptance__wrapper .btn-group-vertical > .btn ~ button,
main .acceptance__wrapper .btn-group-vertical > button ~ .btn,
main .acceptance__wrapper .btn-group-vertical > button ~ button,
main .acceptance__wrapper .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ button,
main .acceptance__wrapper .questions__wrapper .btn-group-vertical > button ~ .questions__ctaButton,
main .acceptance__wrapper .registration__wrapper .registration__register .btn-group-vertical > a ~ button,
main .acceptance__wrapper .registration__wrapper .registration__register .btn-group-vertical > button ~ a,
main .acceptance__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ button,
main .acceptance__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ button,
main .acceptance__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ button,
main .acceptance__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > button ~ .bozza,
main .acceptance__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > button ~ .cancella,
main .acceptance__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > button ~ .pubblica,
main .acceptance__wrapper .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ button,
main .acceptance__wrapper .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ button,
main .acceptance__wrapper .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > button ~ .ctaButtons__comeRegistrarsi,
main .acceptance__wrapper .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > button ~ .ctaButtons__login,
main .acceptance__wrapper body .buorg .btn-group-vertical > #buorgul ~ button,
main .acceptance__wrapper body .buorg .btn-group-vertical > button ~ #buorgul,
main .agencies__wrapper .agencies__more .acceptance__wrapper .btn-group-vertical > a ~ button,
main .agencies__wrapper .agencies__more .acceptance__wrapper .btn-group-vertical > button ~ a,
main .agencies__wrapper .agencies__more .btn-group-vertical > .btn-group:not(:first-child) > a,
main .agencies__wrapper .agencies__more .btn-group-vertical > .btn ~ a,
main .agencies__wrapper .agencies__more .btn-group-vertical > a ~ .btn,
main .agencies__wrapper .agencies__more .btn-group-vertical > a ~ a,
main .agencies__wrapper .agencies__more .profile__wrapper .profile__addService .btn-group-vertical > a ~ button,
main .agencies__wrapper .agencies__more .profile__wrapper .profile__addService .btn-group-vertical > button ~ a,
main .agencies__wrapper .agencies__more .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ a,
main .agencies__wrapper .agencies__more .questions__wrapper .btn-group-vertical > a ~ .questions__ctaButton,
main .agencies__wrapper .agencies__more .serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ a,
main .agencies__wrapper .agencies__more .serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ a,
main .agencies__wrapper .agencies__more .serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ a,
main .agencies__wrapper .agencies__more .serviceOffCanvas .offcanvas-body .btn-group-vertical > a ~ .bozza,
main .agencies__wrapper .agencies__more .serviceOffCanvas .offcanvas-body .btn-group-vertical > a ~ .cancella,
main .agencies__wrapper .agencies__more .serviceOffCanvas .offcanvas-body .btn-group-vertical > a ~ .pubblica,
main .agencies__wrapper .agencies__more .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ a,
main .agencies__wrapper .agencies__more .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ a,
main .agencies__wrapper .agencies__more .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > a ~ .ctaButtons__comeRegistrarsi,
main .agencies__wrapper .agencies__more .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > a ~ .ctaButtons__login,
main .agencies__wrapper .agencies__more body .buorg .btn-group-vertical > #buorgul ~ a,
main .agencies__wrapper .agencies__more body .buorg .btn-group-vertical > a ~ #buorgul,
main .profile__wrapper .profile__addService .agencies__wrapper .agencies__more .btn-group-vertical > a ~ button,
main .profile__wrapper .profile__addService .agencies__wrapper .agencies__more .btn-group-vertical > button ~ a,
main .profile__wrapper .profile__addService .btn-group-vertical > .btn-group:not(:first-child) > button,
main .profile__wrapper .profile__addService .btn-group-vertical > .btn ~ button,
main .profile__wrapper .profile__addService .btn-group-vertical > button ~ .btn,
main .profile__wrapper .profile__addService .btn-group-vertical > button ~ button,
main .profile__wrapper .profile__addService .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ button,
main .profile__wrapper .profile__addService .questions__wrapper .btn-group-vertical > button ~ .questions__ctaButton,
main .profile__wrapper .profile__addService .registration__wrapper .registration__register .btn-group-vertical > a ~ button,
main .profile__wrapper .profile__addService .registration__wrapper .registration__register .btn-group-vertical > button ~ a,
main .profile__wrapper .profile__addService .serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ button,
main .profile__wrapper .profile__addService .serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ button,
main .profile__wrapper .profile__addService .serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ button,
main .profile__wrapper .profile__addService .serviceOffCanvas .offcanvas-body .btn-group-vertical > button ~ .bozza,
main .profile__wrapper .profile__addService .serviceOffCanvas .offcanvas-body .btn-group-vertical > button ~ .cancella,
main .profile__wrapper .profile__addService .serviceOffCanvas .offcanvas-body .btn-group-vertical > button ~ .pubblica,
main .profile__wrapper .profile__addService .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ button,
main .profile__wrapper .profile__addService .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ button,
main .profile__wrapper .profile__addService .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > button ~ .ctaButtons__comeRegistrarsi,
main .profile__wrapper .profile__addService .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > button ~ .ctaButtons__login,
main .profile__wrapper .profile__addService body .buorg .btn-group-vertical > #buorgul ~ button,
main .profile__wrapper .profile__addService body .buorg .btn-group-vertical > button ~ #buorgul,
main .questions__wrapper .acceptance__wrapper .btn-group-vertical > .questions__ctaButton ~ button,
main .questions__wrapper .acceptance__wrapper .btn-group-vertical > button ~ .questions__ctaButton,
main .questions__wrapper .agencies__wrapper .agencies__more .btn-group-vertical > .questions__ctaButton ~ a,
main .questions__wrapper .agencies__wrapper .agencies__more .btn-group-vertical > a ~ .questions__ctaButton,
main .questions__wrapper .btn-group-vertical > .btn-group:not(:first-child) > .questions__ctaButton,
main .questions__wrapper .btn-group-vertical > .btn ~ .questions__ctaButton,
main .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ .btn,
main .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ .questions__ctaButton,
main .questions__wrapper .profile__wrapper .profile__addService .btn-group-vertical > .questions__ctaButton ~ button,
main .questions__wrapper .profile__wrapper .profile__addService .btn-group-vertical > button ~ .questions__ctaButton,
main .questions__wrapper .registration__wrapper .registration__register .btn-group-vertical > .questions__ctaButton ~ a,
main .questions__wrapper .registration__wrapper .registration__register .btn-group-vertical > a ~ .questions__ctaButton,
main .questions__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ .questions__ctaButton,
main .questions__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ .questions__ctaButton,
main .questions__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ .questions__ctaButton,
main .questions__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > .questions__ctaButton ~ .bozza,
main .questions__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > .questions__ctaButton ~ .cancella,
main .questions__wrapper .serviceOffCanvas .offcanvas-body .btn-group-vertical > .questions__ctaButton ~ .pubblica,
main .questions__wrapper .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ .questions__ctaButton,
main .questions__wrapper .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ .questions__ctaButton,
main .questions__wrapper .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .questions__ctaButton ~ .ctaButtons__comeRegistrarsi,
main .questions__wrapper .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .questions__ctaButton ~ .ctaButtons__login,
main .questions__wrapper body .buorg .btn-group-vertical > #buorgul ~ .questions__ctaButton,
main .questions__wrapper body .buorg .btn-group-vertical > .questions__ctaButton ~ #buorgul,
main .registration__wrapper .registration__register .acceptance__wrapper .btn-group-vertical > a ~ button,
main .registration__wrapper .registration__register .acceptance__wrapper .btn-group-vertical > button ~ a,
main .registration__wrapper .registration__register .btn-group-vertical > .btn-group:not(:first-child) > a,
main .registration__wrapper .registration__register .btn-group-vertical > .btn ~ a,
main .registration__wrapper .registration__register .btn-group-vertical > a ~ .btn,
main .registration__wrapper .registration__register .btn-group-vertical > a ~ a,
main .registration__wrapper .registration__register .profile__wrapper .profile__addService .btn-group-vertical > a ~ button,
main .registration__wrapper .registration__register .profile__wrapper .profile__addService .btn-group-vertical > button ~ a,
main .registration__wrapper .registration__register .questions__wrapper .btn-group-vertical > .questions__ctaButton ~ a,
main .registration__wrapper .registration__register .questions__wrapper .btn-group-vertical > a ~ .questions__ctaButton,
main .registration__wrapper .registration__register .serviceOffCanvas .offcanvas-body .btn-group-vertical > .bozza ~ a,
main .registration__wrapper .registration__register .serviceOffCanvas .offcanvas-body .btn-group-vertical > .cancella ~ a,
main .registration__wrapper .registration__register .serviceOffCanvas .offcanvas-body .btn-group-vertical > .pubblica ~ a,
main .registration__wrapper .registration__register .serviceOffCanvas .offcanvas-body .btn-group-vertical > a ~ .bozza,
main .registration__wrapper .registration__register .serviceOffCanvas .offcanvas-body .btn-group-vertical > a ~ .cancella,
main .registration__wrapper .registration__register .serviceOffCanvas .offcanvas-body .btn-group-vertical > a ~ .pubblica,
main .registration__wrapper .registration__register .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__comeRegistrarsi ~ a,
main .registration__wrapper .registration__register .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > .ctaButtons__login ~ a,
main .registration__wrapper .registration__register .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > a ~ .ctaButtons__comeRegistrarsi,
main .registration__wrapper .registration__register .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-group-vertical > a ~ .ctaButtons__login,
main .registration__wrapper .registration__register body .buorg .btn-group-vertical > #buorgul ~ a,
main .registration__wrapper .registration__register body .buorg .btn-group-vertical > a ~ #buorgul {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab,
.nav,
main .faq__wrapper .nav-anchor,
main .profile__services .status__filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.nav-link,
main .faq__wrapper .nav-anchor li a,
main .profile__services .status__filter li a {
  color: #6c757d;
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
  .nav-link,
  main .faq__wrapper .nav-anchor li a,
  main .profile__services .status__filter li a {
    -webkit-transition: none;
    transition: none;
  }
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:focus,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:hover,
.nav-link:focus,
.nav-link:hover,
main .faq__wrapper .nav-anchor li a:focus,
main .faq__wrapper .nav-anchor li a:hover,
main .profile__services .status__filter li a:focus,
main .profile__services .status__filter li a:hover {
  color: #009640;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.disabled,
.nav-link.disabled,
main .faq__wrapper .nav-anchor li a.disabled,
main .profile__services .status__filter li a.disabled {
  color: #6c757d;
  cursor: default;
  pointer-events: none;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-tabs a,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-tabs .content__tab li a,
.nav-tabs .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.nav-tabs .nav-link,
.nav-tabs main .faq__wrapper .nav-anchor li a,
.nav-tabs main .profile__services .status__filter li a,
main .faq__wrapper .nav-anchor li .nav-tabs a,
main .faq__wrapper .nav-tabs .nav-anchor li a,
main .profile__services .nav-tabs .status__filter li a,
main .profile__services .status__filter li .nav-tabs a {
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  margin-bottom: -1px;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-tabs a:focus,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-tabs a:hover,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-tabs .content__tab li a:focus,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-tabs .content__tab li a:hover,
.nav-tabs .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:focus,
.nav-tabs .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover,
.nav-tabs main .faq__wrapper .nav-anchor li a:focus,
.nav-tabs main .faq__wrapper .nav-anchor li a:hover,
.nav-tabs main .profile__services .status__filter li a:focus,
.nav-tabs main .profile__services .status__filter li a:hover,
main .faq__wrapper .nav-anchor li .nav-tabs a:focus,
main .faq__wrapper .nav-anchor li .nav-tabs a:hover,
main .faq__wrapper .nav-tabs .nav-anchor li a:focus,
main .faq__wrapper .nav-tabs .nav-anchor li a:hover,
main .profile__services .nav-tabs .status__filter li a:focus,
main .profile__services .nav-tabs .status__filter li a:hover,
main .profile__services .status__filter li .nav-tabs a:focus,
main .profile__services .status__filter li .nav-tabs a:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-tabs a.disabled,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-tabs .content__tab li a.disabled,
.nav-tabs .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.disabled,
.nav-tabs .nav-link.disabled,
.nav-tabs main .faq__wrapper .nav-anchor li a.disabled,
.nav-tabs main .profile__services .status__filter li a.disabled,
main .faq__wrapper .nav-anchor li .nav-tabs a.disabled,
main .faq__wrapper .nav-tabs .nav-anchor li a.disabled,
main .profile__services .nav-tabs .status__filter li a.disabled,
main .profile__services .status__filter li .nav-tabs a.disabled {
  background-color: transparent;
  border-color: transparent;
  color: #6c757d;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .nav-tabs li.show .nav-link,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .nav-tabs li.show a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-tabs .nav-item.show a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-tabs a.active,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-tabs .content__tab li.show .nav-link,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-tabs .content__tab li.show a,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-tabs .content__tab li a.active,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-tabs .nav-item.show .content__tab li a,
.nav-tabs .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li.show .nav-link,
.nav-tabs .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li.show a,
.nav-tabs .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.active,
.nav-tabs .nav-item.show .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item.show main .faq__wrapper .nav-anchor li a,
.nav-tabs .nav-item.show main .profile__services .status__filter li a,
.nav-tabs .nav-link.active,
.nav-tabs main .faq__wrapper .nav-anchor li.show .nav-link,
.nav-tabs main .faq__wrapper .nav-anchor li.show a,
.nav-tabs main .faq__wrapper .nav-anchor li a.active,
.nav-tabs main .profile__services .status__filter li.show .nav-link,
.nav-tabs main .profile__services .status__filter li.show a,
.nav-tabs main .profile__services .status__filter li a.active,
main .faq__wrapper .nav-anchor .nav-tabs li.show .nav-link,
main .faq__wrapper .nav-anchor .nav-tabs li.show a,
main .faq__wrapper .nav-anchor li .nav-tabs .nav-item.show a,
main .faq__wrapper .nav-anchor li .nav-tabs a.active,
main .faq__wrapper .nav-tabs .nav-anchor li.show .nav-link,
main .faq__wrapper .nav-tabs .nav-anchor li.show a,
main .faq__wrapper .nav-tabs .nav-anchor li a.active,
main .faq__wrapper .nav-tabs .nav-item.show .nav-anchor li a,
main .profile__services .nav-tabs .nav-item.show .status__filter li a,
main .profile__services .nav-tabs .status__filter li.show .nav-link,
main .profile__services .nav-tabs .status__filter li.show a,
main .profile__services .nav-tabs .status__filter li a.active,
main .profile__services .status__filter .nav-tabs li.show .nav-link,
main .profile__services .status__filter .nav-tabs li.show a,
main .profile__services .status__filter li .nav-tabs .nav-item.show a,
main .profile__services .status__filter li .nav-tabs a.active {
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  color: #495057;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-pills a,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-pills .content__tab li a,
.nav-pills .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.nav-pills .nav-link,
.nav-pills main .faq__wrapper .nav-anchor li a,
.nav-pills main .profile__services .status__filter li a,
main .faq__wrapper .nav-anchor li .nav-pills a,
main .faq__wrapper .nav-pills .nav-anchor li a,
main .profile__services .nav-pills .status__filter li a,
main .profile__services .status__filter li .nav-pills a {
  background: none;
  border: 0;
  border-radius: .25rem;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-pills .show > a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-pills a.active,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-pills .content__tab li .show > a,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-pills .content__tab li a.active,
.nav-pills .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .show > a,
.nav-pills .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.active,
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.nav-pills main .faq__wrapper .nav-anchor li .show > a,
.nav-pills main .faq__wrapper .nav-anchor li a.active,
.nav-pills main .profile__services .status__filter li .show > a,
.nav-pills main .profile__services .status__filter li a.active,
main .faq__wrapper .nav-anchor li .nav-pills .show > a,
main .faq__wrapper .nav-anchor li .nav-pills a.active,
main .faq__wrapper .nav-pills .nav-anchor li .show > a,
main .faq__wrapper .nav-pills .nav-anchor li a.active,
main .profile__services .nav-pills .status__filter li .show > a,
main .profile__services .nav-pills .status__filter li a.active,
main .profile__services .status__filter li .nav-pills .show > a,
main .profile__services .status__filter li .nav-pills a.active {
  background-color: #009640;
  color: #fff;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .nav-fill li,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-fill > a,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-fill .content__tab li,
.nav-fill .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li,
.nav-fill .nav-item,
.nav-fill > .nav-link,
.nav-fill main .faq__wrapper .nav-anchor li,
.nav-fill main .profile__services .status__filter li,
main .faq__wrapper .nav-anchor .nav-fill li,
main .faq__wrapper .nav-anchor li .nav-fill > a,
main .faq__wrapper .nav-fill .nav-anchor li,
main .profile__services .nav-fill .status__filter li,
main .profile__services .status__filter .nav-fill li,
main .profile__services .status__filter li .nav-fill > a {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .nav-justified li,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-justified > a,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-justified .content__tab li,
.nav-justified .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li,
.nav-justified .nav-item,
.nav-justified > .nav-link,
.nav-justified main .faq__wrapper .nav-anchor li,
.nav-justified main .profile__services .status__filter li,
main .faq__wrapper .nav-anchor .nav-justified li,
main .faq__wrapper .nav-anchor li .nav-justified > a,
main .faq__wrapper .nav-justified .nav-anchor li,
main .profile__services .nav-justified .status__filter li,
main .profile__services .status__filter .nav-justified li,
main .profile__services .status__filter li .nav-justified > a {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .nav-fill li .nav-link,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .nav-fill li a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .nav-justified li .nav-link,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .nav-justified li a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-fill .nav-item a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-justified .nav-item a,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-fill .content__tab li .nav-link,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-fill .content__tab li a,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-justified .content__tab li .nav-link,
.modal .modal-dialog .modal-content .modal-body .body__content .nav-justified .content__tab li a,
.nav-fill .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-link,
.nav-fill .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.nav-fill .nav-item .nav-link,
.nav-fill main .faq__wrapper .nav-anchor li .nav-link,
.nav-fill main .faq__wrapper .nav-anchor li a,
.nav-fill main .profile__services .status__filter li .nav-link,
.nav-fill main .profile__services .status__filter li a,
.nav-justified .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-link,
.nav-justified .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.nav-justified .nav-item .nav-link,
.nav-justified main .faq__wrapper .nav-anchor li .nav-link,
.nav-justified main .faq__wrapper .nav-anchor li a,
.nav-justified main .profile__services .status__filter li .nav-link,
.nav-justified main .profile__services .status__filter li a,
main .faq__wrapper .nav-anchor .nav-fill li .nav-link,
main .faq__wrapper .nav-anchor .nav-fill li a,
main .faq__wrapper .nav-anchor .nav-justified li .nav-link,
main .faq__wrapper .nav-anchor .nav-justified li a,
main .faq__wrapper .nav-anchor li .nav-fill .nav-item a,
main .faq__wrapper .nav-anchor li .nav-justified .nav-item a,
main .faq__wrapper .nav-fill .nav-anchor li .nav-link,
main .faq__wrapper .nav-fill .nav-anchor li a,
main .faq__wrapper .nav-justified .nav-anchor li .nav-link,
main .faq__wrapper .nav-justified .nav-anchor li a,
main .profile__services .nav-fill .status__filter li .nav-link,
main .profile__services .nav-fill .status__filter li a,
main .profile__services .nav-justified .status__filter li .nav-link,
main .profile__services .nav-justified .status__filter li a,
main .profile__services .status__filter .nav-fill li .nav-link,
main .profile__services .status__filter .nav-fill li a,
main .profile__services .status__filter .nav-justified li .nav-link,
main .profile__services .status__filter .nav-justified li a,
main .profile__services .status__filter li .nav-fill .nav-item a,
main .profile__services .status__filter li .nav-justified .nav-item a {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: .5rem;
  padding-top: .5rem;
  position: relative;
}

.hero .navbar > .wrapper,
.landing .navbar > .navbar-wrapper,
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl,
.navbar > .ecosystem,
.navbar > .navbar-wrapper,
.navbar > .requests__wrapper,
.navbar > footer,
.stickyBar__wrapper .navbar > .stickyBar__container,
main .info__wrapper .navbar > .info,
main .navbar > .acceptance__wrapper,
main .navbar > .agencies__wrapper,
main .navbar > .faq__wrapper,
main .navbar > .marketplace_wrapper,
main .navbar > .profile__data,
main .navbar > .profile__requests,
main .navbar > .profile__services,
main .navbar > .profile__wrapper,
main .navbar > .questions__wrapper,
main .navbar > .registration__wrapper,
main .navbar > hr {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
      flex-wrap: inherit;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.navbar-brand {
  font-size: 1.25rem;
  margin-right: 1rem;
  padding-bottom: .3125rem;
  padding-top: .3125rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-nav a,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li a,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.navbar-nav .nav-link,
.navbar-nav main .faq__wrapper .nav-anchor li a,
.navbar-nav main .profile__services .status__filter li a,
main .faq__wrapper .nav-anchor li .navbar-nav a,
main .faq__wrapper .navbar-nav .nav-anchor li a,
main .profile__services .navbar-nav .status__filter li a,
main .profile__services .status__filter li .navbar-nav a {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-bottom: .5rem;
  padding-top: .5rem;
}

.navbar-collapse {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.navbar-toggler {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: .6875rem;
  padding: 1px 6px;
  -webkit-transition: -webkit-box-shadow .15s ease-in-out;
  transition: -webkit-box-shadow .15s ease-in-out;
  transition: box-shadow .15s ease-in-out;
  transition: box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    -webkit-transition: none;
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  -webkit-box-shadow: 0 0 0 .25rem;
          box-shadow: 0 0 0 .25rem;
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-expand-sm .navbar-nav a,
  .modal .modal-dialog .modal-content .modal-body .body__content .navbar-expand-sm .navbar-nav .content__tab li a,
  .navbar-expand-sm .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
  .navbar-expand-sm .navbar-nav .nav-link,
  .navbar-expand-sm .navbar-nav main .faq__wrapper .nav-anchor li a,
  .navbar-expand-sm .navbar-nav main .profile__services .status__filter li a,
  main .faq__wrapper .nav-anchor li .navbar-expand-sm .navbar-nav a,
  main .faq__wrapper .navbar-expand-sm .navbar-nav .nav-anchor li a,
  main .profile__services .navbar-expand-sm .navbar-nav .status__filter li a,
  main .profile__services .status__filter li .navbar-expand-sm .navbar-nav a {
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler,
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: inherit;
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }
  .navbar-expand-sm .offcanvas-bottom,
  .navbar-expand-sm .offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }
  .navbar-expand-sm .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-expand-md .navbar-nav a,
  .modal .modal-dialog .modal-content .modal-body .body__content .navbar-expand-md .navbar-nav .content__tab li a,
  .navbar-expand-md .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
  .navbar-expand-md .navbar-nav .nav-link,
  .navbar-expand-md .navbar-nav main .faq__wrapper .nav-anchor li a,
  .navbar-expand-md .navbar-nav main .profile__services .status__filter li a,
  main .faq__wrapper .nav-anchor li .navbar-expand-md .navbar-nav a,
  main .faq__wrapper .navbar-expand-md .navbar-nav .nav-anchor li a,
  main .profile__services .navbar-expand-md .navbar-nav .status__filter li a,
  main .profile__services .status__filter li .navbar-expand-md .navbar-nav a {
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler,
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: inherit;
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }
  .navbar-expand-md .offcanvas-bottom,
  .navbar-expand-md .offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }
  .navbar-expand-md .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .navbar,
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav,
  .navbar .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu,
  .navbar .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-expand-lg .navbar-nav a,
  .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar .navbar-nav a,
  .modal .modal-dialog .modal-content .modal-body .body__content .navbar-expand-lg .navbar-nav .content__tab li a,
  .modal .modal-dialog .modal-content .modal-body .body__content .navbar .navbar-nav .content__tab li a,
  .navbar-expand-lg .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
  .navbar-expand-lg .navbar-nav .nav-link,
  .navbar-expand-lg .navbar-nav main .faq__wrapper .nav-anchor li a,
  .navbar-expand-lg .navbar-nav main .profile__services .status__filter li a,
  .navbar .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
  .navbar .navbar-nav .nav-link,
  .navbar .navbar-nav main .faq__wrapper .nav-anchor li a,
  .navbar .navbar-nav main .profile__services .status__filter li a,
  main .faq__wrapper .nav-anchor li .navbar-expand-lg .navbar-nav a,
  main .faq__wrapper .nav-anchor li .navbar .navbar-nav a,
  main .faq__wrapper .navbar-expand-lg .navbar-nav .nav-anchor li a,
  main .faq__wrapper .navbar .navbar-nav .nav-anchor li a,
  main .profile__services .navbar-expand-lg .navbar-nav .status__filter li a,
  main .profile__services .navbar .navbar-nav .status__filter li a,
  main .profile__services .status__filter li .navbar-expand-lg .navbar-nav a,
  main .profile__services .status__filter li .navbar .navbar-nav a {
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll,
  .navbar .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse,
  .navbar .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler,
  .navbar-expand-lg .offcanvas-header,
  .navbar .navbar-toggler,
  .navbar .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas,
  .navbar .offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: inherit;
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }
  .navbar-expand-lg .offcanvas-bottom,
  .navbar-expand-lg .offcanvas-top,
  .navbar .offcanvas-bottom,
  .navbar .offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }
  .navbar-expand-lg .offcanvas-body,
  .navbar .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-expand-xl .navbar-nav a,
  .modal .modal-dialog .modal-content .modal-body .body__content .navbar-expand-xl .navbar-nav .content__tab li a,
  .navbar-expand-xl .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
  .navbar-expand-xl .navbar-nav .nav-link,
  .navbar-expand-xl .navbar-nav main .faq__wrapper .nav-anchor li a,
  .navbar-expand-xl .navbar-nav main .profile__services .status__filter li a,
  main .faq__wrapper .nav-anchor li .navbar-expand-xl .navbar-nav a,
  main .faq__wrapper .navbar-expand-xl .navbar-nav .nav-anchor li a,
  main .profile__services .navbar-expand-xl .navbar-nav .status__filter li a,
  main .profile__services .status__filter li .navbar-expand-xl .navbar-nav a {
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler,
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: inherit;
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }
  .navbar-expand-xl .offcanvas-bottom,
  .navbar-expand-xl .offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }
  .navbar-expand-xl .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-expand-xxl .navbar-nav a,
  .modal .modal-dialog .modal-content .modal-body .body__content .navbar-expand-xxl .navbar-nav .content__tab li a,
  .navbar-expand-xxl .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
  .navbar-expand-xxl .navbar-nav .nav-link,
  .navbar-expand-xxl .navbar-nav main .faq__wrapper .nav-anchor li a,
  .navbar-expand-xxl .navbar-nav main .profile__services .status__filter li a,
  main .faq__wrapper .nav-anchor li .navbar-expand-xxl .navbar-nav a,
  main .faq__wrapper .navbar-expand-xxl .navbar-nav .nav-anchor li a,
  main .profile__services .navbar-expand-xxl .navbar-nav .status__filter li a,
  main .profile__services .status__filter li .navbar-expand-xxl .navbar-nav a {
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler,
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: inherit;
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }
  .navbar-expand-xxl .offcanvas-bottom,
  .navbar-expand-xxl .offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

.navbar-expand {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-expand .navbar-nav a,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-expand .navbar-nav .content__tab li a,
.navbar-expand .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.navbar-expand .navbar-nav .nav-link,
.navbar-expand .navbar-nav main .faq__wrapper .nav-anchor li a,
.navbar-expand .navbar-nav main .profile__services .status__filter li a,
main .faq__wrapper .nav-anchor li .navbar-expand .navbar-nav a,
main .faq__wrapper .navbar-expand .navbar-nav .nav-anchor li a,
main .profile__services .navbar-expand .navbar-nav .status__filter li a,
main .profile__services .status__filter li .navbar-expand .navbar-nav a {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

.navbar-expand .navbar-toggler,
.navbar-expand .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas {
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  bottom: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  position: inherit;
  -webkit-transform: none;
          transform: none;
  -webkit-transition: none;
  transition: none;
  visibility: visible !important;
  z-index: 1000;
}

.navbar-expand .offcanvas-bottom,
.navbar-expand .offcanvas-top {
  border-bottom: 0;
  border-top: 0;
  height: auto;
}

.navbar-expand .offcanvas-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  overflow-y: visible;
  padding: 0;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover,
.navbar .navbar-brand,
.navbar .navbar-brand:focus,
.navbar .navbar-brand:hover {
  color: rgba(34, 34, 34, 0.9);
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-light .navbar-nav a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar .navbar-nav a,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-light .navbar-nav .content__tab li a,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar .navbar-nav .content__tab li a,
.navbar-light .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav main .faq__wrapper .nav-anchor li a,
.navbar-light .navbar-nav main .profile__services .status__filter li a,
.navbar .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.navbar .navbar-nav .nav-link,
.navbar .navbar-nav main .faq__wrapper .nav-anchor li a,
.navbar .navbar-nav main .profile__services .status__filter li a,
main .faq__wrapper .nav-anchor li .navbar-light .navbar-nav a,
main .faq__wrapper .nav-anchor li .navbar .navbar-nav a,
main .faq__wrapper .navbar-light .navbar-nav .nav-anchor li a,
main .faq__wrapper .navbar .navbar-nav .nav-anchor li a,
main .profile__services .navbar-light .navbar-nav .status__filter li a,
main .profile__services .navbar .navbar-nav .status__filter li a,
main .profile__services .status__filter li .navbar-light .navbar-nav a,
main .profile__services .status__filter li .navbar .navbar-nav a {
  color: #222;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-light .navbar-nav a:focus,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-light .navbar-nav a:hover,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar .navbar-nav a:focus,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar .navbar-nav a:hover,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-light .navbar-nav .content__tab li a:focus,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-light .navbar-nav .content__tab li a:hover,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar .navbar-nav .content__tab li a:focus,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar .navbar-nav .content__tab li a:hover,
.navbar-light .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:focus,
.navbar-light .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav main .faq__wrapper .nav-anchor li a:focus,
.navbar-light .navbar-nav main .faq__wrapper .nav-anchor li a:hover,
.navbar-light .navbar-nav main .profile__services .status__filter li a:focus,
.navbar-light .navbar-nav main .profile__services .status__filter li a:hover,
.navbar .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:focus,
.navbar .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:hover,
.navbar .navbar-nav .nav-link:focus,
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav main .faq__wrapper .nav-anchor li a:focus,
.navbar .navbar-nav main .faq__wrapper .nav-anchor li a:hover,
.navbar .navbar-nav main .profile__services .status__filter li a:focus,
.navbar .navbar-nav main .profile__services .status__filter li a:hover,
main .faq__wrapper .nav-anchor li .navbar-light .navbar-nav a:focus,
main .faq__wrapper .nav-anchor li .navbar-light .navbar-nav a:hover,
main .faq__wrapper .nav-anchor li .navbar .navbar-nav a:focus,
main .faq__wrapper .nav-anchor li .navbar .navbar-nav a:hover,
main .faq__wrapper .navbar-light .navbar-nav .nav-anchor li a:focus,
main .faq__wrapper .navbar-light .navbar-nav .nav-anchor li a:hover,
main .faq__wrapper .navbar .navbar-nav .nav-anchor li a:focus,
main .faq__wrapper .navbar .navbar-nav .nav-anchor li a:hover,
main .profile__services .navbar-light .navbar-nav .status__filter li a:focus,
main .profile__services .navbar-light .navbar-nav .status__filter li a:hover,
main .profile__services .navbar .navbar-nav .status__filter li a:focus,
main .profile__services .navbar .navbar-nav .status__filter li a:hover,
main .profile__services .status__filter li .navbar-light .navbar-nav a:focus,
main .profile__services .status__filter li .navbar-light .navbar-nav a:hover,
main .profile__services .status__filter li .navbar .navbar-nav a:focus,
main .profile__services .status__filter li .navbar .navbar-nav a:hover {
  color: rgba(34, 34, 34, 0.7);
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-light .navbar-nav a.disabled,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar .navbar-nav a.disabled,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-light .navbar-nav .content__tab li a.disabled,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar .navbar-nav .content__tab li a.disabled,
.navbar-light .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.disabled,
.navbar-light .navbar-nav .nav-link.disabled,
.navbar-light .navbar-nav main .faq__wrapper .nav-anchor li a.disabled,
.navbar-light .navbar-nav main .profile__services .status__filter li a.disabled,
.navbar .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.disabled,
.navbar .navbar-nav .nav-link.disabled,
.navbar .navbar-nav main .faq__wrapper .nav-anchor li a.disabled,
.navbar .navbar-nav main .profile__services .status__filter li a.disabled,
main .faq__wrapper .nav-anchor li .navbar-light .navbar-nav a.disabled,
main .faq__wrapper .nav-anchor li .navbar .navbar-nav a.disabled,
main .faq__wrapper .navbar-light .navbar-nav .nav-anchor li a.disabled,
main .faq__wrapper .navbar .navbar-nav .nav-anchor li a.disabled,
main .profile__services .navbar-light .navbar-nav .status__filter li a.disabled,
main .profile__services .navbar .navbar-nav .status__filter li a.disabled,
main .profile__services .status__filter li .navbar-light .navbar-nav a.disabled,
main .profile__services .status__filter li .navbar .navbar-nav a.disabled {
  color: rgba(34, 34, 34, 0.3);
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-light .navbar-nav .show > a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-light .navbar-nav a.active,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar .navbar-nav .show > a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar .navbar-nav a.active,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-light .navbar-nav .content__tab li .show > a,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-light .navbar-nav .content__tab li a.active,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar .navbar-nav .content__tab li .show > a,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar .navbar-nav .content__tab li a.active,
.navbar-light .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .show > a,
.navbar-light .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.active,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav main .faq__wrapper .nav-anchor li .show > a,
.navbar-light .navbar-nav main .faq__wrapper .nav-anchor li a.active,
.navbar-light .navbar-nav main .profile__services .status__filter li .show > a,
.navbar-light .navbar-nav main .profile__services .status__filter li a.active,
.navbar .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .show > a,
.navbar .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.active,
.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .show > .nav-link,
.navbar .navbar-nav main .faq__wrapper .nav-anchor li .show > a,
.navbar .navbar-nav main .faq__wrapper .nav-anchor li a.active,
.navbar .navbar-nav main .profile__services .status__filter li .show > a,
.navbar .navbar-nav main .profile__services .status__filter li a.active,
main .faq__wrapper .nav-anchor li .navbar-light .navbar-nav .show > a,
main .faq__wrapper .nav-anchor li .navbar-light .navbar-nav a.active,
main .faq__wrapper .nav-anchor li .navbar .navbar-nav .show > a,
main .faq__wrapper .nav-anchor li .navbar .navbar-nav a.active,
main .faq__wrapper .navbar-light .navbar-nav .nav-anchor li .show > a,
main .faq__wrapper .navbar-light .navbar-nav .nav-anchor li a.active,
main .faq__wrapper .navbar .navbar-nav .nav-anchor li .show > a,
main .faq__wrapper .navbar .navbar-nav .nav-anchor li a.active,
main .profile__services .navbar-light .navbar-nav .status__filter li .show > a,
main .profile__services .navbar-light .navbar-nav .status__filter li a.active,
main .profile__services .navbar .navbar-nav .status__filter li .show > a,
main .profile__services .navbar .navbar-nav .status__filter li a.active,
main .profile__services .status__filter li .navbar-light .navbar-nav .show > a,
main .profile__services .status__filter li .navbar-light .navbar-nav a.active,
main .profile__services .status__filter li .navbar .navbar-nav .show > a,
main .profile__services .status__filter li .navbar .navbar-nav a.active {
  color: rgba(34, 34, 34, 0.9);
}

.navbar-light .navbar-toggler,
.navbar .navbar-toggler {
  border-color: #222;
  color: #222;
}

.navbar-light .navbar-toggler-icon,
.navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23222' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text,
.navbar .navbar-text {
  color: #222;
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover,
.navbar .navbar-text a {
  color: rgba(34, 34, 34, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-dark .navbar-nav a,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-dark .navbar-nav .content__tab li a,
.navbar-dark .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav main .faq__wrapper .nav-anchor li a,
.navbar-dark .navbar-nav main .profile__services .status__filter li a,
main .faq__wrapper .nav-anchor li .navbar-dark .navbar-nav a,
main .faq__wrapper .navbar-dark .navbar-nav .nav-anchor li a,
main .profile__services .navbar-dark .navbar-nav .status__filter li a,
main .profile__services .status__filter li .navbar-dark .navbar-nav a {
  color: rgba(255, 255, 255, 0.55);
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-dark .navbar-nav a:focus,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-dark .navbar-nav a:hover,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-dark .navbar-nav .content__tab li a:focus,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-dark .navbar-nav .content__tab li a:hover,
.navbar-dark .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:focus,
.navbar-dark .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:hover,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav main .faq__wrapper .nav-anchor li a:focus,
.navbar-dark .navbar-nav main .faq__wrapper .nav-anchor li a:hover,
.navbar-dark .navbar-nav main .profile__services .status__filter li a:focus,
.navbar-dark .navbar-nav main .profile__services .status__filter li a:hover,
main .faq__wrapper .nav-anchor li .navbar-dark .navbar-nav a:focus,
main .faq__wrapper .nav-anchor li .navbar-dark .navbar-nav a:hover,
main .faq__wrapper .navbar-dark .navbar-nav .nav-anchor li a:focus,
main .faq__wrapper .navbar-dark .navbar-nav .nav-anchor li a:hover,
main .profile__services .navbar-dark .navbar-nav .status__filter li a:focus,
main .profile__services .navbar-dark .navbar-nav .status__filter li a:hover,
main .profile__services .status__filter li .navbar-dark .navbar-nav a:focus,
main .profile__services .status__filter li .navbar-dark .navbar-nav a:hover {
  color: rgba(255, 255, 255, 0.75);
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-dark .navbar-nav a.disabled,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-dark .navbar-nav .content__tab li a.disabled,
.navbar-dark .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.disabled,
.navbar-dark .navbar-nav .nav-link.disabled,
.navbar-dark .navbar-nav main .faq__wrapper .nav-anchor li a.disabled,
.navbar-dark .navbar-nav main .profile__services .status__filter li a.disabled,
main .faq__wrapper .nav-anchor li .navbar-dark .navbar-nav a.disabled,
main .faq__wrapper .navbar-dark .navbar-nav .nav-anchor li a.disabled,
main .profile__services .navbar-dark .navbar-nav .status__filter li a.disabled,
main .profile__services .status__filter li .navbar-dark .navbar-nav a.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-dark .navbar-nav .show > a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-dark .navbar-nav a.active,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-dark .navbar-nav .content__tab li .show > a,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-dark .navbar-nav .content__tab li a.active,
.navbar-dark .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .show > a,
.navbar-dark .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.active,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav main .faq__wrapper .nav-anchor li .show > a,
.navbar-dark .navbar-nav main .faq__wrapper .nav-anchor li a.active,
.navbar-dark .navbar-nav main .profile__services .status__filter li .show > a,
.navbar-dark .navbar-nav main .profile__services .status__filter li a.active,
main .faq__wrapper .nav-anchor li .navbar-dark .navbar-nav .show > a,
main .faq__wrapper .nav-anchor li .navbar-dark .navbar-nav a.active,
main .faq__wrapper .navbar-dark .navbar-nav .nav-anchor li .show > a,
main .faq__wrapper .navbar-dark .navbar-nav .nav-anchor li a.active,
main .profile__services .navbar-dark .navbar-nav .status__filter li .show > a,
main .profile__services .navbar-dark .navbar-nav .status__filter li a.active,
main .profile__services .status__filter li .navbar-dark .navbar-nav .show > a,
main .profile__services .status__filter li .navbar-dark .navbar-nav a.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card,
.requests__wrapper .requests .request__content .message-card,
.service-card {
  word-wrap: break-word;
  background-clip: border-box;
  background-color: #fff;
  border: 0 solid rgba(34, 34, 34, 0.125);
  border-radius: .25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  position: relative;
}

.card > hr,
.requests__wrapper .requests .request__content .message-card > hr,
.service-card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group,
.requests__wrapper .requests .request__content .message-card > .list-group,
.service-card > .list-group {
  border-bottom: inherit;
  border-top: inherit;
}

.card > .list-group:first-child,
.requests__wrapper .requests .request__content .message-card > .list-group:first-child,
.service-card > .list-group:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  border-top-width: 0;
}

.card > .list-group:last-child,
.requests__wrapper .requests .request__content .message-card > .list-group:last-child,
.service-card > .list-group:last-child {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-bottom-width: 0;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer,
.requests__wrapper .requests .request__content .message-card > .card-header + .list-group,
.requests__wrapper .requests .request__content .message-card > .list-group + .card-footer,
.service-card > .card-header + .list-group,
.service-card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem;
}

.card-title {
  margin-bottom: .5rem;
}

.card-subtitle {
  margin-top: -.25rem;
}

.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  background-color: rgba(34, 34, 34, 0.03);
  border-bottom: 0 solid rgba(34, 34, 34, 0.125);
  margin-bottom: 0;
  padding: .5rem 1rem;
}

.card-header:first-child {
  border-radius: .25rem .25rem 0 0;
}

.card-footer {
  background-color: rgba(34, 34, 34, 0.03);
  border-top: 0 solid rgba(34, 34, 34, 0.125);
  padding: .5rem 1rem;
}

.card-footer:last-child {
  border-radius: 0 0 .25rem .25rem;
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.5rem;
}

.card-header-pills,
.card-header-tabs {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.card-img-overlay {
  border-radius: .25rem;
  bottom: 0;
  left: 0;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
}

.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-img,
.card-img-bottom {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.card-group > .card,
.card-group > .service-card,
.requests__wrapper .requests .request__content .card-group > .message-card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .card-group > .card,
  .card-group > .service-card,
  .requests__wrapper .requests .request__content .card-group > .message-card {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card,
  .card-group > .card + .service-card,
  .card-group > .service-card + .card,
  .card-group > .service-card + .service-card,
  .requests__wrapper .requests .request__content .card-group > .card + .message-card,
  .requests__wrapper .requests .request__content .card-group > .message-card + .card,
  .requests__wrapper .requests .request__content .card-group > .message-card + .message-card,
  .requests__wrapper .requests .request__content .card-group > .message-card + .service-card,
  .requests__wrapper .requests .request__content .card-group > .service-card + .message-card {
    border-left: 0;
    margin-left: 0;
  }
  .card-group > .card:not(:last-child),
  .card-group > .service-card:not(:last-child),
  .requests__wrapper .requests .request__content .card-group > .message-card:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .service-card:not(:last-child) .card-header,
  .card-group > .service-card:not(:last-child) .card-img-top,
  .requests__wrapper .requests .request__content .card-group > .message-card:not(:last-child) .card-header,
  .requests__wrapper .requests .request__content .card-group > .message-card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .service-card:not(:last-child) .card-footer,
  .card-group > .service-card:not(:last-child) .card-img-bottom,
  .requests__wrapper .requests .request__content .card-group > .message-card:not(:last-child) .card-footer,
  .requests__wrapper .requests .request__content .card-group > .message-card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child),
  .card-group > .service-card:not(:first-child),
  .requests__wrapper .requests .request__content .card-group > .message-card:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .service-card:not(:first-child) .card-header,
  .card-group > .service-card:not(:first-child) .card-img-top,
  .requests__wrapper .requests .request__content .card-group > .message-card:not(:first-child) .card-header,
  .requests__wrapper .requests .request__content .card-group > .message-card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .service-card:not(:first-child) .card-footer,
  .card-group > .service-card:not(:first-child) .card-img-bottom,
  .requests__wrapper .requests .request__content .card-group > .message-card:not(:first-child) .card-footer,
  .requests__wrapper .requests .request__content .card-group > .message-card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  color: #212529;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1rem;
  overflow-anchor: none;
  padding: 1rem 1.25rem;
  position: relative;
  text-align: left;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, border-radius .15s ease, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, border-radius .15s ease, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease, -webkit-box-shadow .15s ease-in-out;
  width: 100%;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    -webkit-transition: none;
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  background-color: #e6f5ec;
  -webkit-box-shadow: inset 0 -1px 0 rgba(34, 34, 34, 0.125);
          box-shadow: inset 0 -1px 0 rgba(34, 34, 34, 0.125);
  color: #00873a;
}

.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300873a'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.accordion-button:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  content: "";
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: 1.25rem;
  margin-left: auto;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  width: 1.25rem;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    -webkit-transition: none;
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  border-color: #80cba0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
  outline: 0;
  z-index: 3;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(34, 34, 34, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding-left: 0;
}

.page-link {
  background-color: #fff;
  border: solid #dee2e6;
  color: #009640;
  display: block;
  position: relative;
  text-decoration: none;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    -webkit-transition: none;
    transition: none;
  }
}

.page-link:hover {
  border-color: #dee2e6;
  z-index: 2;
}

.page-link:focus,
.page-link:hover {
  background-color: #e9ecef;
  color: #007833;
}

.page-link:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
  outline: 0;
  z-index: 3;
}

.page-item:not(:first-child) .page-link {
  margin-left: -none;
}

.page-item.active .page-link {
  background-color: #009640;
  border-color: #009640;
  color: #fff;
  z-index: 3;
}

.page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6;
  color: #6c757d;
  pointer-events: none;
}

.page-link {
  padding: .375rem .75rem;
}

.page-item:first-child .page-link {
  border-bottom-left-radius: .25rem;
  border-top-left-radius: .25rem;
}

.page-item:last-child .page-link {
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem;
}

.pagination-lg .page-link {
  font-size: 1.25rem;
  padding: .75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-bottom-left-radius: .3rem;
  border-top-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-bottom-right-radius: .3rem;
  border-top-right-radius: .3rem;
}

.pagination-sm .page-link {
  font-size: .875rem;
  padding: .25rem .5rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-bottom-left-radius: .2rem;
  border-top-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-bottom-right-radius: .2rem;
  border-top-right-radius: .2rem;
}

.badge,
.service-card .card-footer .category,
.service-card .card-footer .edit .status {
  border-radius: .25rem;
  color: #222;
  display: inline-block;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  padding: .45em .75em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.badge:empty,
.service-card .card-footer .category:empty,
.service-card .card-footer .edit .status:empty {
  display: none;
}

.btn .badge,
.btn .service-card .card-footer .category,
.btn .service-card .card-footer .edit .status,
.service-card .card-footer .btn .category,
.service-card .card-footer .edit .btn .status,
.service-card .card-footer .edit .serviceOffCanvas .offcanvas-body .bozza .status,
.service-card .card-footer .edit .serviceOffCanvas .offcanvas-body .cancella .status,
.service-card .card-footer .edit .serviceOffCanvas .offcanvas-body .pubblica .status,
.service-card .card-footer .edit .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi .status,
.service-card .card-footer .edit .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login .status,
.service-card .card-footer .edit body .buorg #buorgul .status,
.service-card .card-footer .edit main .acceptance__wrapper button .status,
.service-card .card-footer .edit main .agencies__wrapper .agencies__more a .status,
.service-card .card-footer .edit main .profile__wrapper .profile__addService button .status,
.service-card .card-footer .edit main .questions__wrapper .questions__ctaButton .status,
.service-card .card-footer .edit main .registration__wrapper .registration__register a .status,
.service-card .card-footer .serviceOffCanvas .offcanvas-body .bozza .category,
.service-card .card-footer .serviceOffCanvas .offcanvas-body .cancella .category,
.service-card .card-footer .serviceOffCanvas .offcanvas-body .pubblica .category,
.service-card .card-footer .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi .category,
.service-card .card-footer .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login .category,
.service-card .card-footer body .buorg #buorgul .category,
.service-card .card-footer main .acceptance__wrapper button .category,
.service-card .card-footer main .agencies__wrapper .agencies__more a .category,
.service-card .card-footer main .profile__wrapper .profile__addService button .category,
.service-card .card-footer main .questions__wrapper .questions__ctaButton .category,
.service-card .card-footer main .registration__wrapper .registration__register a .category,
.serviceOffCanvas .offcanvas-body .bozza .badge,
.serviceOffCanvas .offcanvas-body .bozza .service-card .card-footer .category,
.serviceOffCanvas .offcanvas-body .bozza .service-card .card-footer .edit .status,
.serviceOffCanvas .offcanvas-body .cancella .badge,
.serviceOffCanvas .offcanvas-body .cancella .service-card .card-footer .category,
.serviceOffCanvas .offcanvas-body .cancella .service-card .card-footer .edit .status,
.serviceOffCanvas .offcanvas-body .pubblica .badge,
.serviceOffCanvas .offcanvas-body .pubblica .service-card .card-footer .category,
.serviceOffCanvas .offcanvas-body .pubblica .service-card .card-footer .edit .status,
.serviceOffCanvas .offcanvas-body .service-card .card-footer .bozza .category,
.serviceOffCanvas .offcanvas-body .service-card .card-footer .cancella .category,
.serviceOffCanvas .offcanvas-body .service-card .card-footer .edit .bozza .status,
.serviceOffCanvas .offcanvas-body .service-card .card-footer .edit .cancella .status,
.serviceOffCanvas .offcanvas-body .service-card .card-footer .edit .pubblica .status,
.serviceOffCanvas .offcanvas-body .service-card .card-footer .pubblica .category,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi .badge,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi .service-card .card-footer .category,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi .service-card .card-footer .edit .status,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login .badge,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login .service-card .card-footer .category,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login .service-card .card-footer .edit .status,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .service-card .card-footer .ctaButtons__comeRegistrarsi .category,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .service-card .card-footer .ctaButtons__login .category,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .service-card .card-footer .edit .ctaButtons__comeRegistrarsi .status,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .service-card .card-footer .edit .ctaButtons__login .status,
body .buorg #buorgul .badge,
body .buorg #buorgul .service-card .card-footer .category,
body .buorg #buorgul .service-card .card-footer .edit .status,
main .acceptance__wrapper button .badge,
main .acceptance__wrapper button .service-card .card-footer .category,
main .acceptance__wrapper button .service-card .card-footer .edit .status,
main .agencies__wrapper .agencies__more a .badge,
main .agencies__wrapper .agencies__more a .service-card .card-footer .category,
main .agencies__wrapper .agencies__more a .service-card .card-footer .edit .status,
main .profile__wrapper .profile__addService button .badge,
main .profile__wrapper .profile__addService button .service-card .card-footer .category,
main .profile__wrapper .profile__addService button .service-card .card-footer .edit .status,
main .questions__wrapper .questions__ctaButton .badge,
main .questions__wrapper .questions__ctaButton .service-card .card-footer .category,
main .questions__wrapper .questions__ctaButton .service-card .card-footer .edit .status,
main .registration__wrapper .registration__register a .badge,
main .registration__wrapper .registration__register a .service-card .card-footer .category,
main .registration__wrapper .registration__register a .service-card .card-footer .edit .status {
  position: relative;
  top: -1px;
}

.btn-close {
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.25' height='29.25'%3E%3Cpath fill='%2300973b' d='M14.625 2.953A11.667 11.667 0 1 1 6.37 6.37a11.623 11.623 0 0 1 8.255-3.417m0-2.953A14.625 14.625 0 1 0 29.25 14.625 14.623 14.623 0 0 0 14.625 0z'/%3E%3Cpath fill='%2300973b' d='m21.938 19.891-2.046 2.046-5.267-5.266-5.266 5.266-2.046-2.046 5.266-5.266-5.266-5.266 2.046-2.046 5.266 5.266 5.266-5.266 2.046 2.046-5.266 5.266z'/%3E%3C/svg%3E") 50%/30px auto no-repeat;
  border: 0;
  border-radius: .25rem;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  color: #009640;
  height: 30px;
  opacity: .5;
  padding: .25em;
  width: 30px;
}

.btn-close:hover {
  color: #009640;
  opacity: .75;
  text-decoration: none;
}

.btn-close:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
  opacity: 1;
  outline: 0;
}

.btn-close.disabled,
.btn-close:disabled {
  opacity: .25;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
          filter: invert(1) grayscale(100%) brightness(200%);
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1055;
}

.modal-dialog {
  margin: .5rem;
  pointer-events: none;
  position: relative;
  width: auto;
}

.modal.fade .modal-dialog {
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered,
.modal .modal-dialog {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: calc(100% - 1rem);
}

.modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 0;
  border-radius: .3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modal-backdrop {
  background-color: #222;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1050;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem;
}

.modal-header .btn-close {
  margin: -.5rem -.5rem -.5rem auto;
  padding: .5rem;
}

.modal-title {
  line-height: 1.5;
  margin-bottom: 0;
}

.modal-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
  border-top: 0 solid #dee2e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: .75rem;
}

.modal-footer > * {
  margin: .25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
    max-width: 500px;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered,
  .modal .modal-dialog {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  height: 100%;
  margin: 0;
  max-width: none;
  width: 100vw;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

.spinner-border,
main .marketplace_wrapper .marketplace .content__filters > ul > li.filters__spinner > div > div {
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
  border: .25em solid;
  border-radius: 50%;
  border-right: .25em solid transparent;
  display: inline-block;
  height: 2rem;
  vertical-align: -.125em;
  width: 2rem;
}

.spinner-border-sm {
  border-width: .2em;
  height: 1rem;
  width: 1rem;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.spinner-grow {
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  opacity: 0;
  vertical-align: -.125em;
  width: 2rem;
}

.spinner-grow-sm {
  height: 1rem;
  width: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow,
  main .marketplace_wrapper .marketplace .content__filters > ul > li.filters__spinner > div > div {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.offcanvas {
  background-clip: padding-box;
  background-color: #fff;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 100%;
  outline: 0;
  position: fixed;
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  visibility: hidden;
  z-index: 1045;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    -webkit-transition: none;
    transition: none;
  }
}

.offcanvas-backdrop {
  background-color: #222;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem;
}

.offcanvas-header .btn-close {
  margin-bottom: -.5rem;
  margin-right: -.5rem;
  margin-top: -.5rem;
  padding: .5rem;
}

.offcanvas-title {
  line-height: 1.5;
  margin-bottom: 0;
}

.offcanvas-body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
}

.offcanvas-start {
  border-right: 0;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  width: 900px;
}

.offcanvas-end,
.serviceOffCanvas {
  border-left: 0;
  right: 0;
  top: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  width: 900px;
}

.offcanvas-top {
  border-bottom: 0;
  top: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.offcanvas-bottom,
.offcanvas-top {
  height: 100vh;
  left: 0;
  max-height: 100%;
  right: 0;
}

.offcanvas-bottom {
  border-top: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.offcanvas.show {
  -webkit-transform: none;
          transform: none;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.link-primary {
  color: #009640;
}

.link-primary:focus,
.link-primary:hover {
  color: #33ab66;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:focus,
.link-secondary:hover {
  color: #565e64;
}

.link-success {
  color: #009640;
}

.link-success:focus,
.link-success:hover {
  color: #33ab66;
}

.link-info {
  color: #0dcaf0;
}

.link-info:focus,
.link-info:hover {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}

.link-warning:focus,
.link-warning:hover {
  color: #ffcd39;
}

.link-danger {
  color: #e30513;
}

.link-danger:focus,
.link-danger:hover {
  color: #b6040f;
}

.link-light {
  color: #f8f9fa;
}

.link-light:focus,
.link-light:hover {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}

.link-dark:focus,
.link-dark:hover {
  color: #1a1e21;
}

.ratio,
main .info__wrapper .info .content__iframe div {
  position: relative;
  width: 100%;
}

.ratio:before,
main .info__wrapper .info .content__iframe div:before {
  content: "";
  display: block;
  padding-top: var(--bs-aspect-ratio);
}

.ratio > *,
main .info__wrapper .info .content__iframe div > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%
;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%
;
}

.ratio-16x9,
main .info__wrapper .info .content__iframe div {
  --bs-aspect-ratio: 56.25%
;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%
;
}

.fixed-top {
  top: 0;
}

.fixed-bottom,
.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

.sticky-top,
.stickyBar__wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.hstack,
.modal .modal-dialog .modal-content .modal-body .body__content .content__service,
.modal .modal-dialog .modal-content .modal-body .body__content .info,
.modal .modal-dialog .modal-content .modal-body .body__header,
.modal .modal-dialog .modal-content .modal-body .body__header .header__info,
.modal .modal-dialog .modal-content .modal-body .body__header span,
.navbar-brand,
.requests__wrapper .requests .request__content .message-card .card-header,
.selected:not(.multiselect--active) .multiselect__single,
.service-card .card-footer .edit,
.stickyBar__wrapper .stickyBar__container .stickyBar__logo,
main .acceptance__wrapper div div,
main .marketplace_wrapper .marketplace .content__tabs > ul li div,
main .questions__wrapper .questions {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.vstack {
  -ms-flex-item-align: stretch;
      align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.stretched-link:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
  background-color: currentColor;
  display: inline-block;
  min-height: 1em;
  opacity: .25;
  width: 1px;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block,
.ecosystem ul li a img,
.hero__content .content__img img,
.selected:not(.multiselect--active) .multiselect__single .num,
.services-cards .content__cards .cards .service a,
main .agencies__wrapper .agencies__more span {
  display: block !important;
}

.d-grid,
.serviceOffCanvas .offcanvas-body .submit,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons {
  display: -ms-grid !important;
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex,
main .agencies__wrapper .agencies__agency .agency__image,
main .marketplace_wrapper .marketplace .content__tabs > ul li {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-none,
.stickyBar__wrapper .stickyBar__container .stickyBar__logo {
  display: none !important;
}

.fieldset .form__group .picture-input > div .preview-container,
.fieldset .form__group .tox-tinymce,
.fieldset .form__group input:not(.form-check-input),
.fieldset .form__group select,
.fieldset .form__group textarea,
.form-control,
.form-select,
.input-group,
.modal .modal-dialog .modal-content .modal-body .body__content .content,
.multiselect__content-wrapper,
.multiselect__tags,
.pagination_nav .pagination,
.requests__wrapper .requests .request__content .message-card,
.service-card,
.shadow,
.stickyBar__wrapper {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(34, 34, 34, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(34, 34, 34, 0.15) !important;
}

.navbar,
.service-card:hover,
.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(34, 34, 34, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(34, 34, 34, 0.075) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(34, 34, 34, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(34, 34, 34, 0.175) !important;
}

.fieldset .form__group .form-control input:not(.form-check-input),
.fieldset .form__group .form-control textarea,
.fieldset .form__group .form-select input:not(.form-check-input),
.fieldset .form__group .form-select textarea,
.fieldset .form__group .input-group input:not(.form-check-input),
.fieldset .form__group .input-group textarea,
.fieldset .form__group input:not(.form-check-input) .form-control,
.fieldset .form__group input:not(.form-check-input) input:not(.form-check-input),
.fieldset .form__group input:not(.form-check-input) textarea,
.fieldset .form__group select .form-control,
.fieldset .form__group select input:not(.form-check-input),
.fieldset .form__group select textarea,
.fieldset .form__group textarea .form-control,
.fieldset .form__group textarea input:not(.form-check-input),
.fieldset .form__group textarea textarea,
.form-control .fieldset .form__group input:not(.form-check-input),
.form-control .fieldset .form__group textarea,
.form-control .form-control,
.form-select .fieldset .form__group input:not(.form-check-input),
.form-select .fieldset .form__group textarea,
.form-select .form-control,
.input-group .fieldset .form__group input:not(.form-check-input),
.input-group .fieldset .form__group textarea,
.input-group .form-control,
.noNavMargin .navbar,
.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
}

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top,
.service-card .card-footer .edit {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end,
.service-card .card-footer .edit .button_wrapper i:not(:last-child) {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom,
.modal .modal-dialog .modal-content .modal-body .body__content .content__service,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
main .faq__wrapper .nav-anchor li a,
main .profile__services .status__filter li a {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start,
.callout,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li:not(:first-child),
main .faq__wrapper .nav-anchor li:not(:first-child),
main .profile__services .status__filter li:not(:first-child) {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li.active a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li:hover a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.active,
main .faq__wrapper .nav-anchor li.active a,
main .faq__wrapper .nav-anchor li:hover a,
main .faq__wrapper .nav-anchor li a.active,
main .profile__services .status__filter li.active a,
main .profile__services .status__filter li:hover a,
main .profile__services .status__filter li a.active {
  border-color: #009640 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #009640 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #e30513 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark,
.callout {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li:not(:first-child),
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
main .faq__wrapper .nav-anchor li:not(:first-child),
main .faq__wrapper .nav-anchor li a,
main .profile__services .status__filter li:not(:first-child),
main .profile__services .status__filter li a {
  border-width: 3px !important;
}

.border-4,
.callout {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100,
.service-card,
.services-cards .content__cards .cards .service a {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}

.flex-wrap,
main .agencies__wrapper .agencies__agency .agency__image {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons {
  gap: .5rem !important;
}

.gap-3,
.navbar-brand {
  gap: 1rem !important;
}

.gap-4,
.serviceOffCanvas .offcanvas-body .submit {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

.justify-content-center,
.pagination_nav .pagination {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.justify-content-evenly {
  -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
          justify-content: space-evenly !important;
}

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important;
}

.align-items-center,
.ecosystem ul,
.hero__content,
.stickyBar__wrapper .stickyBar__container .stickyBar,
main .agencies__wrapper .agencies__agency .agency__image,
main .profile__wrapper .profile {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
}

.order-first {
  -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
          order: -1 !important;
}

.order-0,
footer > ul > li.menu {
  -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
          order: 0 !important;
}

.order-1,
footer > ul > li.info {
  -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
          order: 1 !important;
}

.order-2,
footer > ul > li.social {
  -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
          order: 2 !important;
}

.order-3 {
  -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
          order: 3 !important;
}

.order-4 {
  -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
          order: 4 !important;
}

.order-5 {
  -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
          order: 5 !important;
}

.order-last {
  -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
          order: 6 !important;
}

.ecosystem ul,
.m-0,
footer > ul,
footer > ul > li.menu ul {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

#tinymce,
.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0,
main .marketplace_wrapper .marketplace .content__filters,
main .marketplace_wrapper .marketplace .content__tabs {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li,
.mx-2,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li,
.navbar-nav .nav-item,
.navbar-nav main .faq__wrapper .nav-anchor li,
.navbar-nav main .profile__services .status__filter li,
main .faq__wrapper .nav-anchor .navbar-nav li,
main .faq__wrapper .navbar-nav .nav-anchor li,
main .profile__services .navbar-nav .status__filter li,
main .profile__services .status__filter .navbar-nav li {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3,
.pagination_nav .pagination li:not(.prev):not(.next) {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.ecosystem ul li a img,
.mx-auto,
main .agencies__wrapper .agencies__agency .agency__image img,
main .agencies__wrapper .agencies__more a {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.my-1,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login,
main .agencies__wrapper .agencies__more a,
main .registration__wrapper .registration__register a {
  margin-bottom: .25rem !important;
  margin-top: .25rem !important;
}

.my-2,
main .acceptance__wrapper .h1,
main .acceptance__wrapper button,
main .acceptance__wrapper h1,
main .questions__wrapper .questions__ctaButton {
  margin-bottom: .5rem !important;
  margin-top: .5rem !important;
}

.my-3,
.service-card .image-wrapper,
.serviceOffCanvas .offcanvas-body .submit,
footer > ul > li .h2,
footer > ul > li.menu ul li,
footer > ul > li h2,
main .info__wrapper .info .content__list,
main .marketplace_wrapper {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.ecosystem ul li,
.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

.my-5,
main .acceptance__wrapper,
main .agencies__wrapper,
main .faq__wrapper,
main .info__wrapper .info .content__iframe,
main .marketplace_wrapper .marketplace .content__filters,
main .marketplace_wrapper .marketplace .content__tabs,
main .profile__data .profile__title,
main .profile__requests .profile__title,
main .profile__services .profile__title,
main .profile__wrapper,
main .questions__wrapper,
main .registration__wrapper,
main hr {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
}

.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.fieldset button[type=submit],
.modal .modal-dialog .modal-content .modal-body .body__content .content,
.mt-2,
.service-card .card-footer .edit {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2,
.modal .modal-dialog .modal-content .modal-body .body__content .content__service li,
.modal .modal-dialog .modal-content .modal-body .body__header .header__info li,
.stickyBar__wrapper .stickyBar__container .stickyBar__logo img,
main .marketplace_wrapper .marketplace .content__tabs > ul li div {
  margin-right: .5rem !important;
}

.callout,
.me-3,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li,
main .acceptance__wrapper div div input,
main .faq__wrapper .nav-anchor li,
main .profile__services .status__filter li {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto,
main .questions__wrapper .questions__title {
  margin-right: auto !important;
}

.mb-0,
.modal .modal-dialog .modal-content .modal-body .body__header .header__info,
.noNavMargin .navbar {
  margin-bottom: 0 !important;
}

.mb-1,
.service-card .card-footer .edit .button_wrapper,
.service-card .card-footer .edit span {
  margin-bottom: .25rem !important;
}

.fieldset .form__group,
.mb-2,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li,
.requests__wrapper .requests .request__content .message-card .card-body .body-email,
.serviceOffCanvas .offcanvas-body .fieldset .confirm-delete div,
main .agencies__wrapper .agencies__more span,
main .faq__wrapper .nav-anchor li,
main .marketplace_wrapper .marketplace .content__tabs > ul li div,
main .profile__services .status__filter li {
  margin-bottom: .5rem !important;
}

.mb-3,
.modal .modal-dialog .modal-content .modal-body .body__content .content__service li,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab,
.requests__wrapper .requests .request,
.requests__wrapper .requests .request__info,
main .faq__wrapper .nav-anchor,
main .info__wrapper .info .content__list .list__wrapper article .list__title,
main .profile__services .status__filter,
main .profile__wrapper .profile > div,
main .registration__wrapper .registration__register,
main .registration__wrapper .registration__title .h4,
main .registration__wrapper .registration__title h4 {
  margin-bottom: 1rem !important;
}

.callout,
.mb-4,
.navbar,
main .agencies__wrapper .agencies__agency,
main .info__wrapper .info .content__list .list__wrapper article,
main .marketplace_wrapper .marketplace .content__filters > ul > li,
main .marketplace_wrapper .marketplace .content__filters > ul > li .h3,
main .marketplace_wrapper .marketplace .content__filters > ul > li h3,
main .marketplace_wrapper .marketplace .content__tabs > ul li .h3,
main .marketplace_wrapper .marketplace .content__tabs > ul li h3,
main .registration__wrapper .registration__list .list__wrapper li {
  margin-bottom: 1.5rem !important;
}

.mb-5,
main .agencies__wrapper .agencies__title,
main .registration__wrapper .registration__title {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2,
.service-card .card-footer .edit .button_wrapper i {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.hero__content .content__img img,
.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales,
.ms-auto,
.requests__wrapper .requests .date,
.selected:not(.multiselect--active) .multiselect__single .num {
  margin-left: auto !important;
}

.ecosystem ul,
.modal .modal-dialog .modal-content .modal-body .body__content .content__service,
.modal .modal-dialog .modal-content .modal-body .body__header .header__info,
.p-0,
.requests__wrapper .requests,
.services-cards .content__cards .cards,
footer > ul,
footer > ul > li.menu ul,
main .marketplace_wrapper .marketplace .content__filters,
main .marketplace_wrapper .marketplace .content__filters > ul,
main .marketplace_wrapper .marketplace .content__tabs,
main .marketplace_wrapper .marketplace .content__tabs > ul,
main .profile__wrapper .profile__info ul {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2,
.services-cards .content__cards .cards .service {
  padding: .5rem !important;
}

.callout,
.p-3,
main .agencies__wrapper .agencies__agency .agency__image {
  padding: 1rem !important;
}

.modal .modal-dialog .modal-content .modal-body .body__form .form__wrapper,
.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content,
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.py-1 {
  padding-bottom: .25rem !important;
  padding-top: .25rem !important;
}

.fieldset legend,
.hero__content,
.py-2 {
  padding-bottom: .5rem !important;
  padding-top: .5rem !important;
}

.py-3,
.stickyBar__wrapper .stickyBar__container .stickyBar,
footer > ul > li {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content,
.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}

.ecosystem,
.pagination_nav,
.py-5,
main .info__wrapper {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2,
.service-card .card-footer .edit,
main .profile__wrapper .profile__info {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li,
.modal .modal-dialog .modal-content .modal-body .body__header .header__info li,
.pe-2,
.service-card .card-footer .edit .button_wrapper i:not(:last-child) {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3,
main .profile__data .profile__title,
main .profile__requests .profile__title,
main .profile__services .profile__title {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0,
main .registration__wrapper .registration__list .list__wrapper {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li:not(:first-child),
.ps-3,
main .faq__wrapper .nav-anchor li:not(:first-child),
main .info__wrapper .info .content__title .h2,
main .info__wrapper .info .content__title h2,
main .profile__services .status__filter li:not(:first-child) {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + .75vw) !important;
}

.fs-3 {
  font-size: 1.125rem !important;
}

.fs-4 {
  font-size: 1rem !important;
}

.fs-5 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end,
footer > ul > li.social {
  text-align: right !important;
}

.text-center,
main .agencies__wrapper .agencies__more {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase,
main .questions__wrapper .questions__title,
main .registration__wrapper .registration__title .h4,
main .registration__wrapper .registration__title h4 {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary,
main .marketplace_wrapper .marketplace .content__filters > ul > li.filters__spinner > div > div {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(34, 34, 34, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25
;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5
;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75
;
}

.text-opacity-100 {
  --bs-text-opacity: 1
;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light,
.callout {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white,
.navbar,
.service-card .card-footer,
.stickyBar__wrapper {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1
;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25
;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5
;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75
;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1
;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
      user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.callout,
.modal .modal-dialog .modal-content .modal-body .body__content .content,
.modal .modal-dialog .modal-content .modal-body .body__form .form__wrapper,
.rounded,
.service-card {
  border-radius: .25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: .2rem !important;
}

.rounded-2 {
  border-radius: .25rem !important;
}

.rounded-3 {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales .sales,
.rounded-pill,
.service-card .card-footer .category,
.service-card .card-footer .edit .status,
.service-card .sales {
  border-radius: 50rem !important;
}

.modal .modal-dialog .modal-content .modal-body .body__header,
.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-end {
  border-top-right-radius: .25rem !important;
}

.rounded-bottom,
.rounded-end {
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom,
.rounded-start {
  border-bottom-left-radius: .25rem !important;
}

.rounded-start {
  border-top-left-radius: .25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: .25rem !important;
  }
  .gap-sm-2 {
    gap: .5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: .25rem !important;
  }
  .m-sm-2 {
    margin: .5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }
  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-sm-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important;
  }
  .my-sm-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important;
  }
  .my-sm-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-sm-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-sm-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: .25rem !important;
  }
  .mt-sm-2 {
    margin-top: .5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: .25rem !important;
  }
  .me-sm-2 {
    margin-right: .5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: .25rem !important;
  }
  .ms-sm-2 {
    margin-left: .5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: .25rem !important;
  }
  .p-sm-2 {
    padding: .5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }
  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .py-sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-sm-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important;
  }
  .py-sm-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important;
  }
  .py-sm-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-sm-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: .25rem !important;
  }
  .pt-sm-2 {
    padding-top: .5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: .25rem !important;
  }
  .pe-sm-2 {
    padding-right: .5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: .25rem !important;
  }
  .ps-sm-2 {
    padding-left: .5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block,
  .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons {
    display: block !important;
  }
  .d-md-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex,
  .stickyBar__wrapper .stickyBar__container .stickyBar__logo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: .25rem !important;
  }
  .gap-md-2 {
    gap: .5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: .25rem !important;
  }
  .m-md-2 {
    margin: .5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }
  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-md-auto,
  .navbar-nav {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-md-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important;
  }
  .ecosystem ul li,
  .my-md-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important;
  }
  .my-md-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-md-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-md-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-md-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: .25rem !important;
  }
  .mt-md-2 {
    margin-top: .5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: .25rem !important;
  }
  .me-md-2 {
    margin-right: .5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0,
  .requests__wrapper .requests .request__info,
  main .marketplace_wrapper .marketplace .content__filters > ul > li {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: .25rem !important;
  }
  .mb-md-2 {
    margin-bottom: .5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5,
  main .info__wrapper .info .content__title .h2,
  main .info__wrapper .info .content__title h2 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: .25rem !important;
  }
  .ms-md-2 {
    margin-left: .5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: .25rem !important;
  }
  .p-md-2 {
    padding: .5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }
  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .py-md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-md-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content,
  .py-md-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important;
  }
  .py-md-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .hero__content,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-md-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: .25rem !important;
  }
  .pt-md-2 {
    padding-top: .5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: .25rem !important;
  }
  .pe-md-2 {
    padding-right: .5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: .25rem !important;
  }
  .pb-md-2 {
    padding-bottom: .5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0,
  .requests__wrapper .requests .request__content {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: .25rem !important;
  }
  .ps-md-2 {
    padding-left: .5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons,
  .text-md-end,
  main .profile__wrapper .profile__addService {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: .25rem !important;
  }
  .gap-lg-2 {
    gap: .5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .order-lg-0,
  footer > ul > li.info {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .order-lg-1,
  footer > ul > li.menu {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: .25rem !important;
  }
  .m-lg-2 {
    margin: .5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }
  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-lg-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important;
  }
  .my-lg-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important;
  }
  .my-lg-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-lg-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-lg-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content,
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: .25rem !important;
  }
  .mt-lg-2 {
    margin-top: .5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0,
  .navbar-nav {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: .25rem !important;
  }
  .me-lg-2 {
    margin-right: .5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: .25rem !important;
  }
  .ms-lg-2 {
    margin-left: .5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: .25rem !important;
  }
  .p-lg-2 {
    padding: .5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }
  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }
  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .py-lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-lg-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important;
  }
  .py-lg-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important;
  }
  .py-lg-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .hero__content,
  .py-lg-5,
  footer > ul > li {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: .25rem !important;
  }
  .pt-lg-2 {
    padding-top: .5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: .25rem !important;
  }
  .pe-lg-2 {
    padding-right: .5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: .25rem !important;
  }
  .ps-lg-2 {
    padding-left: .5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: .25rem !important;
  }
  .gap-xl-2 {
    gap: .5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: .25rem !important;
  }
  .m-xl-2 {
    margin: .5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }
  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xl-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important;
  }
  .my-xl-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important;
  }
  .my-xl-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xl-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-xl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: .25rem !important;
  }
  .mt-xl-2 {
    margin-top: .5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: .25rem !important;
  }
  .me-xl-2 {
    margin-right: .5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: .25rem !important;
  }
  .ms-xl-2 {
    margin-left: .5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: .25rem !important;
  }
  .p-xl-2 {
    padding: .5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }
  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }
  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .py-xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xl-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important;
  }
  .py-xl-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important;
  }
  .py-xl-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xl-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: .25rem !important;
  }
  .pt-xl-2 {
    padding-top: .5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: .25rem !important;
  }
  .pe-xl-2 {
    padding-right: .5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: .25rem !important;
  }
  .ps-xl-2 {
    padding-left: .5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: .25rem !important;
  }
  .gap-xxl-2 {
    gap: .5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
  .order-xxl-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .order-xxl-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: .25rem !important;
  }
  .m-xxl-2 {
    margin: .5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }
  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xxl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xxl-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important;
  }
  .my-xxl-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important;
  }
  .my-xxl-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-xxl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: .25rem !important;
  }
  .mt-xxl-2 {
    margin-top: .5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: .25rem !important;
  }
  .me-xxl-2 {
    margin-right: .5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: .25rem !important;
  }
  .ms-xxl-2 {
    margin-left: .5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: .25rem !important;
  }
  .p-xxl-2 {
    padding: .5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }
  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }
  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .py-xxl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xxl-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important;
  }
  .py-xxl-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important;
  }
  .py-xxl-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: .25rem !important;
  }
  .pt-xxl-2 {
    padding-top: .5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: .25rem !important;
  }
  .pe-xxl-2 {
    padding-right: .5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: .25rem !important;
  }
  .ps-xxl-2 {
    padding-left: .5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2rem !important;
  }
  .fs-2 {
    font-size: 1.875rem !important;
  }
  .fs-5 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

main .marketplace_wrapper .marketplace .content__filters > ul > li .h3,
main .marketplace_wrapper .marketplace .content__filters > ul > li h3,
main .marketplace_wrapper .marketplace .content__tabs > ul li .h3,
main .marketplace_wrapper .marketplace .content__tabs > ul li h3 {
  font-weight: 500;
}

main .profile__data .profile__title,
main .profile__requests .profile__title,
main .profile__services .profile__title {
  border-bottom: 5px solid #222;
  font-weight: 600;
  text-transform: uppercase;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li,
main .faq__wrapper .nav-anchor li,
main .profile__services .status__filter li {
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li.active a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li:hover a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.active,
main .faq__wrapper .nav-anchor li.active a,
main .faq__wrapper .nav-anchor li:hover a,
main .faq__wrapper .nav-anchor li a.active,
main .profile__services .status__filter li.active a,
main .profile__services .status__filter li:hover a,
main .profile__services .status__filter li a.active {
  color: #009640;
}

.btn-primary,
.serviceOffCanvas .offcanvas-body .pubblica,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login,
body .buorg #buorgul,
main .acceptance__wrapper button,
main .agencies__wrapper .agencies__more a,
main .profile__wrapper .profile__addService button,
main .registration__wrapper .registration__register a {
  background-color: #009640;
  border-color: #009640;
  color: #fff;
}

.btn-primary:hover,
.serviceOffCanvas .offcanvas-body .pubblica:hover,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:hover,
body .buorg #buorgul:hover,
main .acceptance__wrapper button:hover,
main .agencies__wrapper .agencies__more a:hover,
main .profile__wrapper .profile__addService button:hover,
main .registration__wrapper .registration__register a:hover {
  background-color: #008036;
  border-color: #007833;
  color: #fff;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus,
.serviceOffCanvas .offcanvas-body .btn-check:focus + .pubblica,
.serviceOffCanvas .offcanvas-body .pubblica:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:focus + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:focus,
body .buorg #buorgul:focus,
body .buorg .btn-check:focus + #buorgul,
main .acceptance__wrapper .btn-check:focus + button,
main .acceptance__wrapper button:focus,
main .agencies__wrapper .agencies__more .btn-check:focus + a,
main .agencies__wrapper .agencies__more a:focus,
main .profile__wrapper .profile__addService .btn-check:focus + button,
main .profile__wrapper .profile__addService button:focus,
main .registration__wrapper .registration__register .btn-check:focus + a,
main .registration__wrapper .registration__register a:focus {
  background-color: #008036;
  border-color: #007833;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(38, 166, 93, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(38, 166, 93, 0.5);
  color: #fff;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.serviceOffCanvas .offcanvas-body .active.pubblica,
.serviceOffCanvas .offcanvas-body .btn-check:active + .pubblica,
.serviceOffCanvas .offcanvas-body .btn-check:checked + .pubblica,
.serviceOffCanvas .offcanvas-body .pubblica:active,
.serviceOffCanvas .offcanvas-body .show > .dropdown-toggle.pubblica,
.show > .btn-primary.dropdown-toggle,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .active.ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:active + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:checked + .ctaButtons__login,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:active,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .show > .dropdown-toggle.ctaButtons__login,
body .buorg #buorgul:active,
body .buorg .active#buorgul,
body .buorg .btn-check:active + #buorgul,
body .buorg .btn-check:checked + #buorgul,
body .buorg .show > .dropdown-toggle#buorgul,
main .acceptance__wrapper .btn-check:active + button,
main .acceptance__wrapper .btn-check:checked + button,
main .acceptance__wrapper .show > button.dropdown-toggle,
main .acceptance__wrapper button.active,
main .acceptance__wrapper button:active,
main .agencies__wrapper .agencies__more .btn-check:active + a,
main .agencies__wrapper .agencies__more .btn-check:checked + a,
main .agencies__wrapper .agencies__more .show > a.dropdown-toggle,
main .agencies__wrapper .agencies__more a.active,
main .agencies__wrapper .agencies__more a:active,
main .profile__wrapper .profile__addService .btn-check:active + button,
main .profile__wrapper .profile__addService .btn-check:checked + button,
main .profile__wrapper .profile__addService .show > button.dropdown-toggle,
main .profile__wrapper .profile__addService button.active,
main .profile__wrapper .profile__addService button:active,
main .registration__wrapper .registration__register .btn-check:active + a,
main .registration__wrapper .registration__register .btn-check:checked + a,
main .registration__wrapper .registration__register .show > a.dropdown-toggle,
main .registration__wrapper .registration__register a.active,
main .registration__wrapper .registration__register a:active {
  background-color: #007833;
  border-color: #007130;
  color: #fff;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.serviceOffCanvas .offcanvas-body .active.pubblica:focus,
.serviceOffCanvas .offcanvas-body .btn-check:active + .pubblica:focus,
.serviceOffCanvas .offcanvas-body .btn-check:checked + .pubblica:focus,
.serviceOffCanvas .offcanvas-body .pubblica:active:focus,
.serviceOffCanvas .offcanvas-body .show > .dropdown-toggle.pubblica:focus,
.show > .btn-primary.dropdown-toggle:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .active.ctaButtons__login:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:active + .ctaButtons__login:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:checked + .ctaButtons__login:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:active:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .show > .dropdown-toggle.ctaButtons__login:focus,
body .buorg #buorgul:active:focus,
body .buorg .active#buorgul:focus,
body .buorg .btn-check:active + #buorgul:focus,
body .buorg .btn-check:checked + #buorgul:focus,
body .buorg .show > .dropdown-toggle#buorgul:focus,
main .acceptance__wrapper .btn-check:active + button:focus,
main .acceptance__wrapper .btn-check:checked + button:focus,
main .acceptance__wrapper .show > button.dropdown-toggle:focus,
main .acceptance__wrapper button.active:focus,
main .acceptance__wrapper button:active:focus,
main .agencies__wrapper .agencies__more .btn-check:active + a:focus,
main .agencies__wrapper .agencies__more .btn-check:checked + a:focus,
main .agencies__wrapper .agencies__more .show > a.dropdown-toggle:focus,
main .agencies__wrapper .agencies__more a.active:focus,
main .agencies__wrapper .agencies__more a:active:focus,
main .profile__wrapper .profile__addService .btn-check:active + button:focus,
main .profile__wrapper .profile__addService .btn-check:checked + button:focus,
main .profile__wrapper .profile__addService .show > button.dropdown-toggle:focus,
main .profile__wrapper .profile__addService button.active:focus,
main .profile__wrapper .profile__addService button:active:focus,
main .registration__wrapper .registration__register .btn-check:active + a:focus,
main .registration__wrapper .registration__register .btn-check:checked + a:focus,
main .registration__wrapper .registration__register .show > a.dropdown-toggle:focus,
main .registration__wrapper .registration__register a.active:focus,
main .registration__wrapper .registration__register a:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(38, 166, 93, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(38, 166, 93, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled,
.serviceOffCanvas .offcanvas-body .disabled.pubblica,
.serviceOffCanvas .offcanvas-body .pubblica:disabled,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__login:disabled,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .disabled.ctaButtons__login,
body .buorg #buorgul:disabled,
body .buorg .disabled#buorgul,
main .acceptance__wrapper button.disabled,
main .acceptance__wrapper button:disabled,
main .agencies__wrapper .agencies__more a.disabled,
main .agencies__wrapper .agencies__more a:disabled,
main .profile__wrapper .profile__addService button.disabled,
main .profile__wrapper .profile__addService button:disabled,
main .registration__wrapper .registration__register a.disabled,
main .registration__wrapper .registration__register a:disabled {
  background-color: #009640;
  border-color: #009640;
  color: #fff;
}

.btn-outline-primary,
.serviceOffCanvas .offcanvas-body .bozza,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi,
main .questions__wrapper .questions__ctaButton {
  border-color: #009640;
  color: #009640;
}

.btn-outline-primary:hover,
.serviceOffCanvas .offcanvas-body .bozza:hover,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi:hover,
main .questions__wrapper .questions__ctaButton:hover {
  background-color: #009640;
  border-color: #009640;
  color: #fff;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus,
.serviceOffCanvas .offcanvas-body .bozza:focus,
.serviceOffCanvas .offcanvas-body .btn-check:focus + .bozza,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:focus + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi:focus,
main .questions__wrapper .btn-check:focus + .questions__ctaButton,
main .questions__wrapper .questions__ctaButton:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.5);
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active,
.serviceOffCanvas .offcanvas-body .active.bozza,
.serviceOffCanvas .offcanvas-body .bozza:active,
.serviceOffCanvas .offcanvas-body .btn-check:active + .bozza,
.serviceOffCanvas .offcanvas-body .btn-check:checked + .bozza,
.serviceOffCanvas .offcanvas-body .dropdown-toggle.show.bozza,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .active.ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:active + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:checked + .ctaButtons__comeRegistrarsi,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi:active,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .dropdown-toggle.show.ctaButtons__comeRegistrarsi,
main .questions__wrapper .active.questions__ctaButton,
main .questions__wrapper .btn-check:active + .questions__ctaButton,
main .questions__wrapper .btn-check:checked + .questions__ctaButton,
main .questions__wrapper .dropdown-toggle.show.questions__ctaButton,
main .questions__wrapper .questions__ctaButton:active {
  background-color: #009640;
  border-color: #009640;
  color: #222;
}

.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus,
.serviceOffCanvas .offcanvas-body .active.bozza:focus,
.serviceOffCanvas .offcanvas-body .bozza:active:focus,
.serviceOffCanvas .offcanvas-body .btn-check:active + .bozza:focus,
.serviceOffCanvas .offcanvas-body .btn-check:checked + .bozza:focus,
.serviceOffCanvas .offcanvas-body .dropdown-toggle.show.bozza:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .active.ctaButtons__comeRegistrarsi:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:active + .ctaButtons__comeRegistrarsi:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .btn-check:checked + .ctaButtons__comeRegistrarsi:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi:active:focus,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .dropdown-toggle.show.ctaButtons__comeRegistrarsi:focus,
main .questions__wrapper .active.questions__ctaButton:focus,
main .questions__wrapper .btn-check:active + .questions__ctaButton:focus,
main .questions__wrapper .btn-check:checked + .questions__ctaButton:focus,
main .questions__wrapper .dropdown-toggle.show.questions__ctaButton:focus,
main .questions__wrapper .questions__ctaButton:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.serviceOffCanvas .offcanvas-body .bozza:disabled,
.serviceOffCanvas .offcanvas-body .disabled.bozza,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .ctaButtons__comeRegistrarsi:disabled,
.stickyBar__wrapper .stickyBar__container .stickyBar__ctaButtons .disabled.ctaButtons__comeRegistrarsi,
main .questions__wrapper .disabled.questions__ctaButton,
main .questions__wrapper .questions__ctaButton:disabled {
  background-color: transparent;
  color: #009640;
}

main .info__wrapper .info .content__title .h2,
main .info__wrapper .info .content__title h2 {
  border-left: 10px solid #222;
}

[v-cloak] {
  display: none;
}

body .buorg {
  -webkit-animation: buorgfly-fast .4s ease-out 0s;
  animation: buorgfly-fast .4s ease-out 0s;
  background-color: #fff8ea;
  border-bottom: 1px solid #a29330;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: #000;
  font: 18px Monserrat, Helvetica, sans-serif;
  left: 0;
  position: absolute;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 111111;
}

body .buorg .buorg-pad {
  line-height: 1.7em;
  padding: 15px;
}

.buorg-buttons {
  display: block;
  text-align: center;
}

#buorgig,
#buorgpermanent,
#buorgul {
  background: #5ab400;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin: 0 2px;
  padding: 1px 10px;
  text-decoration: none;
  white-space: nowrap;
}

#buorgig {
  background-color: #edbc68;
}

@media only screen and (max-width: 700px) {
  .buorg div {
    line-height: 1.3em;
    padding: 5px 12px 5px 9px;
  }
}

@-webkit-keyframes buorgfly-fast {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes buorgfly-fast {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.buorg-icon {
  background: no-repeat 0;
  display: inline-block;
  height: 16px;
  position: relative;
  top: -.05em;
  vertical-align: middle;
  width: 22px;
}

.ce-banner {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 1.5rem;
  position: fixed;
  right: 0;
  z-index: 2001;
}

.ce-banner a:not([class]) {
  color: #fff;
}

.navbar-brand {
  z-index: 1046;
}

@media (min-width: 992px) {
  .navbar-brand img {
    height: 70px;
    width: auto;
  }
}

.navbar-brand .vr {
  height: 44px;
}

@media (min-width: 992px) {
  .navbar-brand .vr {
    height: 74px;
  }
}

.navbar-toggler {
  border: 0;
  color: #222;
  font-size: 11px;
  height: 55px;
  line-height: 1;
  text-transform: uppercase;
  width: 55px;
  z-index: 1046;
}

.navbar-toggler svg {
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
}

.navbar-toggler svg path {
  -webkit-transition: stroke-dashoffset .2s ease;
  transition: stroke-dashoffset .2s ease;
}

.navbar-toggler svg .h--3 {
  -webkit-transition-delay: .6s;
          transition-delay: .6s;
}

.navbar-toggler svg .h--2 {
  -webkit-transition-delay: .5s;
          transition-delay: .5s;
}

.navbar-toggler svg .h--1 {
  -webkit-transition-delay: .4s;
          transition-delay: .4s;
}

.navbar-toggler svg .o--2 {
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}

.navbar-toggler svg .o--1 {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.navbar-toggler:not(.collapsed) .o {
  stroke-dashoffset: 0;
}

.navbar-toggler:not(.collapsed) .h {
  stroke-dashoffset: 100%;
}

.navbar-toggler:not(.collapsed) .h--3 {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.navbar-toggler:not(.collapsed) .h--2 {
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}

.navbar-toggler:not(.collapsed) .h--1 {
  -webkit-transition-delay: .2s;
          transition-delay: .2s;
}

.navbar-toggler:not(.collapsed) .o--2 {
  -webkit-transition-delay: .5s;
          transition-delay: .5s;
}

.navbar-toggler:not(.collapsed) .o--1 {
  -webkit-transition-delay: .6s;
          transition-delay: .6s;
}

.navbar-toggler:focus {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  outline: none;
  -webkit-transition: -webkit-box-shadow .2s ease;
  transition: -webkit-box-shadow .2s ease;
  transition: box-shadow .2s ease;
  transition: box-shadow .2s ease, -webkit-box-shadow .2s ease;
}

.navbar-toggler:hover svg {
  -webkit-transform: scale(1.1) translate3d(1%, 1%, 0);
          transform: scale(1.1) translate3d(1%, 1%, 0);
}

@media (max-width: 991.98px) {
  .navbar-nav {
    margin-top: 55px;
    max-width: 60vw;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li,
  .modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li,
  .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li,
  .navbar-nav .nav-item,
  .navbar-nav main .faq__wrapper .nav-anchor li,
  .navbar-nav main .profile__services .status__filter li,
  main .faq__wrapper .nav-anchor .navbar-nav li,
  main .faq__wrapper .navbar-nav .nav-anchor li,
  main .profile__services .navbar-nav .status__filter li,
  main .profile__services .status__filter .navbar-nav li {
    opacity: 0;
    -webkit-transform: translateX(20vw);
            transform: translateX(20vw);
    -webkit-transition: opacity .2s ease, -webkit-transform .3s ease;
    transition: opacity .2s ease, -webkit-transform .3s ease;
    transition: transform .3s ease, opacity .2s ease;
    transition: transform .3s ease, opacity .2s ease, -webkit-transform .3s ease;
    -webkit-transition-delay: calc(var(--CDPdelay)*.1s + .5s);
            transition-delay: calc(var(--CDPdelay)*.1s + .5s);
  }
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li .nav-link,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li a,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-nav .nav-item a,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li .nav-link,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li a,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-link,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a,
.navbar-nav .nav-item .nav-link,
.navbar-nav main .faq__wrapper .nav-anchor li .nav-link,
.navbar-nav main .faq__wrapper .nav-anchor li a,
.navbar-nav main .profile__services .status__filter li .nav-link,
.navbar-nav main .profile__services .status__filter li a,
main .faq__wrapper .nav-anchor .navbar-nav li .nav-link,
main .faq__wrapper .nav-anchor .navbar-nav li a,
main .faq__wrapper .nav-anchor li .navbar-nav .nav-item a,
main .faq__wrapper .navbar-nav .nav-anchor li .nav-link,
main .faq__wrapper .navbar-nav .nav-anchor li a,
main .profile__services .navbar-nav .status__filter li .nav-link,
main .profile__services .navbar-nav .status__filter li a,
main .profile__services .status__filter .navbar-nav li .nav-link,
main .profile__services .status__filter .navbar-nav li a,
main .profile__services .status__filter li .navbar-nav .nav-item a {
  color: #222;
  font-size: 16px;
  font-weight: 300;
  padding: .5rem 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li .nav-link:before,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li a:before,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-nav .nav-item a:before,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li .nav-link:before,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li a:before,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-link:before,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:before,
.navbar-nav .nav-item .nav-link:before,
.navbar-nav main .faq__wrapper .nav-anchor li .nav-link:before,
.navbar-nav main .faq__wrapper .nav-anchor li a:before,
.navbar-nav main .profile__services .status__filter li .nav-link:before,
.navbar-nav main .profile__services .status__filter li a:before,
main .faq__wrapper .nav-anchor .navbar-nav li .nav-link:before,
main .faq__wrapper .nav-anchor .navbar-nav li a:before,
main .faq__wrapper .nav-anchor li .navbar-nav .nav-item a:before,
main .faq__wrapper .navbar-nav .nav-anchor li .nav-link:before,
main .faq__wrapper .navbar-nav .nav-anchor li a:before,
main .profile__services .navbar-nav .status__filter li .nav-link:before,
main .profile__services .navbar-nav .status__filter li a:before,
main .profile__services .status__filter .navbar-nav li .nav-link:before,
main .profile__services .status__filter .navbar-nav li a:before,
main .profile__services .status__filter li .navbar-nav .nav-item a:before {
  border-bottom: 5px solid;
  bottom: 0;
  color: #666;
  color: #009640;
  content: "";
  left: 0;
  margin-bottom: .1rem;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scaleX(0.001);
          transform: scaleX(0.001);
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transition: opacity .1s ease, -webkit-transform .3s ease;
  transition: opacity .1s ease, -webkit-transform .3s ease;
  transition: opacity .1s ease, transform .3s ease;
  transition: opacity .1s ease, transform .3s ease, -webkit-transform .3s ease;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li .nav-link.active,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li a.active,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-nav .nav-item a.active,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li .nav-link.active,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li a.active,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-link.active,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.active,
.navbar-nav .nav-item .nav-link.active,
.navbar-nav main .faq__wrapper .nav-anchor li .nav-link.active,
.navbar-nav main .faq__wrapper .nav-anchor li a.active,
.navbar-nav main .profile__services .status__filter li .nav-link.active,
.navbar-nav main .profile__services .status__filter li a.active,
main .faq__wrapper .nav-anchor .navbar-nav li .nav-link.active,
main .faq__wrapper .nav-anchor .navbar-nav li a.active,
main .faq__wrapper .nav-anchor li .navbar-nav .nav-item a.active,
main .faq__wrapper .navbar-nav .nav-anchor li .nav-link.active,
main .faq__wrapper .navbar-nav .nav-anchor li a.active,
main .profile__services .navbar-nav .status__filter li .nav-link.active,
main .profile__services .navbar-nav .status__filter li a.active,
main .profile__services .status__filter .navbar-nav li .nav-link.active,
main .profile__services .status__filter .navbar-nav li a.active,
main .profile__services .status__filter li .navbar-nav .nav-item a.active {
  font-weight: 700;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li .nav-link.active:before,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li a.active:before,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-nav .nav-item a.active:before,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li .nav-link.active:before,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li a.active:before,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-link.active:before,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.active:before,
.navbar-nav .nav-item .nav-link.active:before,
.navbar-nav main .faq__wrapper .nav-anchor li .nav-link.active:before,
.navbar-nav main .faq__wrapper .nav-anchor li a.active:before,
.navbar-nav main .profile__services .status__filter li .nav-link.active:before,
.navbar-nav main .profile__services .status__filter li a.active:before,
main .faq__wrapper .nav-anchor .navbar-nav li .nav-link.active:before,
main .faq__wrapper .nav-anchor .navbar-nav li a.active:before,
main .faq__wrapper .nav-anchor li .navbar-nav .nav-item a.active:before,
main .faq__wrapper .navbar-nav .nav-anchor li .nav-link.active:before,
main .faq__wrapper .navbar-nav .nav-anchor li a.active:before,
main .profile__services .navbar-nav .status__filter li .nav-link.active:before,
main .profile__services .navbar-nav .status__filter li a.active:before,
main .profile__services .status__filter .navbar-nav li .nav-link.active:before,
main .profile__services .status__filter .navbar-nav li a.active:before,
main .profile__services .status__filter li .navbar-nav .nav-item a.active:before {
  color: #666;
  color: #009640;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li .nav-link.active:before,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li .nav-link:hover:before,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li a.active:before,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar-nav li a:hover:before,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-nav .nav-item a.active:before,
.modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .navbar-nav .nav-item a:hover:before,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li .nav-link.active:before,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li .nav-link:hover:before,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li a.active:before,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar-nav .content__tab li a:hover:before,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-link.active:before,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li .nav-link:hover:before,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a.active:before,
.navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li a:hover:before,
.navbar-nav .nav-item .nav-link.active:before,
.navbar-nav .nav-item .nav-link:hover:before,
.navbar-nav main .faq__wrapper .nav-anchor li .nav-link.active:before,
.navbar-nav main .faq__wrapper .nav-anchor li .nav-link:hover:before,
.navbar-nav main .faq__wrapper .nav-anchor li a.active:before,
.navbar-nav main .faq__wrapper .nav-anchor li a:hover:before,
.navbar-nav main .profile__services .status__filter li .nav-link.active:before,
.navbar-nav main .profile__services .status__filter li .nav-link:hover:before,
.navbar-nav main .profile__services .status__filter li a.active:before,
.navbar-nav main .profile__services .status__filter li a:hover:before,
main .faq__wrapper .nav-anchor .navbar-nav li .nav-link.active:before,
main .faq__wrapper .nav-anchor .navbar-nav li .nav-link:hover:before,
main .faq__wrapper .nav-anchor .navbar-nav li a.active:before,
main .faq__wrapper .nav-anchor .navbar-nav li a:hover:before,
main .faq__wrapper .nav-anchor li .navbar-nav .nav-item a.active:before,
main .faq__wrapper .nav-anchor li .navbar-nav .nav-item a:hover:before,
main .faq__wrapper .navbar-nav .nav-anchor li .nav-link.active:before,
main .faq__wrapper .navbar-nav .nav-anchor li .nav-link:hover:before,
main .faq__wrapper .navbar-nav .nav-anchor li a.active:before,
main .faq__wrapper .navbar-nav .nav-anchor li a:hover:before,
main .profile__services .navbar-nav .status__filter li .nav-link.active:before,
main .profile__services .navbar-nav .status__filter li .nav-link:hover:before,
main .profile__services .navbar-nav .status__filter li a.active:before,
main .profile__services .navbar-nav .status__filter li a:hover:before,
main .profile__services .status__filter .navbar-nav li .nav-link.active:before,
main .profile__services .status__filter .navbar-nav li .nav-link:hover:before,
main .profile__services .status__filter .navbar-nav li a.active:before,
main .profile__services .status__filter .navbar-nav li a:hover:before,
main .profile__services .status__filter li .navbar-nav .nav-item a.active:before,
main .profile__services .status__filter li .navbar-nav .nav-item a:hover:before {
  opacity: 1;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__tab .navbar .show .navbar-nav li,
.modal .modal-dialog .modal-content .modal-body .body__content .navbar .show .navbar-nav .content__tab li,
.navbar .show .navbar-nav .modal .modal-dialog .modal-content .modal-body .body__content .content__tab li,
.navbar .show .navbar-nav .nav-item,
.navbar .show .navbar-nav main .faq__wrapper .nav-anchor li,
.navbar .show .navbar-nav main .profile__services .status__filter li,
main .faq__wrapper .nav-anchor .navbar .show .navbar-nav li,
main .faq__wrapper .navbar .show .navbar-nav .nav-anchor li,
main .profile__services .navbar .show .navbar-nav .status__filter li,
main .profile__services .status__filter .navbar .show .navbar-nav li {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition-delay: calc(var(--delay)*.1s + .5s);
          transition-delay: calc(var(--delay)*.1s + .5s);
}

.hero {
  background-image: url("./assets/img/header.jpeg");
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.hero__content .content__text,
.hero__content .content__text .h1,
.hero__content .content__text h1 {
  color: #fff;
}

.hero__content .content__text hr {
  height: 5px;
  opacity: 1;
  width: 3rem;
}

.hero__content .content__text p {
  font-size: 1rem;
  font-weight: 700;
}

.hero__content .content__img img {
  max-width: 200px;
}

.ecosystem {
  background: #f5f5f5;
}

.ecosystem .h2,
.ecosystem h2 {
  color: #495057;
  font-size: 2rem;
  font-weight: 400;
}

.ecosystem ul {
  list-style: none;
}

footer {
  position: relative;
}

footer:before {
  background: #495057;
  left: 0;
  right: 0;
}

footer > ul {
  list-style: none;
  position: relative;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  footer > ul {
    width: 50%;
  }
}

footer > ul > li {
  color: #fff;
}

footer > ul > li.info {
  font-size: 1rem;
  font-weight: 300;
  opacity: .4;
}

@media (min-width: 1200px) {
  footer > ul > li.info {
    padding-left: 10vw;
  }
}

@media (min-width: 992px) {
  footer > ul > li.menu {
    padding-left: 10vw;
  }
}

footer > ul > li.menu ul {
  list-style-type: none;
}

footer > ul > li.menu ul li a {
  color: #fff;
  font-weight: 300;
}

footer > ul > li.social {
  font-weight: 300;
  line-height: 2rem;
  position: relative;
}

footer > ul > li.social a {
  color: #fff;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  footer > ul > li.social {
    height: 100%;
    position: absolute;
    right: -100%;
    width: 100%;
  }
}

footer > ul > li.social:before {
  background: rgba(0, 0, 0, 0.4);
  left: -1.5rem;
  right: -1.5rem;
}

@media (min-width: 768px) {
  footer > ul > li.social:before {
    left: 0;
  }
}

footer > ul > li .h2,
footer > ul > li h2 {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}

footer:before,
footer > ul > li.social:before {
  bottom: 0;
  content: "";
  position: absolute;
  top: 0;
  z-index: -1;
}

fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: inherit;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

.multiselect {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  color: #212529;
  display: block;
  min-height: calc(1.5em + .75rem);
  position: relative;
  text-align: left;
  width: 100%;
}

.multiselect * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.multiselect:focus {
  outline: none;
}

.multiselect--disabled {
  opacity: .65;
}

.multiselect--active {
  z-index: 50;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
  background: #fff;
  border: none;
  border-radius: .25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  line-height: calc(1.5em + .75rem)/2;
  margin-bottom: .375rem;
  min-height: calc(1.5em + .75rem)/2;
  padding: 0 0 0 .75rem;
  position: relative;
  -webkit-transition: border .1s ease;
  transition: border .1s ease;
  vertical-align: top;
  width: 100%;
}

.multiselect__input:-ms-input-placeholder {
  color: #6c757d;
}

.multiselect__input::-webkit-input-placeholder {
  color: #6c757d;
}

.multiselect__input::-ms-input-placeholder {
  color: #6c757d;
}

.multiselect__input::placeholder {
  color: #6c757d;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}

.multiselect__single {
  margin-bottom: .375rem;
  padding-left: .75rem;
}

.multiselect__tags-wrap {
  display: inline;
}

.multiselect__tags {
  background: #fff;
  border: 0 solid #ced4da;
  border-radius: .25rem;
  display: block;
  font-family: inherit;
  font-size: inherit;
  min-height: calc(1.5em + .75rem);
  padding: 0.375rem calc(1.5em + .75rem) 0 0.375rem;
}

.multiselect__tag {
  background: #f8f9fa;
  border-radius: .25rem;
  color: #212529;
  display: inline-block;
  font-size: .75em;
  font-weight: 400;
  margin-right: .4rem;
  max-width: 100%;
  overflow: hidden;
  padding: .25rem 2.1rem .25rem .4rem;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected:not(.multiselect--active) .multiselect__tags {
  background-color: #009640;
}

.selected:not(.multiselect--active) .multiselect__single {
  background-color: #009640;
  color: #fff;
}

.selected:not(.multiselect--active) .multiselect__single .num {
  background-color: #fff;
  border-radius: 9999px;
  color: #222;
  height: 25px;
  line-height: 25px;
  text-align: center;
  width: 25px;
}

.multiselect__tag-icon {
  bottom: 0;
  cursor: pointer;
  font-size: .75em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;
  margin-left: .25rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 1.7rem;
}

.multiselect__tag-icon:after {
  color: #212529;
  content: "×";
  font-size: 220%;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #e30513;
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}

.multiselect__current {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  min-height: calc(1.5em + .75rem);
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
}

.multiselect__current,
.multiselect__select {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: block;
  line-height: calc(1.5em + .75rem)/2;
  margin: 0;
  text-decoration: none;
}

.multiselect__select {
  height: calc(1.5em + .75rem);
  padding: 8px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  width: calc(1.5em + .75rem);
}

.multiselect__select:before {
  border-color: #212529 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  color: #212529;
  content: "";
  position: relative;
  right: 0;
  top: 50%;
}

.multiselect__placeholder {
  margin-bottom: 10px;
  padding-top: 2px;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect__content-wrapper {
  -webkit-overflow-scrolling: touch;
  background: #fff;
  border: 0 solid #ced4da;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-top: none;
  display: block;
  max-height: 240px;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: 50;
}

.multiselect__content {
  display: inline-block;
  list-style: none;
  margin: 0;
  min-width: 100%;
  padding: 0;
  vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
  border-bottom: none;
  border-radius: .25rem .25rem 0 0;
  border-top: 0 solid #ced4da;
  bottom: 100%;
}

.multiselect__content::webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  cursor: pointer;
  display: block;
  line-height: calc(1.5em + .75rem)/2;
  min-height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  position: relative;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  white-space: nowrap;
}

.multiselect__option:after {
  font-family: inherit;
  font-size: .875em;
  line-height: calc(1.5em + .75rem);
  padding-left: 20px;
  padding-right: 12px;
  position: absolute;
  right: 0;
  top: 0;
}

.multiselect__option--highlight {
  background: #009640;
  color: #fff;
  outline: none;
}

.multiselect__option--highlight:after {
  background: #009640;
  color: #fff;
  content: attr(data-select);
}

.multiselect__option--selected {
  background: #e30513;
  color: #fff;
  font-weight: 700;
}

.multiselect__option--selected:after {
  color: silver;
  content: attr(data-selected);
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #6c757d;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #6c757d;
  color: #fff;
  content: attr(data-deselect);
}

.multiselect--disabled {
  background: #e9ecef;
  pointer-events: none;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #e9ecef;
}

.multiselect__option--disabled {
  background: #e9ecef;
  cursor: text;
  pointer-events: none;
}

.multiselect__option--group {
  background: #e9ecef;
}

.multiselect__option--group.multiselect__option--highlight {
  color: #e9ecef;
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #e9ecef;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #6c757d;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #6c757d;
  color: #fff;
  content: attr(data-deselect);
}

.multiselect-enter-active,
.multiselect-leave-active {
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  display: inline-block;
  line-height: calc(1.5em + .75rem)/2;
  margin-bottom: .375rem;
  vertical-align: top;
}

.multiselect__spinner {
  display: block;
  height: calc(1.5em + .75rem);
  position: absolute;
  right: 0;
  top: 0;
  width: calc(1.5em + .75rem);
}

.multiselect__spinner:after,
.multiselect__spinner:before {
  background: #fff;
  border: 2px solid transparent;
  border-radius: 100%;
  border-top-color: #212529;
  -webkit-box-shadow: 0 0 0 1px transparent;
          box-shadow: 0 0 0 1px transparent;
  content: "";
  height: 16px;
  left: 50%;
  margin: -8px 0 0 -8px;
  position: absolute;
  top: 50%;
  width: 16px;
}

.multiselect__spinner:before {
  -webkit-animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.multiselect__spinner:after {
  -webkit-animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes spinning {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(2turn);
            transform: rotate(2turn);
  }
}

@keyframes spinning {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(2turn);
            transform: rotate(2turn);
  }
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  opacity: 1;
  -webkit-transition: opacity .4s ease-in-out;
  transition: opacity .4s ease-in-out;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

[dir=rtl] .multiselect {
  text-align: right;
}

[dir=rtl] .multiselect__select {
  left: 1px;
  right: auto;
}

[dir=rtl] .multiselect__tags {
  padding: 0.375rem 0.375rem 0 calc(1.5em + .75rem);
}

[dir=rtl] .multiselect__content {
  text-align: right;
}

[dir=rtl] .multiselect__option:after {
  left: 0;
  right: auto;
}

[dir=rtl] .multiselect__clear {
  left: 12px;
  right: auto;
}

[dir=rtl] .multiselect__spinner {
  left: 1px;
  right: auto;
}

.multiselect__placeholder {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 0;
  margin-left: 12px;
  padding-top: 0;
}

.form-group.is-valid .multiselect__tags {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23009640' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") #fff no-repeat center right 2.25rem/calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #009640;
  padding-right: 4.125rem;
}

.form-group.is-valid .multiselect__tags:focus {
  border-color: #009640;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(0, 150, 64, 0.25);
}

.form-group.is-invalid .multiselect__tags {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e30513'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23e30513' stroke='none'/%3E%3C/svg%3E") #fff no-repeat center right 2.25rem/calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #e30513;
  padding-right: 4.125rem;
}

.form-group.is-invalid .multiselect__tags:focus {
  border-color: #e30513;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(227, 5, 19, 0.25);
}

main .info__wrapper {
  background-color: #f1f1f1;
}

main .info__wrapper .info .content__list .list__wrapper article .list__label {
  color: #00426c;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

main .info__wrapper .info .content__list .list__wrapper article .list__title {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  main .info__wrapper .info .content__iframe div {
    margin: auto;
    max-width: 80%;
  }
}

main .registration__wrapper {
  scroll-margin-top: 100px;
}

main .registration__wrapper .registration__title {
  padding-left: 80px;
  position: relative;
}

main .registration__wrapper .registration__title .h4,
main .registration__wrapper .registration__title h4 {
  font-size: 24px;
}

main .registration__wrapper .registration__title:before {
  /* background-image: url(/img/logo_xtra-shadow.svg); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: 40px;
  left: .75rem;
  position: absolute;
  top: 3px;
  width: 40px;
}

@media (min-width: 768px) {
  main .registration__wrapper .registration__title {
    padding-left: 120px;
  }
  main .registration__wrapper .registration__title:before {
    height: 80px;
    width: 80px;
  }
}

main .registration__wrapper .registration__list .list__wrapper {
  counter-reset: cdp-registration;
  list-style-type: none;
}

main .registration__wrapper .registration__list .list__wrapper li {
  padding-left: 80px;
  position: relative;
}

main .registration__wrapper .registration__list .list__wrapper li:before {
  background-color: #05426c;
  border-radius: 50%;
  color: #fff;
  content: counter(cdp-registration);
  counter-increment: cdp-registration 1;
  display: block;
  font-size: 20px;
  font-weight: 900;
  height: 40px;
  left: .75rem;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: 3px;
  width: 40px;
}

main .agencies__wrapper .agencies__agency .agency__image {
  background-color: #f1f1f1;
  height: 100%;
}

main .agencies__wrapper .agencies__agency .agency__image img {
  max-width: 87px;
}

main .agencies__wrapper .agencies__more span {
  color: #6c757d;
}

main .questions__wrapper .questions {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

main .marketplace_wrapper .marketplace .content__filters {
  scroll-margin: 20px;
}

main .marketplace_wrapper .marketplace .content__filters > ul {
  list-style-type: none;
}

main .marketplace_wrapper .marketplace .content__filters > ul > li.filters__spinner > div {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}

main .marketplace_wrapper .marketplace .content__tabs > ul {
  list-style-type: none;
}

main .marketplace_wrapper .marketplace .content__tabs > ul li {
  -ms-flex-line-pack: start;
      align-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

main .marketplace_wrapper .marketplace .content__tabs > ul li .h3,
main .marketplace_wrapper .marketplace .content__tabs > ul li h3 {
  width: 100%;
}

main .marketplace_wrapper .marketplace .content__tabs > ul li div {
  background-color: #f5f5f5;
  border-radius: 9999px;
  color: #626262;
  line-height: 20px;
  padding: 5px 10px;
}

main .marketplace_wrapper .marketplace .content__tabs > ul li div input {
  display: none;
}

main .marketplace_wrapper .marketplace .content__tabs > ul li div label {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13'%3E%3Cpath fill='%23626262' d='M8.853 8.145 7.209 6.501l1.644-1.644a.5.5 0 0 0-.705-.7L6.504 5.801 4.859 4.155a.5.5 0 1 0-.705.7l1.644 1.644-1.644 1.646a.482.482 0 0 0 0 .7.5.5 0 0 0 .705 0l1.644-1.644 1.644 1.644a.5.5 0 0 0 .705 0 .5.5 0 0 0 .001-.7z'/%3E%3Cpath fill='%23626262' d='M6.5.875a5.623 5.623 0 1 1-3.975 1.65A5.588 5.588 0 0 1 6.5.875M6.5 0A6.5 6.5 0 1 0 13 6.5 6.5 6.5 0 0 0 6.5 0z'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  margin-right: 5px;
  width: 16px;
}

main .acceptance__wrapper div div {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

main .acceptance__wrapper div div input {
  margin-top: 5px;
}

@media (max-width: 767.98px) {
  main .profile__wrapper .profile__info {
    border-bottom: 5px solid #009640;
  }
}

@media (min-width: 768px) {
  main .profile__wrapper .profile__info {
    border-left: 5px solid #009640;
  }
}

main .profile__wrapper .profile__info ul {
  list-style: none;
}

main .profile__wrapper .profile__info ul li {
  color: #6c757d;
  margin-bottom: 5px;
}

main .profile__wrapper .profile__info ul li i {
  margin-right: 5px;
  vertical-align: text-bottom;
}

main .profile__data .chart__donuts,
main .profile__data .chart__main {
  min-height: 300px;
}

main .profile__services .status__filter {
  scroll-margin-top: 20px;
}

.services-cards .content__cards {
  position: relative;
}

.services-cards .content__cards .cards {
  list-style-type: none;
}

.services-cards .content__cards .cards .service a {
  color: #222;
  text-decoration: none;
}

.services-cards .content__error {
  color: #e30513;
}

.services-cards .offcanvas-backdrop {
  z-index: 1046;
}

.overlay {
  background-color: rgba(255, 255, 255, 0.75);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99999;
}

.overlay.offCanvas-overlay {
  left: auto;
  position: fixed;
  width: 900px;
}

.overlay.card-overlay {
  bottom: var(--bs-gutter-x, -1.5rem);
  left: var(--bs-gutter-x, -1.5rem);
  right: var(--bs-gutter-x, -1.5rem);
  top: var(--bs-gutter-x, -1.5rem);
}

.service-card {
  border-bottom: 5px solid #fff;
  padding: 1rem 2rem;
  position: relative;
  z-index: 1;
}

.service-card.slim,
.service-card.slim .card-body {
  padding: .5rem;
}

.service-card:hover {
  z-index: 0;
}

.service-card .sales {
  color: #fff;
  padding: 2px 10px;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 2;
}

.service-card .image-wrapper {
  height: 200px;
  position: relative;
  z-index: 1;
}

.service-card .image-wrapper img {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 200px;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.service-card .card-body .card-title {
  font-weight: 600;
  text-decoration: none;
  -webkit-text-decoration-style: none;
  text-decoration-style: none;
}

.service-card .card-footer .category {
  border: 1px solid #222;
  line-height: 1.2;
  white-space: pre-wrap;
}

.service-card .card-footer .edit {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.service-card .card-footer .edit .button_wrapper i {
  cursor: pointer;
}

.service-card .card-footer .edit .status {
  line-height: 1.2;
  white-space: pre-wrap;
}

.service-card .card-footer .edit .status-1 {
  border: 1px solid #009640;
  color: #009640;
}

.service-card .card-footer .edit .status-0 {
  border: 1px solid #e30513;
  color: #e30513;
}

.service-card .card-footer .edit .status-2 {
  border: 1px solid #6c757d;
  color: #6c757d;
}

.service-card.range-1 {
  border-bottom-color: #87e0d6;
}

.service-card.range-1 .sales {
  background-color: #87e0d6;
}

.service-card.range-2 {
  border-bottom-color: #4fc452;
}

.service-card.range-2 .sales {
  background-color: #4fc452;
}

.service-card.range-3 {
  border-bottom-color: #97d852;
}

.service-card.range-3 .sales {
  background-color: #97d852;
}

.service-card.range-4 {
  border-bottom-color: #efe131;
}

.service-card.range-4 .sales {
  background-color: #efe131;
}

.service-card.range-5 {
  border-bottom-color: #fac82e;
}

.service-card.range-5 .sales {
  background-color: #fac82e;
}

.service-card.free {
  border-bottom-color: #adb5bd;
}

.service-card.free .sales {
  background-color: #fff;
  border: 1px solid #222;
  color: #222;
}

.modal .modal-dialog {
  max-width: 1200px;
}

.modal .modal-dialog.no_user {
  max-width: 840px;
  width: 100%;
}

.modal .modal-dialog.no_user .modal-content .modal-body {
      grid-template-areas: "header" "gallery" "content";
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

@media (max-width: 991.98px) {
  .modal .modal-dialog.no_user .modal-content .modal-body .body__gallery {
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
    overflow: hidden;
  }
}

.modal .modal-dialog .modal-content {
  background-color: transparent;
}

.modal .modal-dialog .modal-content .modal-body {
      grid-template-areas: "header header" "gallery form" "content form";
  padding-top: 0;
}

@media (min-width: 992px) {
  .modal .modal-dialog .modal-content .modal-body {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.3fr minmax(400px, 0.7fr);
        grid-template-columns: 1.3fr minmax(400px, 0.7fr);
  }
}

.modal .modal-dialog .modal-content .modal-body .body__header {
  background-color: #fff;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: header;
  padding: 1rem;
  width: 100%;
}

@media (max-width: 575.98px) {
  .modal .modal-dialog .modal-content .modal-body .body__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}

.modal .modal-dialog .modal-content .modal-body .body__header .header__info {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style: none;
}

.modal .modal-dialog .modal-content .modal-body .body__header span {
  font-weight: 700;
  margin-left: auto;
}

.modal .modal-dialog .modal-content .modal-body .body__gallery {
  background-color: #fff;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: gallery;
}

.modal .modal-dialog .modal-content .modal-body .body__gallery .slider {
  padding-top: 35.7%;
}

.modal .modal-dialog .modal-content .modal-body .body__gallery .slider img {
  width: 100%;
}

.modal .modal-dialog .modal-content .modal-body .body__gallery__empty {
  position: relative;
}

.modal .modal-dialog .modal-content .modal-body .body__gallery__empty .xtra-logo {
  height: 100%;
  position: absolute;
}

.modal .modal-dialog .modal-content .modal-body .body__gallery__empty img {
  width: 100%;
}

.modal .modal-dialog .modal-content .modal-body .body__form {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
  grid-area: form;
}

.modal .modal-dialog .modal-content .modal-body .body__form .form__wrapper {
  background-color: #f4f8f6;
  min-height: 400px;
  position: relative;
}

@media (max-width: 991.98px) {
  .modal .modal-dialog .modal-content .modal-body .body__form .form__wrapper {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

@media (min-width: 992px) {
  .modal .modal-dialog .modal-content .modal-body .body__form .form__wrapper {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.modal .modal-dialog .modal-content .modal-body .body__form .form__wrapper .h4,
.modal .modal-dialog .modal-content .modal-body .body__form .form__wrapper h4 {
  font-weight: 600;
  text-transform: uppercase;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content {
  background-color: #fff;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: content;
}

@media (min-width: 992px) {
  .modal .modal-dialog .modal-content .modal-body .body__content .content {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service {
  list-style: none;
}

@media (max-width: 767.98px) {
  .modal .modal-dialog .modal-content .modal-body .body__content .content__service {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__image {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__image img {
  max-height: 80px;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__name {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__name .h3,
.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__name h3 {
  font-weight: 600;
  margin-bottom: 0;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}

@media (max-width: 767.98px) {
  .modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
    text-align: right;
    width: 100%;
  }
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales .sales {
  color: #fff;
  padding: 2px 10px;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales.range-1 .sales {
  background-color: #87e0d6;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales.range-2 .sales {
  background-color: #4fc452;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales.range-3 .sales {
  background-color: #97d852;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales.range-4 .sales {
  background-color: #efe131;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales.range-5 .sales {
  background-color: #fac82e;
}

.modal .modal-dialog .modal-content .modal-body .body__content .content__service li.service__sales.free .sales {
  border: 1px solid #222;
  color: #222;
}

@media (min-width: 992px) {
  .modal .modal-dialog .modal-content .modal-body .body__content .content__content {
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-color: #f8f9fa #6c757d;
    scrollbar-width: thin;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content__content::-webkit-scrollbar {
    width: 11px;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content__content::-webkit-scrollbar-track {
    background: #6c757d;
    border-radius: 6px;
  }
  .modal .modal-dialog .modal-content .modal-body .body__content .content__content::-webkit-scrollbar-thumb {
    background-color: #f8f9fa;
    border: 3px solid #6c757d;
    border-radius: 6px;
  }
}

.modal .modal-dialog .modal-content .modal-body .body__content .h4,
.modal .modal-dialog .modal-content .modal-body .body__content h4 {
  font-weight: 600;
}

.pagination_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.pagination_nav .pagination {
  border-radius: .25rem;
}

.pagination_nav .pagination li:not(.prev):not(.next) {
  height: 36px;
  width: 36px;
}

.pagination_nav .pagination li:not(.prev):not(.next) a {
  border-radius: 9999px;
}

.pagination_nav .pagination li {
  text-align: center;
}

.pagination_nav .pagination li a {
  border-width: 0;
}

.serviceOffCanvas {
  background-color: #f4f8f6;
  z-index: 1048;
}

.serviceOffCanvas .tox.tox-tinymce.tox-fullscreen {
  z-index: 20000;
}

.serviceOffCanvas .offcanvas-body {
  position: relative;
}

.serviceOffCanvas .offcanvas-body .submit {
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  width: 100%;
}

.fieldset .form__group {
  display: block;
}

.fieldset .form__group .picture-input input {
  display: none;
}

.fieldset .form__group .input-group {
  border-radius: .25rem;
}

.fieldset .form__group .tox-tinymce {
  border-radius: .25rem;
  border-width: 0;
}

.fieldset .form__group .picture-input > div {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fieldset .form__group .picture-input > div .preview-container {
  border-radius: .25rem !important;
  margin: 0;
  position: relative;
}

.fieldset .form__group .picture-input > div .preview-container .picture-inner {
  /* background-image: url(/img/icons-ui/icon_upload.svg); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  border: none;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0 !important;
  width: 100%;
}

.fieldset .form__group .picture-input > div .preview-container .picture-inner .picture-inner-text {
  display: none;
}

.fieldset .form__group .picture-input > div .preview-container canvas {
  background-color: #fff !important;
}

.fieldset .form__group .picture-input > div .file-btn {
  background: none;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  height: 17px;
  margin: 0 10px;
  outline: inherit;
  padding: 0;
  width: 17px;
}

.fieldset .form__group .picture-input > div .file-btn-add {
  /* background-image: url(/img/icons-ui/icon_replace.svg); */
  margin-left: 20px;
}

.fieldset .form__group .picture-input > div .file-btn-remove {
  /* background-image: url(/img/icons-ui/icon_trash.svg); */
}

.fieldset legend {
  color: #adb5bd;
  font-size: 14px;
  font-style: italic;
}

.fieldset .error {
  color: #e30513;
  display: block;
  font-size: 12px;
  margin: 10px 0;
}

.form__success {
  color: #009640;
  font-size: 18px;
}

.form__error {
  color: #e30513;
  font-size: 18px;
}

label {
  color: #6c757d;
  font-size: 14px;
}

.stickyBar__wrapper {
  width: 100%;
}

.stickyBar__wrapper .stickyBar__container .stickyBar__logo img {
  height: 50px;
  width: 50px;
}

.requests__wrapper {
  position: relative;
  scroll-margin-top: 20px;
}

.requests__wrapper .requests {
  list-style: none;
}

.requests__wrapper .requests .date {
  color: #6c757d;
  font-size: 14px;
}

.requests__wrapper .requests .request__info.az a {
  color: #222;
  text-decoration: none;
}

.requests__wrapper .requests .request__info.az a .service-card {
  height: auto !important;
}

.requests__wrapper .requests .request__info.soc {
  position: relative;
}

@media (min-width: 576px) {
  .requests__wrapper .requests .request__info.soc {
    border-left: 5px solid #009640;
    max-height: 100px;
  }
}

.requests__wrapper .requests .request__info.soc strong {
  display: block;
}

@media (max-width: 575.98px) {
  .requests__wrapper .requests .request__info.soc strong {
    border-top: 5px solid #009640;
    padding-top: 10px;
  }
}

.requests__wrapper .requests .request__info.soc div {
  color: #6c757d;
}

.requests__wrapper .requests .request__content {
  position: relative;
}

@media (max-width: 575.98px) {
  .requests__wrapper .requests .request__content.azienda:before {
    background-color: #009640;
    content: "";
    height: 20px;
    left: 20vw;
    position: absolute;
    top: -20px;
    width: 50vw;
  }
}

@media (min-width: 576px) {
  .requests__wrapper .requests .request__content.azienda:before {
    background-color: #009640;
    content: "";
    height: 120px;
    left: -15px;
    position: absolute;
    top: 20px;
    width: 15px;
  }
}

.requests__wrapper .requests .request__content.azienda .message-card .card-header {
  background-color: #fff;
}

.requests__wrapper .requests .request__content:not(.azienda) .message-card .card-header {
  background-color: #84988c;
  color: #fff;
}

.requests__wrapper .requests .request__content .message-card .card-header .text {
  text-transform: uppercase;
}

.requests__wrapper .requests .request__content .message-card .card-body .body-email i {
  margin-right: 5px;
}

.requests__wrapper .requests .request__content .message-card .card-body .body-text {
  min-height: 96px;
}

.requests__wrapper .requests .request__content .message-card .card-body .body-text .expander {
  color: #009640;
  cursor: pointer;
  font-size: 12px;
}

.requests__wrapper .requests .request__content .message-card .card-body .body-service-ereased {
  color: #6c757d;
  font-size: .8rem;
  font-style: italic;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

:root {
  font-size: 16px;
}

body {
  margin: 0;
}

ol, ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

header,
main,
footer,
section {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

img[src$="svg"],
img[src$="png"] {
  max-width: 300px;
}

.Xnp {
  line-height: 1.5;
}

.Xnp p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.Xnp .Xnp__container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.Xnp .Xnp__container.Xnp__container--separator {
  margin-top: 2rem;
  padding-top: 2rem;
}

.Xnp .Xnp__container.Xnp__container--separator::before {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  right: 0;
  top: -1rem;
  width: 90%;
  height: 1px;
  background-color: black;
}

.Xnp .Xnp__container.padding-block {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.Xnp .Xnp__container > div {
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .Xnp .Xnp__container > div {
    min-width: 25%;
    text-align: left;
  }
}

@media only screen and (min-width: 1024px) {
  .Xnp .Xnp__container > div .CDPButton {
    margin-left: auto;
    margin-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .Xnp .Xnp__container {
    padding-left: 2rem;
    padding-right: 2rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.Xnp .Xnp__header {
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
}

.Xnp .Xnp__header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/img/header.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.Xnp .Xnp__header .Xnp__header__content {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 900px) {
  .Xnp .Xnp__header .Xnp__header__content {
    text-align: left;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.Xnp .Xnp__header .Xnp__header__content .Xnp__header__title {
  font-weight: normal;
  position: relative;
  color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Xnp .Xnp__header .Xnp__header__content .Xnp__header__title + * {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.Xnp .Xnp__header .Xnp__header__content .Xnp__header__title + *::before {
  width: 2rem;
  height: 1px;
  position: absolute;
  content: '';
  background-color: white;
  top: -.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media only screen and (min-width: 1024px) {
  .Xnp .Xnp__header .Xnp__header__content .Xnp__header__title + *::before {
    left: 0;
    -webkit-transform: unset;
            transform: unset;
  }
}

.Xnp .Xnp__header .Xnp__header__text {
  max-width: 39ch;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 900px) {
  .Xnp .Xnp__header .Xnp__header__text {
    margin-left: 0;
  }
}

.Xnp .Xnp__header .Xnp__header__logo {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 1024px) {
  .Xnp .Xnp__header .Xnp__header__logo {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }
}

.Xnp .Xnp__header .Xnp__header__logo img {
  width: 150px;
}

.CDPitle {
  font-size: 1.88rem;
}

.Xnp__steps {
  background-color: #f1f1f1;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

ol.CDP__numberedList {
  counter-reset: cdpNumberedList;
  margin-bottom: 1rem;
}

ol.CDP__numberedList li {
  position: relative;
  padding-left: 3rem;
  margin-bottom: 3rem;
}

ol.CDP__numberedList li::before {
  display: -ms-grid;
  display: grid;
  counter-increment: cdpNumberedList 1;
  content: counter(cdpNumberedList);
  position: absolute;
  border-radius: 50%;
  color: white;
  background-color: #05426c;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 2.3rem;
  height: 2.3rem;
  place-items: center;
  font-size: 20px;
  font-weight: 500;
}

ul.CDP__list {
  list-style-type: none;
  padding: 0;
}

ul.CDP__list li {
  position: relative;
  padding-left: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

ul.CDP__list li::before {
  display: -ms-grid;
  display: grid;
  content: '\2192';
  position: absolute;
  border-radius: 50%;
  color: white;
  background-color: #05426c;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 2.3rem;
  height: 2.3rem;
  place-items: center;
  font-size: 20px;
  font-weight: 500;
}

.Xnp__section.margin-block {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.Xnp__section.margin-top {
  margin-top: 4rem;
}

.Xnp__section.super-margin-block {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.Xnp__section.Xnp__section--highlight {
  background-color: #f1f1f1;
  padding-top: 4rem;
}

.Xnp__section.Xnp__section--highlight .CDPTitle {
  padding-bottom: 2rem;
}

.CDPButton {
  background-color: #009640;
  color: white;
  text-decoration: none;
  min-height: 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 0;
  font-size: .9rem;
  font-weight: bold;
  cursor: pointer;
}

.CDPButton.CDPButton--reverse {
  background-color: white;
  color: #009640;
  border: 1px solid currentColor;
}

.CDPButton.CDPButton--reverse:hover {
  color: #008036;
  background-color: #d5d5d5;
}

.CDPButton:hover {
  background-color: #008036;
  color: white;
}

.Xnp__CDPDigitalXcelerator .Xnp__container {
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .Xnp__CDPDigitalXcelerator .Xnp__container {
    text-align: left;
  }
}

.Xnp__CDPDigitalXcelerator .Xnp__container img {
  width: 230px;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.Xnp__form {
  width: 100%;
  text-align: center;
}

.Xnp__form label {
  display: block;
}

@media only screen and (min-width: 1024px) {
  .Xnp__form {
    text-align: left;
  }
}

.Xnp__form input[type="text"],
.Xnp__form input[type="email"] {
  text-align: center;
  border: 1px solid #000;
  padding: .5rem;
  width: 32rem;
  max-width: 100%;
  color: black;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1024px) {
  .Xnp__form input[type="text"],
  .Xnp__form input[type="email"] {
    margin-bottom: 0;
    margin-left: unset;
    margin-right: unset;
  }
}

.Xnp__form input[type="text"]::-webkit-input-placeholder,
.Xnp__form input[type="email"]::-webkit-input-placeholder {
  color: #a8a8a8;
}

.Xnp__form input[type="text"]:-ms-input-placeholder,
.Xnp__form input[type="email"]:-ms-input-placeholder {
  color: #a8a8a8;
}

.Xnp__form input[type="text"]::-ms-input-placeholder,
.Xnp__form input[type="email"]::-ms-input-placeholder {
  color: #a8a8a8;
}

.Xnp__form input[type="text"]::placeholder,
.Xnp__form input[type="email"]::placeholder {
  color: #a8a8a8;
}

.CDPResponsive {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.CDPResponsive div {
  margin-left: auto;
  margin-right: auto;
}

.CDPResponsive div:not(:last-child) {
  margin-bottom: 1rem;
}

@media only screen and (min-width: 1024px) {
  .CDPResponsive div {
    margin-left: unset;
    margin-right: unset;
  }
  .CDPResponsive div:not(:last-child) {
    margin-bottom: unset;
  }
}

@media only screen and (min-width: 1024px) {
  .CDPResponsive {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .CDPResponsive > *:last-child {
    margin-right: 0;
    margin-left: auto;
  }
}

.CDPTitle {
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .CDPTitle {
    text-align: left;
  }
}

.CDPTitle.margin-top {
  margin-top: 2rem;
}

.XnpModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 10;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  pointer-events: none;
}

.XnpModal .XnpModal__content {
  background-color: white;
  padding: 4rem;
  max-width: 800px;
  display: none;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.XnpModal.XnpModal--opened {
  opacity: 1;
  pointer-events: all;
}

.XnpModal.XnpModal--opened .XnpModal__content {
  opacity: 1;
  -webkit-transform: scale(100%);
          transform: scale(100%);
  pointer-events: all;
}

.XnpModal.XnpModal--indirizzoNonRegistrato .XnpModal__content--indirizzoNonRegistrato {
  display: block;
}

.XnpModal.XnpModal--aBreveRiceveraiUnLink .XnpModal__content--aBreveRiceveraiUnLink {
  display: block;
}

.XnpModal + .Xnp {
  overflow: hidden;
}

.Xnp__paragraph {
  padding-top: 1.5rem;
  padding-bottom: 3rem;
}

body[abc],
body.XnpModalOpened {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

body[abc] .XnpModal,
body.XnpModalOpened .XnpModal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
/*# sourceMappingURL=xcelerator-lp.css.map */
